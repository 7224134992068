// Good resource: https://web.dev/custom-metrics/?utm_source=devtools#server-timing-api
export default function observeHighLatencyTasks(minimumDurationInMs: number): void {
	// Observe relevant tasks - ones that take longer than <minimumDurationInMs> ms
	try {
		const po = new PerformanceObserver((list) => {
			for (const entry of list.getEntries()) {
				if (entry.duration > minimumDurationInMs) console.debug(entry.toJSON());
			}
		});
		po.observe({ type: 'measure', buffered: true });
	} catch (e) {
		// Do nothing if the browser doesn't support this API.
	}
}
