import React, { FC, forwardRef, ReactElement, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea } from 'recharts';
import { GraphProps } from '@/models/types/graphs';
import CustomTooltip from '@/components/Interpretation/CustomTooltip';
import { HoverProps } from '@/models/types';
import CustomDot from '@/components/Interpretation/CustomDot';
import GraphSkeleton from '@/components/Interpretation/SoilInvestigationGraphs/GraphSkeleton';
import classes from './index.module.css';

const FrictionRatioGraph: FC<GraphProps> = forwardRef<HTMLElement, GraphProps>((props, ref) => {
	const [isActive, setIsActive] = useState(false);
	const { cpt, zoomLevel, computedMeasurements, xAxisHeight, rightMargin, visibility, children } = props;
	const {
		graphWidth,
		graphHeight,
		gridVerticalPoints,
		gridHorizontalPoints,
		lastMeasurementRounded,
		finalXTick,
		yAxisWidth,
	} = computedMeasurements;

	return (
		<div className={classes.container}>
			<span className={classes.purpleBar} ref={ref} />
			<div className={classes.headerContainer}>
				<span className={classes.header}>
					{zoomLevel && zoomLevel >= 0.6 && <span>Friction ratio</span>} R<sub>f</sub> [%]
				</span>
			</div>
			{visibility ? (
				<LineChart
					layout="vertical"
					width={graphWidth}
					height={graphHeight}
					data={cpt.recordsList.filter((record) => record.frictionNumber > 0)}
					syncId={cpt.id}
					margin={{
						top: 0,
						right: rightMargin,
						left: yAxisWidth,
						bottom: 0,
					}}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onMouseMove={(_: any): void => {
						if (!isActive) {
							setIsActive(true);
						}
					}}
					onMouseLeave={(): void => {
						if (isActive) {
							setIsActive(false);
						}
					}}
				>
					<CartesianGrid
						verticalPoints={gridVerticalPoints}
						horizontalPoints={gridHorizontalPoints}
						y={xAxisHeight}
						stroke="#dddddd"
					/>
					<XAxis
						type="number"
						domain={[0, finalXTick]}
						orientation="top"
						allowDecimals={false}
						tickCount={finalXTick / 2 + 1}
						allowDataOverflow
						interval="preserveStartEnd"
						axisLine={false}
						tickLine={false}
						reversed
					/>
					<YAxis
						type="number"
						width={yAxisWidth}
						domain={[lastMeasurementRounded, Math.ceil(cpt.z)]}
						dataKey="depthBasedOnZValue"
						reversed
						hide
					/>
					<Tooltip
						wrapperStyle={{ zIndex: 999 }}
						isAnimationActive={false}
						content={<CustomTooltip shouldDisplay={isActive} />}
					/>
					<Line
						type="monotoneY"
						dataKey="frictionNumber"
						stroke="#333333"
						dot={false}
						activeDot={(props: HoverProps): ReactElement<SVGElement> =>
							((<CustomDot cx={props.cx} cy={props.cy} r={props.r} />) as unknown) as ReactElement<SVGElement, string>
						}
					/>
					<ReferenceArea x1={0} x2={5} y1={cpt.z} y2={cpt.z - 0.00001} stroke="#555555" />
					{children}
				</LineChart>
			) : (
				<GraphSkeleton width={graphWidth} height={graphHeight} />
			)}
		</div>
	);
});

export default React.memo(FrictionRatioGraph);
