import { Dispatch, KeyboardEvent } from 'react';
import moment from 'moment-timezone';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { saveAs } from 'file-saver';
import { LngLatBounds } from 'mapbox-gl';
import { SoilLayer, UploadFileChunk } from '@/proto/build/cpt_pb';
import { ESoilInvestigationType, ExportStatus, HoveredSoilInvestigationLayer, UUID } from '@/models/types';
import { BoreholeAsObject, CPTAsObject, MapViewCoords } from '@/store/types';
import { exportInterpretationsCmd } from '@/net';
import { updateExportStatus } from '@/store/actions';
import { PROPOSED_LAYER_SOIL_ID } from '@/models/constants';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { SoilInvestigationId, SoilInvestigationIdList } from '@/proto/build/soilInvestigation_pb';

export function dateTimestampToString(timeStamp: Timestamp | undefined): string {
	const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });
	const dateString = timeStamp ? new Date(timeStamp.toDate().toISOString()) : new Date(moment().toISOString());
	const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(dateString);
	return `${day} ${month} ${year}`;
}

export const readFileContent = async (file: File): Promise<UploadFileChunk.AsObject> => {
	performance.mark('readFileContent:start');
	const content = await new Response(file).arrayBuffer();

	const result = new UploadFileChunk().toObject();
	result.payload = new Uint8Array(content);
	result.fileName = file.name;
	performance.mark('readFileContent:end');
	performance.measure('readFileContent', 'readFileContent:start', 'readFileContent:end');
	return result;
};

export const validatedFilesExtension = (files: File[]) =>  {
	const extensionLists = ['.gef', '.xml'];
	return files.every((file) =>	{
		const filename = file.name.toLowerCase();
		return extensionLists.some((value) => filename.includes(value));
	});
};

export const readMultipleFileContent = (files: File[]): UploadFileChunk.AsObject[] => {
	const chunkFiles: UploadFileChunk.AsObject[] = [];

	for (const file of files) {
		readFileContent(file)
			.then((chunk) => {
				chunkFiles.push(chunk);
			})
			.catch((e) => console.error('Failed to convert file to UploadGEFChunk'));
	}

	return chunkFiles;
};

export const getTimeStamp = (date: Timestamp.AsObject | undefined): Timestamp => {
	const timeStamp = new Timestamp();
	timeStamp.setNanos(date?.nanos || 0);
	timeStamp.setSeconds(date?.seconds || 0);

	return timeStamp;
};

export const sortLayerByTop = (layers: Record<UUID, SoilLayer.AsObject>): Record<UUID, SoilLayer.AsObject> => {
	return Object.fromEntries(Object.entries(layers).sort((a, b) => b[1].depthTop - a[1].depthTop));
};

export const isSelectedLayerSplittable = (soilLayer: SoilLayer.AsObject): boolean => soilLayer.depthBase / 2 >= 0.1;

export const isValueBetweenSoillayerTopAndDepth = (value: number, top: number, depth: number): boolean => value <= top && value >= top - depth;

export const handleHoveringOnGraph = (
	depthBasedOnZValue: number,
	hovered: HoveredSoilInvestigationLayer,
	cpt: CPTAsObject,
	setHoverState: (found: SoilLayer.AsObject | undefined) => void,
	resetHoverState: () => void,
): void => {
	const found = Object.values(cpt.soilLayersList).find((layer) => {
		return isValueBetweenSoillayerTopAndDepth(depthBasedOnZValue, layer.depthTop, layer.depthBase);
	});

	if (found) {
		const foundID = found.id.toString();
		if (hovered.id !== foundID) {
			found.soilId !== PROPOSED_LAYER_SOIL_ID && foundID !== '' ? setHoverState(found) : resetHoverState();
		}
	} else {
		resetHoverState();
	}
};

export const replaceHyphensWithMinus = (value: string): string => {
	const hyphen = '-';
	const minus = '−';

	return value.replace(hyphen, minus);
};

export const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
	if (event.key === 'Enter') {
		(event.target as HTMLInputElement).blur();
	}
};

export const capitalizeFirstLetter = (str: string): string => {
	return str[0].toUpperCase() + str.slice(1);
};

// Get the highest zIndex from all the soil investigations
export const getHighestZIndex = (soilInvestigations: Record<string, CPTAsObject> | Record<string, BoreholeAsObject>): number => {
	if (!soilInvestigations) return 0;
	const zIndexes: number[] = Object.values(soilInvestigations).map((si) => si.z) || [];

	return Math.ceil(Math.max(...zIndexes));
};

export const exportInterpretations = (
	soilInvestigationIds: SoilInvestigationId.AsObject[],
	projectId: number,
	projectName: string,
	interpretationMethod: InterpretationMethod,
	dispatch: Dispatch<unknown>): void => {
	if (soilInvestigationIds.length <= 100) {
		exportInterpretationsCmd(projectId, soilInvestigationIds)
			.then((zip) => {
				const blob = new Blob([zip.getContent()], { type: 'application/zip' });
				saveAs(blob, zip.getZipFileName());
				dispatch(updateExportStatus({
					projectId, projectName,
					interpretationMethod,
					soilInvestigationsCount: soilInvestigationIds.length,
					status: ExportStatus.EMPTY,
				}));
			})
			.catch((e) => {
				dispatch(updateExportStatus({
					projectId,
					projectName,
					interpretationMethod,
					soilInvestigationsCount: soilInvestigationIds.length,
					status: ExportStatus.ERROR }));
			});
	} else {
		dispatch(updateExportStatus({
			projectId,
			projectName,
			interpretationMethod,
			soilInvestigationsCount: soilInvestigationIds.length,
			status: ExportStatus.TOO_LARGE,
		 }));
	}
};

export const getNumberWith1DecimalPlace = (raw: string): number | undefined => {
	if (isNaN(parseFloat(raw)))
		return undefined;

	return parseFloat(parseFloat(raw).toFixed(1));
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

export function mapViewToLngLatBounds(source: MapViewCoords): LngLatBounds {
	return new LngLatBounds([source.west, source.south, source.east, source.north]);
}

export function lngLatBoundsToMapViewCoords(source: LngLatBounds): MapViewCoords {
	return {
		north: source.getNorth(),
		east: source.getEast(),
		south: source.getSouth(),
		west: source.getWest(),
	};
}


export function soilInvestigationIdWithTypeToList(idsWithType: SoilInvestigationId.AsObject[]):  SoilInvestigationIdList.AsObject {
	const cpts: number[] = [];
	const boreholes: number[] = [];

	idsWithType.forEach((unactiveSoilInvestigation) => {
		if (unactiveSoilInvestigation.type === ESoilInvestigationType.CPT) {
			cpts.push(unactiveSoilInvestigation.soilInvestigationId);
		} else if (unactiveSoilInvestigation.type === ESoilInvestigationType.BOREHOLE) {
			boreholes.push(unactiveSoilInvestigation.soilInvestigationId);
		} else {
			throw Error(`Soil investigation type ${unactiveSoilInvestigation.type} unknown`);
		}
	});

	return  {
		cptsList: cpts,
		boreholesList: boreholes,
	};
}
