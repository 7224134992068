import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSoilInvestigationIds, viewSoilInvestigations } from '@/store/actions';
import { MousePosition } from '@/models/types';
import { getSimpleSoilInvestigationsCmd } from '@/net';
import { getSelectedSoilInvestigationIds, getSelectedProject, getSearchTerm } from '@/store/selectors';
import { SimpleSoilInvestigation, SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import { soilInvestigationIdWithTypeToList } from '@/helpers';

enum CLickEnum {
	NORMAL = 'NORMAL',
	CTRL = 'CTRL',
	SHIFT = 'SHIFT',
}

interface CPTClickType {
	type: CLickEnum;
	value: number;
}

const initialMousePos: MousePosition = {
	mouseX: null,
	mouseY: null,
};

export const useFilesUtils = () => {
	const dispatch = useDispatch();
	const selectedProject = useSelector(getSelectedProject);
	const searchTerm = useSelector(getSearchTerm);
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const [simpleSoilInvestigationList, setSimpleSoilInvestigationList] = useState<SimpleSoilInvestigation.AsObject[]>([]);
	const [simpleUnactiveSoilInvestigationList, setSimpleUnactiveProjectSoilInvestigationList] = useState<SimpleSoilInvestigation.AsObject[]>([]);
	const [previousClicked, setPreviousClicked] = useState<CPTClickType>();
	const [mouseClickPos, setMouseClickPos] = useState<MousePosition>(initialMousePos);

	useEffect(() => {
		const cptsFiles = Object.values(selectedProject?.cptsList || []);
		const boreholesFiles = Object.values(selectedProject?.boreholesList || []);
		const allSoilInvestigationsFiles = [...cptsFiles, ...boreholesFiles];

		const sortedInvestigationsFiles = allSoilInvestigationsFiles
			.filter((si) => si.testId.toLowerCase().includes(searchTerm))
			.sort((a, b) => a.testId.localeCompare(b.testId));

		setSimpleSoilInvestigationList(sortedInvestigationsFiles);

		const unactiveSoilInvesigationIds = selectedSoilInvestigationIds
			.filter((selectedSIId) => !sortedInvestigationsFiles?.find((sortedSIFiles) => sortedSIFiles.id === selectedSIId.soilInvestigationId ))
			.sort((a, b) => a.soilInvestigationId - b.soilInvestigationId);

		if (!unactiveSoilInvesigationIds.length) {
			setSimpleUnactiveProjectSoilInvestigationList([]);
		} else {
			// Get the unactive simple soil investigations list by ids
			getSimpleSoilInvestigationsCmd(soilInvestigationIdWithTypeToList(unactiveSoilInvesigationIds))
				.then((list) => {
					const { cptsList, boreholesList } = list;
					setSimpleUnactiveProjectSoilInvestigationList([...cptsList, ...boreholesList]);
				})
				.catch((error) => {
					throw new Error(`Could not get the list of selected unactive projects soil investigations ${error.message}`);
				});
		}
	}, [dispatch, searchTerm, selectedProject, selectedSoilInvestigationIds]);

	const handleOnClick = (event: React.MouseEvent, soilInvestigationIdAsObject: SoilInvestigationId.AsObject): void => {
		const { soilInvestigationId, type } = soilInvestigationIdAsObject;
		event.preventDefault();
		let currentSelectedSoilInvestigationsIds: SoilInvestigationId.AsObject[] = [...selectedSoilInvestigationIds];
		const indexInFilteredSoilInvestigations = Object.values(simpleSoilInvestigationList)
			.findIndex((si) => si.id === soilInvestigationId && si.type === type);

		const clickedEventType = event.ctrlKey || event.metaKey ? CLickEnum.CTRL : event.shiftKey ? CLickEnum.SHIFT : CLickEnum.NORMAL;

		switch (clickedEventType) {
			// Current click event has been triggered with CTRL (or Command on MacOS)
			case CLickEnum.CTRL:
				const foundIndex = selectedSoilInvestigationIds.findIndex(
					(siId) => siId.soilInvestigationId === soilInvestigationId && siId.type === type);
				if (foundIndex >= 0) {
					currentSelectedSoilInvestigationsIds.splice(foundIndex, 1);
				} else {
					currentSelectedSoilInvestigationsIds.splice(indexInFilteredSoilInvestigations, 0, soilInvestigationIdAsObject);
				}
				setPreviousClicked({ type: CLickEnum.CTRL, value: indexInFilteredSoilInvestigations });
				break;
			// Current click event has been triggered with SHIFT
			case CLickEnum.SHIFT:
				let newSelectedSoilInvestigations: SimpleSoilInvestigation.AsObject[] = [];
				if ((
					previousClicked?.type === CLickEnum.NORMAL
					|| previousClicked?.type === CLickEnum.CTRL
				) && currentSelectedSoilInvestigationsIds.length) {
					if (indexInFilteredSoilInvestigations > previousClicked.value) {
						newSelectedSoilInvestigations =  Object.values(simpleSoilInvestigationList)
							.filter((_soilInvestigation, index) => index <= indexInFilteredSoilInvestigations && index >= previousClicked.value);
					} else {
						newSelectedSoilInvestigations =  Object.values(simpleSoilInvestigationList)
							.filter((_soilInvestigation, index) => index >= indexInFilteredSoilInvestigations && index <= previousClicked.value);
					}
				} else if (previousClicked?.type === CLickEnum.SHIFT) {
					if (indexInFilteredSoilInvestigations > previousClicked.value) {
						newSelectedSoilInvestigations =  Object.values(simpleSoilInvestigationList)
							.filter((_soilInvestigation, index) => index <= indexInFilteredSoilInvestigations && index >= previousClicked.value);
					} else {
						newSelectedSoilInvestigations =  Object.values(simpleSoilInvestigationList)
							.filter((_soilInvestigation, index) => index <= indexInFilteredSoilInvestigations && index >= previousClicked.value);
					}
				}

				currentSelectedSoilInvestigationsIds = [...newSelectedSoilInvestigations].map((soilInvestigation) => {
					return {
						soilInvestigationId: soilInvestigation.id,
						type: soilInvestigation.type,
					};
				});
				break;
			// Current click event has been triggered without CTRL or SHIFT
			default:
				currentSelectedSoilInvestigationsIds = [soilInvestigationIdAsObject];
				setPreviousClicked({ type: CLickEnum.NORMAL, value: indexInFilteredSoilInvestigations });
				break;
		}
		dispatch(setSelectedSoilInvestigationIds(currentSelectedSoilInvestigationsIds));
	};

	const handleRightClick = (event: React.MouseEvent, isSelected: boolean): void => {
		event.preventDefault();
		if (isSelected) {
			setMouseClickPos({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			});
		}
	};

	const handleDoubleCLick = (event: React.MouseEvent, soilInvestigationId: SoilInvestigationId.AsObject): void => {
		event.preventDefault();
		if (selectedProject) {
			dispatch(viewSoilInvestigations({ projectId: selectedProject?.id, soilInvestigationsIds: [soilInvestigationId] }));
		}
	};

	const handleMouseClickPosition = (): void => {
		setMouseClickPos(initialMousePos);
	};

	return {
		simpleSoilInvestigationList,
		simpleUnactiveSoilInvestigationList,
		selectedSoilInvestigationIds,
		mouseClickPos,
		handleOnClick,
		handleRightClick,
		handleDoubleCLick,
		handleMouseClickPosition,
	};
};
