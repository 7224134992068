import React, { FC } from 'react';
import { default as MUIDialog } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import classes from './index.module.css';

interface Props {
	title: string;
	isOpen: boolean;
	children: React.ReactNode;
	actionButtons: React.ReactNode;
	closeDialog: () => void;
}

const BaseDialog: FC<Props> = ({ isOpen, title, children, closeDialog, actionButtons }) => {
	return (
		<div>
			<MUIDialog
				fullWidth={true}
				maxWidth={'sm'}
				onClose={closeDialog}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
				classes={{
					root: classes.root,
					paper: classes.paper,
				}}
			>
				<DialogTitle id="customized-dialog-title" classes={{ root: classes.title }}>
					{title}
				</DialogTitle>
				<DialogContent dividers classes={{ dividers: classes.dividers }}>
					{children}
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActions }}>{actionButtons}</DialogActions>
			</MUIDialog>
		</div>
	);
};

export default BaseDialog;
