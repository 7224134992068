// source: soilInvestigation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var cpt_pb = require('./cpt_pb.js');
goog.object.extend(proto, cpt_pb);
var borehole_pb = require('./borehole_pb.js');
goog.object.extend(proto, borehole_pb);
var location_pb = require('./location_pb.js');
goog.object.extend(proto, location_pb);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    position: (f = msg.getPosition()) && location_pb.LatLng.toObject(includeInstance, f),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    projectNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    testId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractor(value);
      break;
    case 3:
      var value = new location_pb.LatLng;
      reader.readMessage(value,location_pb.LatLng.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContractor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      location_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProjectNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string contractor = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getContractor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setContractor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LatLng position = 3;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getPosition = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, location_pb.LatLng, 3));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.hasDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string project_number = 5;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getProjectNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setProjectNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string test_id = 6;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 project_id = 8;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.toObject = function(includeInstance, msg) {
  var f, obj = {
    cptsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    boreholesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCpts(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBoreholes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCptsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getBoreholesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 cpts = 1;
 * @return {!Array<number>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.getCptsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.setCptsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.addCpts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.clearCptsList = function() {
  return this.setCptsList([]);
};


/**
 * repeated int64 boreholes = 2;
 * @return {!Array<number>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.getBoreholesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.setBoreholesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.addBoreholes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationIdList.prototype.clearBoreholesList = function() {
  return this.setBoreholesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    soilInvestigationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSoilInvestigationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSoilInvestigationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 soil_investigation_id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.prototype.getSoilInvestigationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.prototype.setSoilInvestigationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationId.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpts: (f = msg.getCpts()) && cpt_pb.CPTList.toObject(includeInstance, f),
    boreholes: (f = msg.getBoreholes()) && borehole_pb.BoreholeList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cpt_pb.CPTList;
      reader.readMessage(value,cpt_pb.CPTList.deserializeBinaryFromReader);
      msg.setCpts(value);
      break;
    case 2:
      var value = new borehole_pb.BoreholeList;
      reader.readMessage(value,borehole_pb.BoreholeList.deserializeBinaryFromReader);
      msg.setBoreholes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cpt_pb.CPTList.serializeBinaryToWriter
    );
  }
  f = message.getBoreholes();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      borehole_pb.BoreholeList.serializeBinaryToWriter
    );
  }
};


/**
 * optional CPTList cpts = 1;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.CPTList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.getCpts = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.CPTList} */ (
    jspb.Message.getWrapperField(this, cpt_pb.CPTList, 1));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.CPTList|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.setCpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.clearCpts = function() {
  return this.setCpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.hasCpts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BoreholeList boreholes = 2;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.getBoreholes = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList} */ (
    jspb.Message.getWrapperField(this, borehole_pb.BoreholeList, 2));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.setBoreholes = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.clearBoreholes = function() {
  return this.setBoreholes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilInvestigationList.prototype.hasBoreholes = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.toObject = function(includeInstance, msg) {
  var f, obj = {
    cptsList: jspb.Message.toObjectList(msg.getCptsList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.toObject, includeInstance),
    boreholesList: jspb.Message.toObjectList(msg.getBoreholesList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.deserializeBinaryFromReader);
      msg.addCpts(value);
      break;
    case 2:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.deserializeBinaryFromReader);
      msg.addBoreholes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.serializeBinaryToWriter
    );
  }
  f = message.getBoreholesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SimpleSoilInvestigation cpts = 1;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.getCptsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, 1));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.setCptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.addCpts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.clearCptsList = function() {
  return this.setCptsList([]);
};


/**
 * repeated SimpleSoilInvestigation boreholes = 2;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.getBoreholesList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, 2));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.setBoreholesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.addBoreholes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigationList.prototype.clearBoreholesList = function() {
  return this.setBoreholesList([]);
};


goog.object.extend(exports, proto.com.royalhaskoningdhv.mondriaan.proto);
