import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { ProjectProgress, ProjectStatusType } from '@/models/types';

const initialState: ProjectProgress = {
	show: false,
	progress: 0,
	type: ProjectStatusType.UNKNOWN,
};

export default createReducer(initialState, {
	[actions.updateProgressBar.type]: (_, { payload }): ProjectProgress => payload,
});
