import { matchPath, match } from 'react-router';

type MatchResult<T> = {
	[K in keyof T]: string;
};

type RouteParams<T> = {} extends T ? T | undefined | void : T;

export interface RouteInformation<T> {
	templatePath: string;
	(params: RouteParams<T>, hash?: string): string;
	matchPath(url: string): null | match<MatchResult<T>>;
	matchPathWithProps(url: string, params: RouteParams<T>): null | match;
	hasParams: boolean;
}

function route<V extends string, R = {} extends { [K in V]: string | number } ? {} : { [K in V]: string | number }>(
	literals: TemplateStringsArray,
	...placeholders: V[]
): RouteInformation<R> {
	let templatePath = '';
	for (let i = 0; i < placeholders.length; i++) {
		templatePath += literals[i];
		templatePath += `:${placeholders[i]}`;
	}
	templatePath += literals[literals.length - 1];
	function linkTo(params: RouteParams<R>, hash?: string): string {
		let url = '';
		for (let i = 0; i < placeholders.length; i++) {
			url += literals[i];
			// Typescript is not smart enough to automatically check the types here, but the signature is correct
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			url += (params as any)[placeholders[i]];
		}
		url += literals[literals.length - 1];
		if (hash) {
			url += `#${hash}`;
		}
		return url;
	}
	linkTo.matchPath = (url: string): ReturnType<RouteInformation<R>['matchPath']> => {
		return matchPath(url, templatePath);
	};
	linkTo.matchPathWithProps = (url: string, params: RouteParams<R>): ReturnType<RouteInformation<R>['matchPathWithProps']> => {
		return matchPath(url, linkTo(params));
	};
	linkTo.templatePath = templatePath;
	linkTo.hasParams = placeholders.length > 0;
	linkTo.toString = (): string => `route \`${templatePath}\``;
	return linkTo;
}

export const interpretationProjectSelected = route`/projects/${'id'}/interpretation`;
export const interpretationGeneral = route`/general/interpretation`;
export const projectSelected = route`/projects/${'id'}/`;
export const projectsmap = route`/`;
export const notFound = route`*`;
