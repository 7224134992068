import React, { createRef, FC, forwardRef, Ref, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import { distanceBetweenSteps } from '@/components/Layout/DepthRuler';
import { getSelectedProject } from '@/store/selectors';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import MetaDataContent from '@/components/Dialogs/MetaDataContent';
import { TextButtonTurquiose } from '@/components/custom/buttons';
import BoreholeSoilLayerBar from './BoreholeSoilLayerBar';
import classes from './index.module.css';
import { BoreholeAsObject } from '@/store/types';
import { BoreholeSoilLayer } from '@/proto/build/borehole_pb';

interface Props {
	borehole: BoreholeAsObject;
	zoomLevel: number;
	maxZIndex: number;
	visibility: boolean;
	ref: Ref<HTMLDivElement>;
}

const xAxisHeight = 30;
const defaultSoilLayerBarWidth = 54;
const minSoilBarWidth = 40;
const maxSoilBarWidth = 162; // defaultWidth * zoomlevel(300%)

const Borehole: FC<Props> = forwardRef<HTMLDivElement, Props>(({ borehole, zoomLevel, maxZIndex, visibility }, ref) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const selectedProject =  useSelector(getSelectedProject);
	const boreholeHeaderRef = createRef<HTMLDivElement>();
	const topPosBaseOnFirstZIndex = (maxZIndex - Math.ceil(borehole.z)) * (distanceBetweenSteps * zoomLevel);
	const [boreholeIsInActiveProject, setBoreholeIsInActiveProject] = useState(false);

	useEffect(() => {
		if (selectedProject) {
			const found = Object.values(selectedProject.boreholesList).find((bh) => borehole.id === bh.id);
			setBoreholeIsInActiveProject(!!found);
		}
	}, [borehole.id, selectedProject]);

	const measurementsSoilBarWidth = useMemo((): number => {
		const zoomedWidth = defaultSoilLayerBarWidth * zoomLevel;
		return zoomedWidth <= minSoilBarWidth
			? minSoilBarWidth
			: zoomedWidth >= maxSoilBarWidth
				? maxSoilBarWidth
				: zoomedWidth;
	}, [zoomLevel]);

	const measurementsSoilBarHeight = useMemo((): number => {
		const { depthFromTop, height } = Object.values(borehole.layersList).pop() as BoreholeSoilLayer.AsObject;
		const measurementLength = Math.ceil(borehole.z) - Math.floor(depthFromTop - height);
		const distanceBasedOnZoom = distanceBetweenSteps * zoomLevel;

		return distanceBasedOnZoom * measurementLength;
	}, [borehole, zoomLevel]);

	const DialogActions = <TextButtonTurquiose onClick={(): void => setDialogOpen(false)}>CLOSE</TextButtonTurquiose>;

	return (
		<div
			ref={ref}
			className={classes.root}
			style={{ transform: `translateY(${topPosBaseOnFirstZIndex}px)`, width: 100 * zoomLevel, maxWidth: 210, minWidth: 100 }}>
			<div
				className={classes.header}
				ref={boreholeHeaderRef}
				style={{
					color: boreholeIsInActiveProject ? '#01cebd' : '#999',
				}}
			>
				{borehole.boreholeId}
				<InfoIcon
					className={classes.icon}
					onClick={(): void => {
						setDialogOpen(true);
					}}
				/>
			</div>
			<div className={classes.soilInvestigation} >
				<div className={classes.soilLayers}>
					<BoreholeSoilLayerBar
						borehole={borehole}
						distanceBasedOnZoom={distanceBetweenSteps * zoomLevel}
						height={measurementsSoilBarHeight}
						width={measurementsSoilBarWidth}
						xAxisHeight={xAxisHeight}
						visibility={visibility}
					/>
				</div>
			</div>
			<BaseDialog
				title={`${borehole.boreholeId} Metadata`}
				isOpen={dialogOpen}
				closeDialog={(): void => setDialogOpen(false)}
				actionButtons={DialogActions}
			>
				<MetaDataContent data={borehole} />
			</BaseDialog>
		</div>
	);
});

export default React.memo(Borehole);
