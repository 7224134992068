import React, { FC } from 'react';
import classNames from 'classnames';
import ChevronLeftIconRounded from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightIconRounded from '@material-ui/icons/ChevronRightRounded';
import { useSelector } from 'react-redux';
import { HideSideBarButton } from '@/components/custom/buttons';
import CPTSidebarHandler from './CPTSidebarHandler';
import { getSelectedLayerID } from '@/store/selectors';
import { ESoilInvestigationType } from '@/models/types';
import BoreholeSidebarHandler from './BoreholeSidebarHandler';
import classes from './index.module.css';

interface Props {
	handleSideBar: () => void;
	openSideBar: boolean;
}

const SideBar: FC<Props> = ({ handleSideBar, openSideBar }): JSX.Element => {
	const IconComponent = openSideBar ? ChevronRightIconRounded : ChevronLeftIconRounded;
	const { soilInvestigationType } = useSelector(getSelectedLayerID);

	return (
		<div className={classNames(classes.sidebar, { [classes.closedSidebar]: !openSideBar })}>
			<div className={classes.sidebarContainer}>
				<HideSideBarButton
					className={classes.sidebarButton}
					onClick={handleSideBar}
				>
					<IconComponent />
				</HideSideBarButton>
				{soilInvestigationType === ESoilInvestigationType.CPT && <CPTSidebarHandler openSideBar={openSideBar}/>}
				{soilInvestigationType === ESoilInvestigationType.BOREHOLE && <BoreholeSidebarHandler />}
			</div>
		</div>
	);
};

export default SideBar;
