import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types';

const appState = (s: AppState): AppState => s;

export const getUrlPathName = createSelector(appState, (s: AppState): string => {
	return s.router.location.pathname;
});

export const getUrlSearchParams = createSelector(appState, (s: AppState): URLSearchParams => {
	return new URLSearchParams(s.router.location.search);
});
