import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TextIconFlatButton } from '@/components/custom/buttons';
import { getSelectedProject, getSelectedSoilInvestigationIds } from '@/store/selectors';
import { exportInterpretations } from '@/helpers';
import classes from './index.module.css';

interface Props {
	buttonText: string;
	disabled?: boolean;
}

const ExportInterpretationButton: FC<Props> = ({ buttonText, disabled }): JSX.Element => {
	const selectedProject = useSelector(getSelectedProject);
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const dispatch = useDispatch();

	const exportInterpretation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();

		if (!selectedProject) {
			console.error('Project not set for Export');
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		exportInterpretations(
			selectedSoilInvestigationIds,
			selectedProject!.id,
			selectedProject!.name,
			selectedProject!.interpretationMethod,
			dispatch);
	};

	return (
		<label className={classes.exportInterpretation}>
			<TextIconFlatButton
				disabled={disabled}
				variant="contained"
				className={classes.exportInterpretationButton}
				onClick={(e): void => exportInterpretation(e)}
			>
				<GetAppIcon />
				<span className={classes.buttonText}>{buttonText}</span>
			</TextIconFlatButton>
		</label>
	);
};

export default ExportInterpretationButton;
