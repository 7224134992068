import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import classes from './index.module.css';
import FilesSelectionMenu from '../FilesSelectionMenu';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import { setConfirmSoilInvestigationsDeletionDialogOpen } from '@/store/actions';

interface Props {
	handleClose: () => void;
	projectId: number;
	projectName: string;
	interpretationMethod: InterpretationMethod;
	selectedSoilInvestigationIds: SoilInvestigationId.AsObject[];
	handleEdit: () => void;
	handleDeleteProject: () => void;
}

export const ProjectOptions: FC<Props> = ({ projectId, projectName, interpretationMethod,
	selectedSoilInvestigationIds, handleClose, handleEdit, handleDeleteProject }): JSX.Element => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
	const [selectionActiveMenuOpen, setSelectionActiveMenuOpen] = useState<boolean>(false);

	const handleDelete = (): void => {
		handleDeleteProject();
	};

	const handleActiveSelectionOnClick = (event: React.MouseEvent<HTMLElement>): void => {
		if (anchorEl !== event.currentTarget) {
			setAnchorEl(event.currentTarget);
			setSelectionActiveMenuOpen((current) => !current);
		}
	};

	const handleCancelClick = (): void => {
		resetDeleteCptDialog();
	};

	function resetDeleteCptDialog(): void {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(false));
		setAnchorEl(undefined);
	}

	const confirmDeleteCPTsDialog = (): void => {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(true));
		setAnchorEl(undefined);
	};

	return (
		<Menu
			classes={{ list: classes.list }}
			keepMounted
			open={true}
			onClose={(): void => handleClose()}
			anchorReference="anchorPosition"
			anchorPosition={
				{
					top: 127,
					left: selectedSoilInvestigationIds.length > 0 ? 210 : 222,
				}
			}
		>
			<MenuItem classes={{ root: classes.listItem }} onClick={handleEdit}>
				Edit Project Info
			</MenuItem>
			<MenuItem classes={{ root: classes.listItem }} onClick={handleDelete}>
				Delete Project
			</MenuItem>
			{ selectedSoilInvestigationIds.length > 0 &&
					<MenuItem
						classes={{ root: classes.listItem }}
						onMouseOver={handleActiveSelectionOnClick}
						onMouseLeave={() => handleCancelClick}
						style={{ backgroundColor: selectionActiveMenuOpen ? '#515a66' : 'default' }}
					>
						Active Selection <KeyboardArrowRightOutlinedIcon/>
					</MenuItem>
			}
			<FilesSelectionMenu
				handleClose={handleClose}
				projectId={projectId}
				anchorEl={anchorEl}
				projectName={projectName}
				interpretationMethod={interpretationMethod}
				soilInvestigationIds={selectedSoilInvestigationIds}
				open={selectionActiveMenuOpen}
				handleDeleteSoilInvestigation={confirmDeleteCPTsDialog}
			/>
		</Menu>
	);
};

export default ProjectOptions;
