import mixpanel, { Dict, RequestOptions, Callback } from 'mixpanel-browser';
import { User } from '@auth0/auth0-spa-js';
import { GrpcError } from '@/models/classes';
import * as events from './events';

const developmentMode = process.env.NODE_ENV !== 'production';
let shouldTrack = false;

// Getting started: https://eu.mixpanel.com/report/2235361/view/2782937/setup/#code
export function setup(token: string): void {
	try {
		if (token) {
			mixpanel.init(token, { 'api_host': 'https://api-eu.mixpanel.com' }, '');
			shouldTrack = !mixpanel.has_opted_out_tracking();
		} else {
			shouldTrack = false;
		}
	} catch (e) {
		// Do nothing if mixpanel fails.
		if (developmentMode)
			console.error('Mixpanel setup failed', e);
	}
}

// This mimics the track event from mixpanel, but allows to handle errors or change behaviour globally from here.
export function track(eventName: string, properties?: Dict, optionsOrCallback?: RequestOptions | Callback, callback?: Callback): void {
	try {
		if (shouldTrack)
			mixpanel.track(eventName, properties, optionsOrCallback, callback);
	} catch (e) {
		// Do nothing if mixpanel fails.
		if (developmentMode)
			console.error('Mixpanel track event failed', e);
	}
}

// This mimics the track event from mixpanel, but allows to handle errors or change behaviour globally from here.
export function trackError(e: GrpcError, properties?: Dict, optionsOrCallback?: RequestOptions | Callback, callback?: Callback): void {
	try {
		if (shouldTrack)
			mixpanel.track(events.EVENT_ERROR, Object.assign({}, properties, { code: e.code, message: e.message }), optionsOrCallback, callback);
	} catch (e) {
		// Do nothing if mixpanel fails.
		if (developmentMode)
			console.error('Mixpanel track event failed', e);
	}
}

export function trackSignIn(user: User): void {
	try {
		if (shouldTrack) {
			mixpanel.identify(user.sub);
			mixpanel.people.set('Last login', Date.now().toString());
		}
	} catch (e) {
		// Do nothing if mixpanel fails.
		if (developmentMode)
			console.error('Mixpanel track event failed', e);
	}
}

export function mixPanelReset(): void {
	try {
		if (shouldTrack)
			mixpanel.reset();
	} catch (e) {
		// Do nothing if mixpanel fails.
		if (developmentMode)
			console.error('Mixpanel sign out failed', e);
	}
}

