import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Select, MenuItem, ListItemText } from '@material-ui/core';
import AppBarMui from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import StyledCheckbox from '@/components/custom/checkbox';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { getSearchTerm, getSelectedProject, getProjectIdsFromSelectedSoilInvestigationsList } from '@/store/selectors';
import {
	resetSelectedSoilLayer,
	setSearchTerm,
	setSelectedProject,
	setSelectedSoilInvestigationIds,
	setSelectedSoilInvestigations,
} from '@/store/actions';
import { ChartVisibility } from '@/models/types';
import logo from '@/assets/images/logo-m.svg';
import classes from './index.module.css';

type ZoomLevel = {
	title: string;
	value: number;
};

const zoomDefaultLevelsOptions: ZoomLevel[] = [
	{ title: '50%', value: 0.5 },
	{ title: '100%', value: 1 },
	{ title: '150%', value: 1.5 },
	{ title: '300%', value: 3 },
];

const MIN_ZOOM_LEVEL = 30;
const MAX_ZOOM_LEVEL = 800;

interface Props {
	zoomLevel?: number;
	onZoomInOut?: (value: number) => void;
	visibleCharts?: string[];
	handleChartsVisibility?: (event: React.ChangeEvent<{ value: unknown }>) => void;
	children: ReactNode;
}

const getCurrentZoomLevel = (zoomLevel: number, zoomOptionsList: ZoomLevel[]): ZoomLevel => {
	return (
		zoomOptionsList.find((option): boolean => option.value === zoomLevel) || {
			title: `${(zoomLevel * 100).toFixed(0)}%`,
			value: zoomLevel,
		}
	);
};

const AppBar: FC<Props> = ({ zoomLevel, onZoomInOut, visibleCharts, handleChartsVisibility, children }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const selectedProject = useSelector(getSelectedProject);
	const selectedProjectIds = useSelector(getProjectIdsFromSelectedSoilInvestigationsList);
	const searchTerm = useSelector(getSearchTerm);
	const isInterpretationPage = location.pathname.includes('interpretation');
	const [value, setValue] = useState<ZoomLevel>();

	useEffect((): void => {
		if (zoomLevel) {
			setValue(getCurrentZoomLevel(zoomLevel, zoomDefaultLevelsOptions));
		}
	}, [onZoomInOut, zoomLevel]);

	const onChange = (_event: React.ChangeEvent<{}>, newValue: ZoomLevel | string | null): void => {
		if (!onZoomInOut) return;
		if (typeof newValue === 'string') {
			let v = newValue.replace('%', '');
			v = isNaN(+v) ? '100' : +v < MIN_ZOOM_LEVEL ? MIN_ZOOM_LEVEL.toFixed(0) : +v > MAX_ZOOM_LEVEL ? MAX_ZOOM_LEVEL.toFixed(0) : v;
			onZoomInOut(+v / 100);
		} else if (newValue) {
			onZoomInOut(newValue.value);
		} else {
			onZoomInOut(zoomDefaultLevelsOptions[1].value);
		}
	};

	const handleDeselecting = (isHome: boolean): void => {
		if (isHome) {
			dispatch(setSelectedProject(undefined));

			const inStore = localStorage.getItem('mondriaan');
			const current = inStore == null ? {} : JSON.parse(inStore);
			current.lastSeenProjectId = null;
			localStorage.setItem('mondriaan', JSON.stringify(current));
		}

		dispatch(setSearchTerm(''));
		dispatch(setSelectedSoilInvestigationIds([]));
		dispatch(setSelectedSoilInvestigations({}));
		dispatch(resetSelectedSoilLayer());
	};

	return (
		<AppBarMui position={isInterpretationPage ? 'static' : 'absolute'} classes={{ root: classes.root }}>
			<Toolbar className={classes.topBar}>
				<div className={classes.topBarLeft}>
					<div className={classes.breadcrumbContainer}>
						<img src={logo} alt="mondriaan-logo" />
						<div className={classes.breadcrumb}>
							<Link to="/" onClick={(): void => handleDeselecting(true)}>
								{searchTerm.length ? 'SEARCH RESULTS' : 'HOME'}
							</Link>
							{selectedProject && <ArrowRightIcon />}
							{(isInterpretationPage && selectedProject) && (
								<Link	to={`/projects/${selectedProject?.id}${location.search}`}	onClick={(): void => handleDeselecting(false)}>
									{(selectedProjectIds.length > 1) ? 'MULTIPLE PROJECTS' : selectedProject?.name.toUpperCase()}
								</Link>
							) }
							{(isInterpretationPage && !selectedProject) && (
								<Fragment>
									<ArrowRightIcon />
									<span className={classes.breadcrumbLast}>
										{(selectedProjectIds.length > 1) ? 'MULTIPLE PROJECTS' : 'GENERAL'}
									</span>
								</Fragment>
							)}
							{(!isInterpretationPage && selectedProject) && (
								<span className={classes.breadcrumbLast}>{selectedProject?.name.toUpperCase()}</span>
							)}
							{isInterpretationPage && (
								<Fragment>
									<ArrowRightIcon />
									<span className={classes.breadcrumbLast}>SELECTION</span>
								</Fragment>
							)}
						</div>
					</div>
					{isInterpretationPage && (
						<div className={classes.selectContainer}>
							{visibleCharts && (
								<FormControl
									variant="outlined"
									size="small"
									classes={{
										root: classes.formControl,
										marginDense: classes.formControlMarginDense,
									}}
								>
									<Select
										labelId="demo-mutiple-checkbox-label"
										id="demo-mutiple-checkbox"
										multiple
										value={visibleCharts || []}
										onChange={handleChartsVisibility}
										renderValue={(): string => 'View'}
										IconComponent={KeyboardArrowDownRounded}
										classes={{
											root: classes.chartSelectRoot,
											icon: classes.chartSelectIcon,
										}}
										MenuProps={{
											classes: {
												paper: classes.chartMenuPaper,
											},
										}}
									>
										{Object.values(ChartVisibility).map((chartName) => {
											const disabled = visibleCharts.length <= 1 && visibleCharts.includes(chartName);
											return (
												<MenuItem
													key={chartName}
													value={chartName}
													disabled={disabled}
													classes={{
														selected: classes.chartMenuItemSelected,
														root: classes.chartMenuItemRoot,
													}}
												>
													<StyledCheckbox
														checked={visibleCharts.indexOf(chartName) > -1}
														classes={{
															checked: classes.chartItemCheckboxChecked,
															root: classes.chartItemCheckboxRoot,
														}}
													/>
													<ListItemText primary={chartName} />
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}

							<div className={classes.zoomHandler}>
								<span className={classes.zoomLabel}>ZOOM</span>
								<Autocomplete
									classes={{
										endAdornment: classes.selectEndAdornment,
										root: classes.select,
										input: classes.input,
										inputRoot: classes.inputRoot,
									}}
									value={
										value || {
											title: '100%',
											value: 1,
										}
									}
									onChange={onChange}
									options={zoomDefaultLevelsOptions}
									getOptionLabel={(option): string => option?.title || `${option}`}
									inputMode="numeric"
									freeSolo
									disableClearable
									forcePopupIcon
									popupIcon={<KeyboardArrowDownRounded className={classes.icon} />}
									renderInput={(params): ReactNode => {
										return <OutlinedTextField classes={{ root: classes.autocomplete }} {...params} variant="outlined" />;
									}}
								/>
							</div>
						</div>
					)}
				</div>
				<div className={classes.topBarRight}>{children}</div>
			</Toolbar>
		</AppBarMui>
	);
};

export default AppBar;
