import React, { ComponentType, FC, ReactNode } from 'react';
import { Switch, Route as ReactRoute, RouteComponentProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import * as routes from '@/routes';
import NotFound from '@/pages/NotFound';
import ProjectsMap from '@/pages/ProjectsMap';
import Interpretation from '@/pages/Interpretation';

interface RouteProps<R> {
	route: routes.RouteInformation<R>;
	component: ComponentType<RouteComponentProps> | ComponentType;
	exact?: boolean;
}

function Route<R>({ route, component: Comp, exact }: RouteProps<R>): JSX.Element {
	return <ReactRoute exact={exact} path={route.templatePath} render={(props): ReactNode => <Comp {...props} />} />;
}

const index: FC = (): JSX.Element => (
	<Switch>
		{Route({ route: routes.projectsmap, component: ProjectsMap, exact: true })}
		{Route({ route: routes.projectSelected, component: ProjectsMap, exact: true })}
		{Route({ route: routes.interpretationProjectSelected, component: Interpretation, exact: false })}
		{Route({ route: routes.interpretationGeneral, component: Interpretation, exact: true })}
		{Route({ route: routes.notFound, component: NotFound, exact: true })}
	</Switch>
);

export default withAuthenticationRequired(index);
