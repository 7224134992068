import React, { FC, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProjectItem from '../ProjectItem';
import MapSideBarSkeleton from '../SideBarSkeleton';
import classes from './index.module.css';
import { Project } from '@/store/types';
import { getRetrievingProjectsFailed, getSearchTerm } from '@/store/selectors';

type Props = {
	sortedProjects: Project[],
}

const ProjectList: FC<Props> = ({ sortedProjects }): JSX.Element => {
	const [isInitiallyLoading, setIsInitiallyLoading] = useState(true);
	const retrievingProjectsFailed = useSelector(getRetrievingProjectsFailed);
	const searchTerm = useSelector(getSearchTerm);
	const sortedProjectSliceEnd = searchTerm === '' ? 3 : sortedProjects.length;

	useEffect(() => {
		const timer = setTimeout(() => { setIsInitiallyLoading(false); }, 5000);
		return (): void => clearTimeout(timer);
	}, []);

	const header = sortedProjects.length ? (searchTerm === '' ? 'RECENT PROJECTS' : 'PROJECTS') : '';

	return (
		<div className={classes.projectList}>
			<Fragment>
				<h4 className={classes.header}>{header}</h4>
				{
					sortedProjects.slice(0, sortedProjectSliceEnd).map((project) => (
						<ProjectItem key={project.id} project={project} />
					))
				}
				{	(retrievingProjectsFailed && !sortedProjects.length ) && <div className={classes.emptyText}>Failed to retrieve projects.</div>	}
				{
					(!sortedProjects.length && !isInitiallyLoading && !retrievingProjectsFailed)
									&& <div className={classes.emptyText}>No matching results were found.</div>
				}
				{(!sortedProjects.length && isInitiallyLoading && !retrievingProjectsFailed) && <MapSideBarSkeleton items={2} />}
			</Fragment>
		</div>
	);
};

export default ProjectList;
