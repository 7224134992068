export const EVENT_ERROR = 'Error';
export const EVENT_UPLOAD = 'File uploading';
export const EVENT_CONVERT = 'File export';
export const EVENT_INTERPRETATION_CHANGED = 'Change of the interpretation method';
export const EVENT_CREATE_PROJECT = 'Project creation';
export const EVENT_IMPORT_SOIL_INVESTIGATION_TO_PROJECT = 'Import Soil Investigation to project';
export const EVENT_SOIL_INVESTIGATION_IS_DELETED = 'Soil Investigation File deletion';
export const EVENT_PROJECT_IS_DELETED = 'Project deleted';
export const EVENT_SOIL_TYPE_CREATED = 'Project specific soil type creation';
export const EVENT_INTERPRETATION_OVERRIDEN = 'Manual file interpretation';
export const EVENT_SEARCHED_PROJECT = 'Project search';
export const EVENT_PROJECT_WAS_VIEWED = 'Project viewed';
export const EVENT_FILE_WAS_VIEWED = 'File viewing';
