import { applyMiddleware, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import launchSagas from '@/sagas';
import createReducer from './reducers';
import { AppState } from './types';
import { createMixPanelMiddleware } from './mixPanelMiddleware';

export const history = createBrowserHistory();

const isDevelopment = process.env.NODE_ENV !== 'production';
const sagaMiddleware = createSagaMiddleware();
const mixPanelMiddleware = createMixPanelMiddleware();

const store: Store<AppState> = configureStore({
	reducer: createReducer(history),
	devTools: isDevelopment,
	enhancers: [
		applyMiddleware(routerMiddleware(history), sagaMiddleware),
	],
	middleware: [
		mixPanelMiddleware,
	],
});

sagaMiddleware.run(launchSagas);

export default store;
