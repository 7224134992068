import React, { FC, forwardRef, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, Label } from 'recharts';
import { GraphProps } from '@/models/types/graphs';
import CustomTooltip from '@/components/Interpretation/CustomTooltip';
import CustomDot from '@/components/Interpretation/CustomDot';
import { ChartVisibility, HoverProps } from '@/models/types';
import { getChartsVisibility } from '@/store/selectors';
import { replaceHyphensWithMinus } from '@/helpers';
import classes from './index.module.css';
import GraphSkeleton from '../GraphSkeleton';

const ConeResistanceGraph: FC<GraphProps> = forwardRef<HTMLElement, GraphProps>((props, ref) => {
	const visibleCharts = useSelector(getChartsVisibility);
	const [isActive, setIsActive] = useState(false);
	const { cpt, computedMeasurements, xAxisHeight, rightMargin, visibility, children } = props;
	const {
		graphWidth,
		graphHeight,
		gridVerticalPoints,
		gridHorizontalPoints,
		lastMeasurementRounded,
		finalXTick,
		yAxisWidth,
	} = computedMeasurements;

	return (
		<div className={classes.container}>
			<span className={classes.purpleBar} ref={ref} />
			<div className={classes.headerContainer}>
				<span className={classes.header}>
					Cone Resistance q<sub>c</sub> [MPa]
				</span>
			</div>
			{visibility ? (
				<LineChart
					layout="vertical"
					width={graphWidth}
					height={graphHeight}
					data={cpt.recordsList.filter((record) => record.coneResistance > 0)}
					syncId={cpt.id}
					throttleDelay={0}
					margin={{
						top: 0,
						right: rightMargin,
						left: 0,
						bottom: 0,
					}}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onMouseMove={(_: any): void => {
						if (!isActive) {
							setIsActive(true);
						}
					}}
					onMouseLeave={(): void => {
						if (isActive) {
							setIsActive(false);
						}
					}}
				>
					<CartesianGrid
						verticalPoints={gridVerticalPoints}
						horizontalPoints={gridHorizontalPoints}
						y={xAxisHeight}
						stroke="#dddddd"
					/>
					<XAxis
						type="number"
						domain={[0, finalXTick]}
						orientation="top"
						allowDecimals={false}
						tickCount={finalXTick / 10 + 1}
						allowDataOverflow
						interval="preserveStartEnd"
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						type="number"
						width={yAxisWidth}
						domain={[lastMeasurementRounded, Math.ceil(cpt.z)]}
						dataKey="depthBasedOnZValue"
						tick={false}
						axisLine={false}
						reversed
					/>
					<Tooltip
						wrapperStyle={{ zIndex: 999 }}
						offset={10}
						isAnimationActive={false}
						content={<CustomTooltip shouldDisplay={isActive} />}
						animationEasing="ease-in-out"
					/>
					<Line
						type="monotoneY"
						dataKey="coneResistance"
						stroke="#ab0f6e"
						dot={false}
						activeDot={(props: HoverProps): ReactElement<SVGElement> =>
							((<CustomDot cx={props.cx} cy={props.cy} r={props.r} />) as unknown) as ReactElement<SVGElement, string>
						}
						style={{ margin: '3px' }}
					/>
					<ReferenceArea x1={0} x2={5} y1={cpt.z} y2={cpt.z - 0.00001} stroke="#555555">
						{!visibleCharts.includes(ChartVisibility.WATER_PRESSURE) && (
							<Label position="left">
								{replaceHyphensWithMinus(cpt.z.toLocaleString(undefined, { maximumFractionDigits: 2 }))}
							</Label>
						)}
					</ReferenceArea>
					{children}
				</LineChart>
			) : (
				<GraphSkeleton width={graphWidth} height={graphHeight} />
			)}
		</div>
	);
});

export default React.memo(ConeResistanceGraph);
