import { createAction } from '@reduxjs/toolkit';
import { Project, UpdateProjectData } from '@/proto/build/project_pb';
import { ProjectProgress, SelectProjectPayload, UploadMethod } from '@/models/types';
import { Project as ProjectType } from '@/store/types';
import { ImportError } from '../types';

/* Action Types */
enum ActionTypes {
	CREATE_PROJECT = '@@mondriaan/PROJECT/CREATE',
	UPDATE_PROJECT = '@@mondriaan/PROJECT/UPDATE',
	ADD_MULTIPLE_PROJECTS = '@@mondriaan/PROJECT/ADD_MULTIPLE',
	SET_SELECTED_PROJECT = '@@mondriaan/PROJECT/SET_SELECTED',
	UPDATE_PROJECT_ERRORS = '@@mondriaan/PROJECT_ERRORS/UPDATE',
	RESET_PROJECT_ERRORS = '@@mondriaan/PROJECT_ERRORS/RESET',
	UPDATE_PROGRESS_BAR = '@@mondriaan/PROGRESS_BAR/UPDATE',
	CREATE_PROJECT_FAILED = '@@mondriaan/PROJECT/CREATE_FAILED',
	IMPORT_SOIL_INVESTIGATION_TO_PROJECT = '@@mondriaan/PROJECT/IMPORT_SOIL_INVESTIGATION',
	IMPORT_SOIL_INVESTIGATION_TO_PROJECT_FAILED = '@@mondriaan/PROJECT/IMPORT_SOIL_INVESTIGATION_FAILED',
	DELETE_PROJECT = '@@mondriaan/PROJECT/DELETE',
	ADD_SOIL = '@@mondriaan/PROJECT/ADD_SOIL',
	REMOVE_SOIL = '@@mondriaan/PROJECT/REMOVE_SOIL',
}

export const createProject = createAction<{ project: Project.AsObject; successfulSoilInvestigation: number; failedSoilInvestigation: number;
	uploadTime: string; uploadMethod?: UploadMethod; }>(ActionTypes.CREATE_PROJECT);
export const updateProject = createAction<{ project: UpdateProjectData.AsObject; oldProject: ProjectType }>(ActionTypes.UPDATE_PROJECT);
export const addMultipleProjects = createAction<Project.AsObject[]>(ActionTypes.ADD_MULTIPLE_PROJECTS);
export const setSelectedProject = createAction<number | SelectProjectPayload | undefined>(
	ActionTypes.SET_SELECTED_PROJECT,
);
export const updateProjectError = createAction<ImportError>(ActionTypes.UPDATE_PROJECT_ERRORS);
export const resetProjectErrors = createAction(ActionTypes.RESET_PROJECT_ERRORS);

export const updateProgressBar = createAction<ProjectProgress>(ActionTypes.UPDATE_PROGRESS_BAR);
export const createProjectFailed = createAction<boolean>(ActionTypes.CREATE_PROJECT_FAILED);
export const deleteProjectSuccess = createAction<number>(ActionTypes.DELETE_PROJECT);
export const deleteProjectFailed = createAction<{ projectId: number; msg: string }>(ActionTypes.DELETE_PROJECT);

export const importSoilInvestigationToProject = createAction<{
	project: Project.AsObject;
	successfulSoilInvestigation: number;
	failedSoilInvestigation: number;
	uploadTime: string; uploadMethod?: UploadMethod; }>(ActionTypes.IMPORT_SOIL_INVESTIGATION_TO_PROJECT);
export const importSoilInvestigationToProjectFailed = createAction<string>(ActionTypes.IMPORT_SOIL_INVESTIGATION_TO_PROJECT_FAILED);

export const addSoilToProject = createAction<{ projectId: number; projectName: string; categoryName: string; soilId: number }>(ActionTypes.ADD_SOIL);
export const deleteSoilFromProject = createAction<{ projectId: number; soilId: number }>(ActionTypes.REMOVE_SOIL);
