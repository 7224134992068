import React, { FC, ReactNode, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import {
	isSoilInvestigationLayerSelected,
	getSelectedSoilInvestigation,
	getSelectedSoilInvestigationSoilLayer,
} from '@/store/selectors';
import { getSoilLibraries, getSoils } from '@/store/selectors/soil';
import { isSelectedLayerSplittable } from '@/helpers';
import { createSoilLayer, deleteSoilLayer, updateSoilLayer, resetSelectedSoilLayer, splitSoilLayer } from '@/store/actions/soillayer';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { TextButtonOrange, TextRaisedButton } from '@/components/custom/buttons';
import { PROPOSED_LAYER_SOIL_ID } from '@/models/constants';
import { SoilInvestigationIdWithLayerPayload } from '@/models/types';
import { SoilLayer } from '@/proto/build/cpt_pb';
import { Soil, SoilPattern } from '@/proto/build/soil_pb';
import classes from './index.module.css';

/* Dynamically include all soil layer images */
const imageContext = require.context('@/assets/images/patterns', false, /\.(svg)$/);

const SoilMaterialHandler: FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const isSoilLayerSelected = useSelector(isSoilInvestigationLayerSelected);
	const soilMaterials = useSelector(getSoils);
	const soilLibraries = useSelector(getSoilLibraries);
	const selectedSoilInvestigation = useSelector(getSelectedSoilInvestigation);
	const selectedSoilInvestigationSoilLayer = useSelector(getSelectedSoilInvestigationSoilLayer) as SoilLayer.AsObject;
	const soilIds = Object.values(soilLibraries).flatMap((lib) => lib.soilsList);

	const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.preventDefault();
		if (selectedSoilInvestigationSoilLayer) {
			dispatch(
				deleteSoilLayer({
					soilInvestigationId: selectedSoilInvestigation?.id.toString() || '',
					id: selectedSoilInvestigationSoilLayer.id.toString(),
				}),
			);
		}

		dispatch(resetSelectedSoilLayer());
	};

	const handleSplitClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.preventDefault();
		if (selectedSoilInvestigationSoilLayer) {
			dispatch(
				splitSoilLayer({
					soilInvestigationId: selectedSoilInvestigation?.id.toString() || '',
					id: selectedSoilInvestigationSoilLayer.id.toString(),
				}),
			);
		}
	};

	const onMaterialChange = (event: React.ChangeEvent<{}>, newValue: Soil.AsObject | string): void => {
		event.preventDefault();
		if (typeof newValue === 'string') return;

		if (selectedSoilInvestigationSoilLayer) {
			const newLayer: SoilLayer.AsObject = {
				...selectedSoilInvestigationSoilLayer,
				soilId: newValue.id,
			};

			const payload: SoilInvestigationIdWithLayerPayload = {
				soilInvestigationId: selectedSoilInvestigation?.id.toString() || '',
				layer: newLayer,
			};

			!selectedSoilInvestigationSoilLayer.soilId ? dispatch(createSoilLayer(payload)) : dispatch(updateSoilLayer(payload));
		}
	};

	const getPatternUrl = (soilLibraryId: number): string | undefined => {
		const pattern = soilLibraries[soilLibraryId].pattern;
		if (pattern) {
			return imageContext(`./${Object.keys(SoilPattern)[pattern.pattern].toLowerCase()}.svg`);
		}
	};

	return (
		<div className={classes.root}>
			{isSoilLayerSelected && selectedSoilInvestigationSoilLayer && selectedSoilInvestigationSoilLayer?.soilId !== PROPOSED_LAYER_SOIL_ID && (
				<div
					className={classes.labelColorBox}
					style={{
						backgroundColor: soilMaterials[selectedSoilInvestigationSoilLayer.soilId].color,
						backgroundImage: soilLibraries[soilMaterials[selectedSoilInvestigationSoilLayer.soilId].soilLibraryId].pattern !== undefined ?
							`url(${getPatternUrl(soilMaterials[selectedSoilInvestigationSoilLayer.soilId].soilLibraryId)})` : '',
					}}
				/>
			)}
			<Autocomplete
				classes={{ inputRoot: classes.inputRoot, endAdornment: classes.selectEndAdornment,
					groupLabel: classes.groupLabel, option: classes.option }}
				value={selectedSoilInvestigationSoilLayer?.soilId ? soilMaterials[selectedSoilInvestigationSoilLayer.soilId] : ''}
				onChange={onMaterialChange}
				disabled={!isSoilLayerSelected}
				options={Object.values(soilMaterials).filter((soil) => soilIds.includes(soil.id))}
				groupBy={(soilType): string => soilLibraries[soilType.soilLibraryId].name}
				getOptionLabel={(option): string => option?.name || ''}
				inputMode="text"
				freeSolo
				disableClearable
				forcePopupIcon
				popupIcon={<KeyboardArrowDownRounded className={classes.icon} />}
				renderInput={(params): ReactNode => (
					<OutlinedTextField
						{...params}
						className={classes.autocompleteSelectedText}
						variant="outlined"
						label="Material"
						InputLabelProps={{
							margin: 'dense',
						}}
					/>
				)}
				renderOption={(option): ReactNode => (
					<div className={classes.soilMaterial}>
						<span className={classes.colorBox} style={{ backgroundColor: option.color }}>
							{soilLibraries[option.soilLibraryId].pattern && <img
								src={getPatternUrl(option.soilLibraryId)}
								alt="Category pattern"
								className={classes.optionImage}
							/>}
						</span>
						<span className={classes.autocompleteText}>{option?.name}</span>
					</div>
				)}
			/>
			{isSoilLayerSelected && selectedSoilInvestigationSoilLayer && selectedSoilInvestigationSoilLayer.soilId !== PROPOSED_LAYER_SOIL_ID && (
				<div className={classes.buttons}>
					<TextButtonOrange className={classes.removeBtn} onClick={handleDeleteClick}>
						REMOVE LAYER
					</TextButtonOrange>
					<TextRaisedButton
						className={classes.splitBtn}
						disabled={!isSelectedLayerSplittable(selectedSoilInvestigationSoilLayer)}
						onClick={handleSplitClick}
					>
						SPLIT LAYER
					</TextRaisedButton>
				</div>
			)}
		</div>
	);
};

export default SoilMaterialHandler;
