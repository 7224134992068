import React, { FC } from 'react';
import classes from './index.module.css';


interface WarningChipProps {
	message: string;
}

const WarningChip: FC<WarningChipProps> = ({ message }) => {
	return (
		<div className={classes.root}>
			<span>{message}</span>
		</div>
	);
};

export default WarningChip;
