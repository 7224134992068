import React, { FC, Fragment, useEffect, useState } from 'react';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Tabs, MenuItem, Typography, Tooltip } from '@material-ui/core';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { checkUniqueProjectName, createProjectCmd, uploadSoilInvestigationsToProjectCmd } from '@/net';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import StyledTab from '@/components/custom/tabs';
import TabPanel from '@/components/custom/TabPanel';
import { TextButtonNeutral, TextButtonTurquiose } from '@/components/custom/buttons';
import OutlinedTextFieldWithTooltip from '@/components/custom/OutlinedTextFieldWithTooltip';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { CreateProjectData } from '@/proto/build/project_pb';
import { UploadFileChunk } from '@/proto/build/cpt_pb';
import { UploadMethod, getDescriptionOf } from '@/models/types';
import { Project } from '@/store/types';
import { capitalizeFirstLetter, getNumberWith1DecimalPlace, onKeyPress } from '@/helpers';
import { getProjects } from '@/store/selectors';
import classes from './index.module.css';

interface Props {
	files: UploadFileChunk.AsObject[];
	uploadMethod?: UploadMethod;
	showDialog: boolean;
	setShowDialog: (value: boolean) => void;
	clearFiles: () => void;
}

const tabsTitles = ['MY PROJECT', 'NEW PROJECT'];
const myProjectTabIndex = 0;
const newProjectTabIndex = 1;

const defaultState: CreateProjectData.AsObject = {
	name: '',
	description: '',
	abwReference: '',
	interpretationMethod: InterpretationMethod.MANUAL,
	minimumLayerThickness: 0.1,
	waterLevel: 0,
	soilInvestigationsList: [],
};

export const ProjectDialog: FC<Props> = ({ showDialog, setShowDialog, files, clearFiles, uploadMethod }: Props) => {
	const dispatch = useDispatch();
	const projects = useSelector(getProjects);
	const [tempProject, setTempProject] = useState<CreateProjectData.AsObject>(defaultState);
	const [activeTab, setActiveTab] = useState<number>(myProjectTabIndex);
	const [reasonNameIsInvalid, setReasonNameIsInvalid] = useState<string>('');
	const [reasonWaterLevelIsInvalid, setReasonWaterLevelIsInvalid] = useState<string>('');
	const [selectedProject, setSelectedProject] = useState<number>();
	const sortOptions = ['Created', 'Alphabetically'];
	const [selectedSortOption, setSelectedSortOption] = useState<string>('Created');
	const [sortedProject, setSortedProject] = useState<Project[]>([]);

	useEffect(() => {
		setTempProject((old) => {
			return {
				...old,
				soilInvestigationsList: files,
			};
		});
	}, [files]);

	function isUploadEnabled(): boolean {
		return (
			activeTab === newProjectTabIndex ||
			sortedProject.length === 0 ||
			(activeTab === myProjectTabIndex && selectedProject !== undefined)
		);
	}

	function resetState(): void {
		setTempProject(defaultState);
		clearFiles();
		setSelectedProject(undefined);
		setShowDialog(false);
	}

	// Don't show tab "My Projects" if project list is empty
	function getAvailableTabs(): string[] {
		if (sortedProject.length > 0) return tabsTitles;

		return [tabsTitles[1]];
	}

	// Handle the Create project and upload the files (to the server)
	const handleUpload = (): void => {
		if (activeTab === myProjectTabIndex && selectedProject) {
			uploadSoilInvestigationsToProjectCmd(selectedProject, files, dispatch, uploadMethod);
			resetState();
		} else {
			if (tempProject.name && tempProject.waterLevel !== undefined) {
				const nameAsStringValue = new StringValue();
				nameAsStringValue.setValue(tempProject.name);

				checkUniqueProjectName(nameAsStringValue)
					.then((nameIsUnique) => {
						if (nameIsUnique.getValue()) {
							createProjectCmd(tempProject, dispatch, uploadMethod);
							resetState();
						} else {
							setReasonNameIsInvalid('This name is already in use');
						}
					})
					.catch((e) => {
						console.error(e);
					});
			} else {
				setReasonNameIsInvalid(!tempProject.name ? 'This field is mandatory' : '');
				setReasonWaterLevelIsInvalid(!tempProject.waterLevel ? 'This field is mandatory' : '');
			}
		}
	};

	// Handle the tab change
	const handleTabChange = (event: React.ChangeEvent<{}>, value: number): void => {
		event.preventDefault();
		setActiveTab(value);
	};

	// TODO add debounce when typing to the textfield
	// Handle any of the textfield change update the respective tempProject prop
	const handleTextFieldOnChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		input: string,
	): void => {
		let value = input === 'minimumLayerThickness' ? getNumberWith1DecimalPlace(event.target.value) : event.target.value;

		if (input === 'name') {
			setReasonNameIsInvalid(!value ? 'This field is mandatory' : '');
		} else if (input === 'waterLevel') {
			setReasonWaterLevelIsInvalid(!value ? 'This field is mandatory' : '');
		} else if (input === 'minimumLayerThickness') {
			if (value !== undefined && Number(value) > 1) {
				value = 1;
			}
		}

		setTempProject((old) => {
			return {
				...old,
				[`${input}`]: value,
			};
		});
	};

	const handleCloseDialog = (): void => {
		resetState();
	};

	const DialogActions = (
		<Fragment>
			<TextButtonNeutral onClick={handleCloseDialog}>CANCEL</TextButtonNeutral>
			<TextButtonTurquiose disabled={!isUploadEnabled()} onClick={handleUpload}>
				UPLOAD
			</TextButtonTurquiose>
		</Fragment>
	);

	const rootClassForProjectName = (): string => {
		return reasonNameIsInvalid ? classes.error : '';
	};

	const getCreatedAtMessage = (seconds: number | undefined): string => {
		if (seconds) {
			const createdAtDate = new Date(seconds * 1000);
			if (isToday(createdAtDate)) {
				const minutes = createdAtDate.getMinutes();
				return 'Created today at ' + createdAtDate.getHours() + ':' + (minutes < 10 ? '0' + minutes : minutes);
			} else {
				return (
					'Created on ' +
					createdAtDate.getDate() +
					' ' +
					createdAtDate.toLocaleString('default', { month: 'short' }) +
					' ' +
					createdAtDate.getFullYear()
				);
			}
		}
		return 'invalid date';
	};

	const isToday = (date: Date): boolean => {
		const today = new Date();
		return (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		);
	};

	const selectProject = (event: React.MouseEvent, id: number): void => {
		event.preventDefault();
		setSelectedProject(id);
	};

	useEffect(() => {
		let projectList: Project[];
		if (selectedSortOption === 'Created') {
			// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
			projectList = Object.values(projects).sort((a, b) => b.createdAt?.seconds! - a.createdAt?.seconds!);
			setSortedProject(projectList);
		} else {
			projectList = Object.values(projects).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
			setSortedProject(projectList);
		}
		// Pre-select first project if none is selected (to be UX-checked
		if (!selectedProject && projectList.length > 0) setSelectedProject(projectList[0].id);
	}, [projects, selectedProject, selectedSortOption]);

	return (
		<BaseDialog
			title="Select a project"
			isOpen={showDialog}
			closeDialog={handleCloseDialog}
			actionButtons={DialogActions}
		>
			<Fragment>
				<div className={classes.controls}>
					{sortedProject.length > 0 && activeTab === myProjectTabIndex && (
						<OutlinedTextField
							select
							value={selectedSortOption}
							label="Sorted by"
							className={classes.sortedBy}
							onChange={(e): void => setSelectedSortOption(e.target.value)}
							SelectProps={{ IconComponent: KeyboardArrowDownRounded }}
							type="text"
						>
							{sortOptions.map((sortOption) => {
								return (
									<MenuItem key={sortOption} value={sortOption}>
										{capitalizeFirstLetter(sortOption.toLocaleLowerCase())}
									</MenuItem>
								);
							})}
						</OutlinedTextField>
					)}
					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						TabIndicatorProps={{
							style: {
								backgroundColor: '#01cebd',
							},
						}}
					>
						{getAvailableTabs().map((tab, index) => {
							return <StyledTab key={`Tab_${tab}`} label={tab} value={index} />;
						})}
					</Tabs>
				</div>
				<TabPanel value={activeTab} index={getAvailableTabs().length - 1}>
					<div className={classes.fields}>
						<div className={classes.column}>
							<OutlinedTextField
								fullWidth
								value={tempProject.name}
								className={classes.textField}
								label="Name"
								classes={{ root: rootClassForProjectName() }}
								onChange={(e): void => handleTextFieldOnChange(e, 'name')}
								onKeyPress={onKeyPress}
								inputProps={{
									maxLength: 100,
									autoComplete: 'off',
								}}
								type="text"
								helperText={reasonNameIsInvalid}
							/>
							<OutlinedTextField
								fullWidth
								value={tempProject.description}
								className={classNames(classes.textField, classes.textArea)}
								multiline
								minRows={5}
								label="Description"
								onChange={(e): void => handleTextFieldOnChange(e, 'description')}
								onKeyPress={onKeyPress}
								type="text"
							/>
						</div>
						<div className={classes.column}>
							<OutlinedTextField
								fullWidth
								value={tempProject.abwReference}
								className={classes.textField}
								label="ABW Reference"
								onChange={(e): void => handleTextFieldOnChange(e, 'abwReference')}
								onKeyPress={onKeyPress}
								inputProps={{
									maxLength: 15,
									form: {
										autocomplete: 'off',
									},
								}}
								type="text"
							/>
							<Typography style={{ marginBottom: '11px' }}>
								Information for CPT interpretation
								<Tooltip
									classes={{ tooltip: classes.tooltip }}
									title={
										`Please, fill in some additional information for the interpretation of the CPTs. 
									   If you are only uploading boreholes you can ignore this section.`}
									placement="top"
									enterDelay={400}
									enterNextDelay={400}
								>
									<InfoRounded className={classes.infoIcon} />
								</Tooltip>
							</Typography>
							<OutlinedTextField
								fullWidth
								select
								value={tempProject.interpretationMethod}
								className={classes.textField}
								label="Interpretation Method"
								disabled={Object.keys(InterpretationMethod).length <= 1}
								onChange={(e): void => handleTextFieldOnChange(e, 'interpretationMethod')}
								SelectProps={{ IconComponent: KeyboardArrowDownRounded }}
								type="text"
							>
								{Object.entries(InterpretationMethod).map(([key, value], index) => {
									return (
										<MenuItem key={key} value={index}>
											{getDescriptionOf(value as InterpretationMethod)}
										</MenuItem>
									);
								})}
							</OutlinedTextField>
							<div className={classes.fieldContainer}>
								<OutlinedTextField
									fullWidth
									value={tempProject.minimumLayerThickness}
									className={classes.textField}
									label="Min Layer"
									onChange={(e): void => handleTextFieldOnChange(e, 'minimumLayerThickness')}
									onKeyPress={onKeyPress}
									inputProps={{ step: 0.1, lang: navigator.language, min: 0.1 }}
									InputProps={{
										endAdornment: <span className={classes.endAdornment}>m</span>,
									}}
									type="number"
								/>
								<OutlinedTextFieldWithTooltip
									fullWidth
									id="water-level"
									label="Ground Water"
									value={tempProject.waterLevel}
									className={classes.outlinedInput}
									onChange={(e): void => handleTextFieldOnChange(e, 'waterLevel')}
									onKeyPress={onKeyPress}
									inputProps={{ step: 0.1, lang: navigator.language }}
									endAdornment={<span className={classes.endAdornment}>m</span>}
									error={!!reasonWaterLevelIsInvalid}
									type="number"
									helperText={reasonWaterLevelIsInvalid}
									tooltipText="Please, fill in the ground water level (depth compared to NAP) for your project.
										It will be used when defining soil layers with automatic Interpretation methods."
								/>
							</div>
						</div>
					</div>
				</TabPanel>
				<TabPanel value={activeTab} index={myProjectTabIndex} isHidden={sortedProject.length === 0}>
					<div className={classes.myProjects}>
						{Object.values(sortedProject).map((project) => {
							return (
								<div
									key={project.id}
									onClick={(e): void => selectProject(e, project.id)}
									className={classNames(classes.myProject, {
										[classes.myProjectSelected]: project.id === selectedProject,
									})}
								>
									<div className={classes.myProjectFirstLine}>
										<b className={classes.myProjectTitle}>{project.name}</b> {project.abwReference}
									</div>
									<div>{getCreatedAtMessage(project.createdAt?.seconds.valueOf())}</div>
								</div>
							);
						})}
					</div>
				</TabPanel>
			</Fragment>
		</BaseDialog>
	);
};

export default ProjectDialog;
