import { BoolValue, Int64Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { ClientReadableStream, Metadata } from 'grpc-web';
import { GrpcError } from '@/models/classes';
import { CPTServiceClient } from '@/proto/build/cpt_grpc_web_pb';
import { Interpretation } from '@/proto/build/cpt_pb';
import { ProjectServiceClient, ProjectServicePromiseClient } from '@/proto/build/project_grpc_web_pb';
import {
	AllProjects,
	CreateProjectData,
	CreateProjectResult,
	UpdateProjectData,
	InterpretationsToExport,
	OpenGroundZippedCSV,
	ImportSoilInvestigationToProject,
	ImportSoilInvestigationToProjectResult,
} from '@/proto/build/project_pb';
import { SoilLibraryServicePromiseClient } from '@/proto/build/soil_grpc_web_pb';
import { NewCustomSoil, Soil, SoilLibraryAndCategoryList, UpdateCustomSoilData } from '@/proto/build/soil_pb';
import { SoilInvestigationServiceClient } from '@/proto/build/soilInvestigation_grpc_web_pb';
import { SimpleSoilInvestigationList, SoilInvestigationIdList, SoilInvestigationList } from '@/proto/build/soilInvestigation_pb';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';
type EmptyStream = ClientReadableStream<Empty>;
class AuthenticationError extends Error {}

class MondriaanApi {
	// gRPC services
	private readonly soilLibraryServiceClient = new SoilLibraryServicePromiseClient(backendUrl);
	private readonly soilInvestigationServiceClient = new SoilInvestigationServiceClient(backendUrl);
	private readonly cptServiceClient = new CPTServiceClient(backendUrl);
	private readonly projectServiceClient = new ProjectServiceClient(backendUrl);
	private readonly projectServiceClientUsingPromise = new ProjectServicePromiseClient(backendUrl);

	private metadata: Metadata;
	private accessToken: string;

	constructor() {
		this.accessToken = '';
		this.metadata = {};
	}

	private validateToken(): void {
		if (!this.accessToken)
			throw new AuthenticationError('No auth0 access token available!');
	}

	private rebuildMetadata(): void {
		this.validateToken();
		this.metadata.authorization = `Bearer ${this.accessToken}`;
	}

	public setAccessToken(newToken?: string): void {
		this.accessToken = newToken || '';
		this.rebuildMetadata();
	}

	/* Project RPCs */
	getAllProjects = async (): Promise<AllProjects.AsObject> => {
		return (await this.projectServiceClientUsingPromise.getAllProjects(new Empty(), this.metadata)).toObject();
	};

	createProject = (data: CreateProjectData): ClientReadableStream<CreateProjectResult> => {
		return this.projectServiceClient.create(data, this.metadata);
	};

	updateProject = (data: UpdateProjectData, callback: (err: GrpcError, response: Empty) => void): EmptyStream => {
		return this.projectServiceClient.update(data, this.metadata, callback);
	};

	exportToOpenGroundCSV = (cptList: InterpretationsToExport,
		callback: (err: GrpcError, response: OpenGroundZippedCSV) => void): ClientReadableStream<OpenGroundZippedCSV> => {
		return this.projectServiceClient.exportToOpenGroundCSV(cptList, this.metadata, callback);
	};

	checkUniqueProjectName = (name: StringValue, callback: (err: GrpcError, response: BoolValue) => void): ClientReadableStream<BoolValue> => {
		return this.projectServiceClient.checkUniqueProjectName(name, this.metadata, callback);
	};

	importSoilInvestigationsToProject = (request: ImportSoilInvestigationToProject): ClientReadableStream<ImportSoilInvestigationToProjectResult> => {
		return this.projectServiceClient.importSoilInvestigationToProject(request, this.metadata);
	};

	deleteProject = (projectId: Int64Value, callback: (err: GrpcError, response: Empty) => void): ClientReadableStream<Empty> => {
		return this.projectServiceClient.delete(projectId, this.metadata, callback);
	};

	/* Simple Soil investigation by ids */
	getSimpleSoilInvestigationsByIds = (
		request: SoilInvestigationIdList,
		callback: (
			err: GrpcError,
			response: SimpleSoilInvestigationList
		) => void): ClientReadableStream<SimpleSoilInvestigationList> => {
		return this.soilInvestigationServiceClient.getSimpleSoilInvestigations(request, this.metadata, callback);
	};

	/* Soil investigation by id */
	getDetailedSoilInvestigationInfo = (
		request: SoilInvestigationIdList,
		callback: (
			err: GrpcError,
			response: SoilInvestigationList
		) => void): ClientReadableStream<SoilInvestigationList> => {
		return this.soilInvestigationServiceClient.getDetailedSoilInvestigationInfo(request, this.metadata, callback);
	};

	updateInterpretation = (interpretation: Interpretation, callback: (err: GrpcError, response: Empty) => void): EmptyStream => {
		return this.cptServiceClient.updateInterpretation(interpretation, this.metadata, callback);
	};

	deleteSoilInvestigation = (
		soilInvestigationIdList: SoilInvestigationIdList,
		callback: (err: GrpcError, response: Empty) => void,
	): EmptyStream => {
		return this.soilInvestigationServiceClient.deleteSoilInvestigation(soilInvestigationIdList, this.metadata, callback);
	};

	/* Soil libraries */
	getGlobalSoilLibraries = async (): Promise<SoilLibraryAndCategoryList.AsObject> => {
		return (await this.soilLibraryServiceClient.allGlobalLibraries(new Empty(), this.metadata)).toObject();
	};

	createSoilType = async (newSoil: NewCustomSoil): Promise<Soil.AsObject> => {
		return (await this.soilLibraryServiceClient.create(newSoil, this.metadata)).toObject();
	};

	updateSoilType = async (customSoil: UpdateCustomSoilData): Promise<Empty.AsObject> => {
		return (await this.soilLibraryServiceClient.update(customSoil, this.metadata)).toObject();
	};

	deleteSoilType = async (soilId: Int64Value): Promise<Empty.AsObject> => {
		return (await this.soilLibraryServiceClient.delete(soilId, this.metadata)).toObject();
	};

}

export default new MondriaanApi();
