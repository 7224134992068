import { createAction } from '@reduxjs/toolkit';
import { SelectedSoilInvestigationLayer, HoveredSoilInvestigationLayer, SoilInvestigationIdWithLayerPayload, DraggingState } from '@/models/types';

/* Action Types */
enum ActionTypes {
	SET_SELECTED_LAYER = '@@mondriaan/LAYER/SET_SELECTED',
	SET_DRAGGING_STATE = '@@mondriaan/LAYER/SET_DRAGGING_STATE',
	RESET_SELECTED_LAYER = '@@mondriaan/SOIL_LAYER/RESET_SELECTED',

	CREATE_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/CREATE',
	UPDATE_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/UPDATE',
	SPLIT_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/SPLIT',
	DELETE_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/DELETE',
	RESET_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/RESET',

	SET_HOVERED_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/SET_HOVER',
	RESET_HOVERED_SOIL_LAYER = '@@mondriaan/SOIL_LAYER/RESET_HOVER',
}

// Actions to manipulate soillayer from the layersList in cpt
export const createSoilLayer = createAction<SoilInvestigationIdWithLayerPayload>(ActionTypes.CREATE_SOIL_LAYER);
export const updateSoilLayer = createAction<SoilInvestigationIdWithLayerPayload>(ActionTypes.UPDATE_SOIL_LAYER);
export const splitSoilLayer = createAction<SelectedSoilInvestigationLayer>(ActionTypes.SPLIT_SOIL_LAYER);
export const deleteSoilLayer = createAction<SelectedSoilInvestigationLayer>(ActionTypes.DELETE_SOIL_LAYER);

export const resetSelectedSoilLayer = createAction(ActionTypes.RESET_SELECTED_LAYER);
export const resetSoilLayer = createAction(ActionTypes.RESET_SOIL_LAYER);

// Actions to manipulate the selected state
export const setSelectedLayer = createAction<SelectedSoilInvestigationLayer>(ActionTypes.SET_SELECTED_LAYER);
export const setDraggingState = createAction<DraggingState>(ActionTypes.SET_DRAGGING_STATE);

// Actions to manipulate the hovered state
export const setHoveredSoilLayer = createAction<HoveredSoilInvestigationLayer>(ActionTypes.SET_HOVERED_SOIL_LAYER);
export const resetHoveredSoilLayer = createAction(ActionTypes.RESET_HOVERED_SOIL_LAYER);
