import 'polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { create } from 'jss';
import { createTheme } from '@material-ui/core';
import { ThemeProvider, jssPreset, StylesProvider } from '@material-ui/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { grey } from '@material-ui/core/colors';
import Pages from '@/pages';
import store, { history } from '@/store';
import observeHighLatencyTasks from '@/performance';
import { setup as setupMixPanel } from '@/tracking/mixPanel';
import './index.css';
import RouterToStoreConnect from './pages/RouterToStoreConnect';

const theme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: grey[900],
		},
		secondary: {
			main: grey[50],
		},
	},
	typography: {
		fontSize: 14,
		fontFamily: ['Barlow', 'sans-serif'].join(','),
	},
	props: {
		MuiButtonBase: {
			disableRipple: true, // No more ripple, on the whole application 💣!
		},
	},
});

const jss = create({
	...jssPreset(),
	insertionPoint: 'jss-insertion-point',
});

// Use async import here, so the remainder part of the application can already be initialized,
// while this script is still loading in the background

const auth0Domain = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
const auth0ClientId = `${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
const auth0RedirectUrl = `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`;
const apiAudience = `${process.env.REACT_APP_AUTH0_AUDIENCE}`;

const root = (
	<Auth0Provider
		domain={ auth0Domain }
		redirectUri={ auth0RedirectUrl }
		clientId={ auth0ClientId }
		audience={ apiAudience }
	>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<StylesProvider jss={jss}>
					<ThemeProvider theme={theme}>
						<RouterToStoreConnect />
						<Pages />
					</ThemeProvider>
				</StylesProvider>
			</ConnectedRouter>
		</Provider>
	</Auth0Provider>
);

ReactDOM.render(root, document.getElementById('root'));

// Final configurations
observeHighLatencyTasks(process.env.REACT_APP_LATENCY_LIMIT_MS ? +process.env.REACT_APP_LATENCY_LIMIT_MS : 1000);
setupMixPanel(process.env.REACT_APP_MIXPANEL_TOKEN || '');
