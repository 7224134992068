import React, { FC, ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { TextButtonNeutral, TextButtonTurquiose } from '@/components/custom/buttons';

interface Props {
	title: string;
	main: string;
	visible: boolean;
	children?: ReactNode;
	onConfirm: () => void;
	onCancel: () => void;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
}

const ConfirmationDialog: FC<Props> = ({ title, main, visible, onConfirm, onCancel, children, cancelButtonLabel, confirmButtonLabel }) => {
	return (
		<Dialog keepMounted open={visible} onClose={onCancel} >
			<DialogTitle >{title}</DialogTitle>
			<DialogContent>
				<DialogContentText >{main}</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<TextButtonNeutral  onClick={onCancel}>
					{cancelButtonLabel ? cancelButtonLabel : 'No'}
				</TextButtonNeutral>
				<TextButtonTurquiose onClick={onConfirm}>
					{confirmButtonLabel ? confirmButtonLabel : 'Yes'}
				</TextButtonTurquiose>
			</DialogActions>
		</Dialog>);
};

export default ConfirmationDialog;
