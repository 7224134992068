import React, { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { onKeyPress } from '@/helpers';
import classes from './index.module.css';
import { getSearchTerm } from '@/store/selectors';

interface Props {
	setSearchTerm: (value: string) => void;
	fullWidth: boolean;
}

export const SearchBar: FC<Props> = ({ setSearchTerm, fullWidth }) => {
	const searchTerm = useSelector(getSearchTerm);
	return (
		<OutlinedTextField
			value={searchTerm}
			className={classNames(classes.textField, { [classes.projectSelected]: !fullWidth }, { [classes.projectNotSelected]: fullWidth })}
			label="Search"
			onChange={(e): void => setSearchTerm(e.target.value)}
			onKeyPress={onKeyPress}
			inputProps={{
				maxLength: 100,
				autoComplete: 'off',
			}}
			type="text"
		/>
	);
};

export default SearchBar;
