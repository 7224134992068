import React, { FC, useEffect, useLayoutEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { ClusterComponentProps } from '@urbica/react-map-gl-cluster';
import { Marker } from '@urbica/react-map-gl';
import { Project } from '@/store/types';
import { dateTimestampToString, getTimeStamp } from '@/helpers';
import CustomPopup from './CustomPopup';
import classes from './index.module.css';

interface Props {
	markerProps: ClusterComponentProps;
	project: Project;
	selected: boolean;
	onClick: (id: number) => void;
}

const CustomCluster: FC<Props> = ({ markerProps, project, selected = false, onClick }): JSX.Element => {
	const { longitude, latitude, pointCount } = markerProps;
	const [showPopUp, setShowPopUp] = useState<boolean>(false);
	const [updatedAt, setUpdatedAt] = useState<string>('');
	const [showPopUpTimeout, setShowPopUpTimeout] = useState<boolean>(false);

	// TODO: Change createdAt to updatedAt property when available
	useEffect(() => {
		if (project.createdAt) {
			const ts = getTimeStamp(project.createdAt);
			setUpdatedAt(dateTimestampToString(ts));
		}
	}, [project]);

	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (!showPopUp) {
			return;
		}

		const timeout = setTimeout(() => setShowPopUpTimeout(true), 500);
		return (): void => {
			clearTimeout(timeout);
		};
	}, [showPopUp]);

	return (
		<Marker key={project.id.toString()} longitude={longitude} latitude={latitude} anchor="center" onClick={(): void => onClick(project.id)}>
			<div
				className={classNames(classes.cluster, { [classes.clusterSelected]: selected })}
				onMouseOver={(): void => setShowPopUp(true)}
				onMouseOut={(): void => {
					setShowPopUp(false);
					setShowPopUpTimeout(false);
				}}
			>
				{pointCount}
			</div>
			{showPopUpTimeout && (
				<CustomPopup long={longitude} lat={latitude}>
					<span className={classes.name}>{project.name}</span>
					{project.abwReference && <span className={classes.abwReference}>{project.abwReference}</span>}
					{updatedAt && <span className={classes.modified}>Modified: {updatedAt}</span>}
				</CustomPopup>
			)}
		</Marker>
	);
};

export default CustomCluster;
