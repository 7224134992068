import { AnyAction } from '@reduxjs/toolkit';
import { track } from '@/tracking/mixPanel';
import * as events from '@/tracking/events';
import { ExportStatus, getDescriptionOf } from '@/models/types';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import * as actions from '../actions';

export const generateMetricsBasedOnAction = (action: AnyAction): void => {
	const { type, payload } = action;

	switch (type) {
		case actions.setSearchTerm.type: {
			if (payload && payload.trim().length > 0)
				track(events.EVENT_SEARCHED_PROJECT, { 'Text': payload });
			break;
		}
		case actions.addSoilToProject.type: {
			track(events.EVENT_SOIL_TYPE_CREATED,
				{ 'Project ID': payload.projectId, 'Project Name': payload.projectName, 'Category': payload.categoryName } );
			break;
		}
		case actions.createProject.type: {
			const soilInvestigationsCount = payload.successfulSoilInvestigation + payload.failedSoilInvestigation;
			track(events.EVENT_CREATE_PROJECT, {
				 'Project ID': payload.project.id,
				 'Project Name': payload.project.name,
				 'Number of files': soilInvestigationsCount,
				'Interpretation method': getDescriptionOf(payload.project.interpretationMethod),
			});
			track(events.EVENT_UPLOAD, {
				'Project ID': payload.project.id,
				'Successful Soil Investigations count': payload.successfulSoilInvestigation,
				'Failed Soil Investigations count': payload.failedSoilInvestigation,
				'Upload time': payload.uploadTime,
				'Method': payload.uploadMethod,
			});
			break;
		}
		case actions.deleteSoilInvestigationsSuccess.type: {
			track(events.EVENT_SOIL_INVESTIGATION_IS_DELETED, {
				'Project id': payload.projectId, 'Quantity deleted': payload.soilInvestigationIds.length });
			break;
		}
		case actions.deleteProjectSuccess.type: {
			track(events.EVENT_PROJECT_IS_DELETED, { 'Project id': payload });
			break;
		}
		case actions.updateProject.type: {
			if (payload.project.interpretationMethod !== payload.oldProject.interpretationMethod)
				track(events.EVENT_INTERPRETATION_CHANGED, {
					'Project id': payload.project.id,
					'Project Name': payload.project.name,
					'Previous method': getDescriptionOf(payload.oldProject.interpretationMethod),
					'New method': getDescriptionOf(payload.project.interpretationMethod),
				});
			break;
		}
		case actions.importSoilInvestigationToProject.type: {
			const soilInvestigationsCount = payload.successfulSoilInvestigation + payload.failedSoilInvestigation;
			track(events.EVENT_IMPORT_SOIL_INVESTIGATION_TO_PROJECT,
				{ 'Project ID': payload.project.id, 'Project Name': payload.project.name, 'Number of files': soilInvestigationsCount });
			track(events.EVENT_UPLOAD, {
				'Project ID': payload.project.id,
				'Successful Soil investigation count': payload.successfulSoilInvestigation,
				'Failed Soil investigation count': payload.failedSoilInvestigation,
				'Upload time': payload.uploadTime,
				'Method': payload.uploadMethod,
			});
			break;
		}
		case actions.updateSoilInterpretationSuccess.type: {
			if (payload.project.interpretationMethod !== InterpretationMethod.MANUAL) {
				track(events.EVENT_INTERPRETATION_OVERRIDEN, { 'Project ID': payload.project.id,
					'Current method': getDescriptionOf(payload.project.interpretationMethod), 'soil investigation id': payload.soilInvestigationId });
			}
			break;
		}
		case actions.updateExportStatus.type: {
			const status: ExportStatus = payload.status;
			if (status === ExportStatus.EMPTY) {
				track(events.EVENT_CONVERT, { 'Project name': payload.projectName, 'Number of files': payload.soilInvestigationCount,
					'Interpretation method': getDescriptionOf(payload.interpretationMethod) });
			}
			break;
		}
		case actions.setSelectedProject.type: {
			const id = typeof payload === 'number' ? payload : payload ? payload.id : undefined;
			if (!id)
				return;

			if (typeof payload === 'object')
				track(events.EVENT_PROJECT_WAS_VIEWED, { 'Project ID': id, 'Source': (payload.origin ? payload.origin : 'Direct') });
			else
				track(events.EVENT_PROJECT_WAS_VIEWED, { 'Project ID': id, 'Source': 'Direct' });

			break;
		}
		case actions.viewSoilInvestigations.type: {
			track(events.EVENT_FILE_WAS_VIEWED, {
				'Project ID': payload.projectId,
				'Number of files in the view': payload.soilInvestigationsIds.length,
				'List of soilInvestigations ids': payload.soilInvestigationsList,
			});
			break;
		}
		default: {}
	}
};
