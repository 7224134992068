import { createAction } from '@reduxjs/toolkit';
import { GrpcError } from '@/models/classes';
import { Project } from '../types';
import { SoilInvestigationId, SoilInvestigationList } from '@/proto/build/soilInvestigation_pb';

/* Action Types */
enum ActionTypes {
	SET_SELECTED_SOIL_INVESTIGATIONS = '@@mondriaan/SOIL_INVESTIGATIONS/SET_SELECTED',
	SET_SELECTED_SOIL_INVESTIGATION_IDS = '@@mondriaan/SOIL_INVESTIGATION/SET_SELECTED_IDS',
	DELETE_SOIL_INVESTIGATIONS_SUCCESS = '@@mondriaan/SOIL_INVESTIGATIONS/DELETE/SUCCESS',
	DELETE_SOIL_INVESTIGATIONS_FAILED = '@@mondriaan/SOIL_INVESTIGATIONS/DELETE/FAILED',
	UPDATE_SOIL_INVESTIGATIONS_INTERPRETATION_SUCCESS = '@@mondriaan/SOIL_INVESTIGATIONS/INTERPRETATION/SUCCESS',
	UPDATE_SOIL_INVESTIGATIONS_INTERPRETATION_FAILED = '@@mondriaan/SOIL_INVESTIGATIONS/INTERPRETATION/FAILED',
	VIEW_SOIL_INVESTIGATIONS = '@@mondriaan/SOIL_INVESTIGATIONS/VIEW',
}

export const setSelectedSoilInvestigations = createAction<Record<string, SoilInvestigationList.AsObject>>(
	ActionTypes.SET_SELECTED_SOIL_INVESTIGATIONS,
);
export const setSelectedSoilInvestigationIds = createAction<SoilInvestigationId.AsObject[]>(ActionTypes.SET_SELECTED_SOIL_INVESTIGATION_IDS);

export const deleteSoilInvestigationsSuccess = createAction<{
	projectId: number;
	soilInvestigationIds: SoilInvestigationId.AsObject[]
}>(ActionTypes.DELETE_SOIL_INVESTIGATIONS_SUCCESS);
export const deleteSoilInvestigationsFailed = createAction<GrpcError>(ActionTypes.DELETE_SOIL_INVESTIGATIONS_FAILED);

export const updateSoilInterpretationSuccess = createAction<{
	 project: Project;
	 soilInvestigationId: number
}>(ActionTypes.UPDATE_SOIL_INVESTIGATIONS_INTERPRETATION_SUCCESS);
export const updateSoilInterpretationFailed = createAction<GrpcError>(ActionTypes.UPDATE_SOIL_INVESTIGATIONS_INTERPRETATION_FAILED);

export const viewSoilInvestigations = createAction<{
	projectId: number;
	soilInvestigationsIds: SoilInvestigationId.AsObject[]
}>(ActionTypes.VIEW_SOIL_INVESTIGATIONS);
