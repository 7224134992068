import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { SoilState } from '@/store/types';
import { Soil, SoilCategory, SoilLibrary, UpdateCustomSoilData } from '@/proto/build/soil_pb';

const initialState: SoilState = {
	soils: {},
	libraries: {},
	categories: {},
};

export default createReducer(initialState, {
	[actions.setSoil.type]: (draft, { payload }: { payload: Soil.AsObject }) => {
		draft.soils[payload.id] = { ...payload };
	},
	[actions.updateSoil.type]: (draft, { payload }: { payload: UpdateCustomSoilData.AsObject }) => {
		draft.soils[payload.id] = {
			...draft.soils[payload.id],
			...payload,
		};
	},
	[actions.deleteSoil.type]: (draft, { payload }: { payload: Soil.AsObject }) => {
		delete draft.soils[payload.id];
	},
	[actions.setSoils.type]: (draft, { payload }: { payload: Soil.AsObject[] }) => {
		payload.forEach((soil) => {
			draft.soils[soil.id] = { ...soil };
		});
	},
	[actions.setSoilLibraries.type]: (draft, { payload }: { payload: SoilLibrary.AsObject[] }) => {
		payload.forEach((library: SoilLibrary.AsObject) => {
			draft.libraries[library.id] = {
				...library,
				soilsList: library.soilsList.map((soil) => {
					draft.soils[soil.id] = soil;
					return soil.id;
				}),
			};
		});

		return draft;
	},
	[actions.setSoilCategories.type]: (draft, { payload }: { payload: SoilCategory.AsObject[] }) => {
		payload.forEach((category: SoilCategory.AsObject) => {
			draft.categories[category.id] = category;
		});

		return draft;
	},
});
