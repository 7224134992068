import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { ExportStatus } from '@/models/types';

const initialState = ExportStatus.EMPTY;

export default createReducer(initialState, {
	[actions.updateExportStatus.type]: (draft, { payload }): ExportStatus => payload.status,
	[actions.resetState.type]: (): ExportStatus => initialState,
});
