import React, { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getSelectedSoilInvestigationSoilLayer } from '@/store/selectors';
import { resetHoveredSoilLayer, resetSelectedSoilLayer } from '@/store/actions/soillayer';
import classes from './index.module.css';
import GraphSkeleton from '@/components/Interpretation/SoilInvestigationGraphs/GraphSkeleton';
import { BoreholeAsObject } from '@/store/types';
import BoreholeSoilLayer from './BoreholeSoilLayer';
import { BoreholeSoilLayer as BoreholeSoilLayerGrpc } from '@/proto/build/borehole_pb';

interface Props {
	borehole: BoreholeAsObject;
	distanceBasedOnZoom: number;
	height: number;
	width: number;
	xAxisHeight: number;
	visibility: boolean;
}

type WithPathMouseEvent = MouseEvent & {
	path: Element[];
};

const BoreholeSoilLayerBar: FC<Props> = ({
	borehole,
	distanceBasedOnZoom,
	height,
	width,
	xAxisHeight,
	visibility,
}): JSX.Element => {
	const dispatch = useDispatch();
	const selectedSoilInvestigationSoilLayer = useSelector(getSelectedSoilInvestigationSoilLayer) as BoreholeSoilLayerGrpc.AsObject;
	const barStart = Math.ceil(borehole.z);

	const handleClickAway = (event: React.MouseEvent<Document, MouseEvent>): void => {
		removePurpleBar(event);

		if (!selectedSoilInvestigationSoilLayer) return;
		// The path property of MouseEvent objects is non-standard
		// To not use any we extends the MouseEvent with the path properties
		const excludedElementFound = findExcludedElements(['topbar', 'sidebar', 'popper', 'layer'], event);

		if (excludedElementFound) return;

		dispatch(resetSelectedSoilLayer());
		dispatch(resetHoveredSoilLayer());
	};

	const findExcludedElements = (
		words: string[],
		event: React.MouseEvent<Document, MouseEvent>,
	): Element | undefined => {
		const { path } = (event as unknown) as WithPathMouseEvent;
		return path.find((p: Element): boolean => {
			const className = p.classList?.value?.replace('-', '').toLocaleLowerCase();

			return !!words.find((word): boolean => className?.includes(word));
		});
	};

	const removePurpleBar = (event: React.MouseEvent<Document, MouseEvent>): void => {
		const excludedElementFound = findExcludedElements(['topbar', 'sidebar', 'popper', 'layer'], event);

		if (excludedElementFound || !selectedSoilInvestigationSoilLayer) return;
	};

	return (
		<div className={classes.container}>
			<div className={classes.headerContainer}>
				<span className={classes.header}>Soil layers</span>
			</div>
			{visibility ? (
				<ClickAwayListener disableReactTree onClickAway={handleClickAway}>
					<ul className={classes.bar} style={{ height, width, marginTop: xAxisHeight }}>
						<li className={classes.boreholeZ} style={{ top: (barStart - borehole.z) * distanceBasedOnZoom - 1 }} />
						{Object.values(borehole.layersList).map((layer, index): ReactNode => {
							return (
								<BoreholeSoilLayer
									key={`layer_${layer.id}_${layer.depthFromTop}_${index}`}
									boreholeID={borehole.id}
									layer={layer}
									barStart={barStart}
									distanceBasedOnZoom={distanceBasedOnZoom}
									isSelected={selectedSoilInvestigationSoilLayer?.id === layer.id}
								/>
							);
						})}
					</ul>
				</ClickAwayListener>
			) : (
				<GraphSkeleton width={width} height={height} />
			)}
		</div>
	);
};

export default React.memo(BoreholeSoilLayerBar);
