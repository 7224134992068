import React, { FC, useRef, useEffect, UIEvent, SetStateAction, Dispatch, useState } from 'react';
import { useSelector } from 'react-redux';
import Ruler from '@royalhaskoningdhv/react-ruler';
import { getDraggingLayerState, getSelectedSoilInvestigation, getSelectedSoilInvestigationSoilLayer } from '@/store/selectors';
import { MIN_LAYER_THICKNESS } from '@/models/constants';

interface Props {
	zoomLevel: number;
	startingPoint: number;
	setScrollCallback: Dispatch<SetStateAction<((event: UIEvent<HTMLDivElement>) => void | undefined) | undefined>>;
}

const rulerStyling = {
	width: '24px',
	height: '100%',
	backgroundColor: '#000000',
	lineColor: '#78797b',
	textColor: '#eeeeee',
};

export const distanceBetweenSteps = 35;

const DepthRuler: FC<Props> = ({ zoomLevel, startingPoint, setScrollCallback }) => {
	const selectedSoilInvestigationSoilLayer = useSelector(getSelectedSoilInvestigationSoilLayer);
	const soilInvestigation = useSelector(getSelectedSoilInvestigation);
	const draggingState = useSelector(getDraggingLayerState);
	const [highlightTop, setHighlightTop] = useState(0);
	const [highlightBottom, setHighlightBottom] = useState(0);
	const ref = useRef<Ruler>();
	const zoom = zoomLevel * distanceBetweenSteps;

	/* This is the margin between the top of the canvas and the start of the CPT graphs */
	const marginAboveGraph = 97 / zoom;

	useEffect((): void => {
		ref.current?.resize();

		window.addEventListener('resize', (): void => {
			ref.current?.resize();
		});
	}, []);

	const isCPT = soilInvestigation && 'recordsList' in soilInvestigation;

	useEffect((): void => {
		ref.current?.scroll(-marginAboveGraph - startingPoint);
	}, [marginAboveGraph, startingPoint]);

	useEffect((): void => {
		setScrollCallback(() => (event: UIEvent<HTMLDivElement>): void => {
			ref.current?.scroll(event.currentTarget.scrollTop / zoom - marginAboveGraph - startingPoint);
		});
	}, [zoom, setScrollCallback, startingPoint, marginAboveGraph]);

	useEffect(() => {
		if (!selectedSoilInvestigationSoilLayer) return;
		const top = 'depthTop' in selectedSoilInvestigationSoilLayer
			? selectedSoilInvestigationSoilLayer.depthTop
			: selectedSoilInvestigationSoilLayer.depthFromTop;
		const bottom = 'height' in selectedSoilInvestigationSoilLayer
			? selectedSoilInvestigationSoilLayer.height
			: selectedSoilInvestigationSoilLayer.depthBase;

		setHighlightTop(top);
		setHighlightBottom(top - bottom);
	}, [selectedSoilInvestigationSoilLayer]);

	useEffect(() => {
		if (!draggingState) return;

		const mouseMove = (event: MouseEvent): void => {
			if (!ref.current) return;

			let pos = -ref.current.state.scrollPos - (event.clientY - 63) / zoom;

			if (draggingState === 'top') {
				// TODO: check if this is correct
				const highest = isCPT ? soilInvestigation?.recordsList[0].depthBasedOnZValue : soilInvestigation?.layersList[0].depthFromTop || 0;

				if (pos - highlightBottom < MIN_LAYER_THICKNESS) {
					pos = highlightBottom + MIN_LAYER_THICKNESS;
				}
				else if (pos > highest) {
					pos = highest;
				}

				setHighlightTop(pos);
			} else if (draggingState === 'bottom') {
				// TODO: check if this is correct
				const deepest = isCPT
					? soilInvestigation?.recordsList[soilInvestigation.recordsList.length - 1].depthBasedOnZValue
					: soilInvestigation?.layersList[
						Object.keys(soilInvestigation.layersList)[Object.keys(soilInvestigation.layersList).length - 1]]?.depthFromTop || 0;

				if (highlightTop - pos < MIN_LAYER_THICKNESS) {
					pos = highlightTop - MIN_LAYER_THICKNESS;
				} else if (pos < deepest) {
					pos = deepest;
				}

				setHighlightBottom(pos);
			}
		};

		document.addEventListener('mousemove', mouseMove);
		return (): void => {
			document.removeEventListener('mousemove', mouseMove);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [draggingState, soilInvestigation, zoom]);

	return (
		<Ruler
			type="vertical"
			ref={(e): void => {
				if (e) ref.current = e;
			}}
			mainLineSize={12}
			shortLineSize={6}
			longLineSize={6}
			style={{
				width: rulerStyling.width,
				height: rulerStyling.height,
			}}
			unit={zoomLevel < 1 ? 2 : 1}
			zoom={zoom}
			zoomLevel={zoomLevel}
			direction="start"
			backgroundColor={rulerStyling.backgroundColor}
			lineColor={rulerStyling.lineColor}
			textAlign="left"
			textColor={rulerStyling.textColor}
			textOffset={[-7, 0]}
			textFormat={(scale: number): string => (-scale).toString()}
			fontSize={11}
			highlight={selectedSoilInvestigationSoilLayer ? [-highlightTop, -highlightBottom] : undefined}
		/>
	);
};

export default DepthRuler;
