import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import retrievingProjectsFailed from '@/store/reducers/retrievingProjectsFailed';
import selected from './selected';
import hovered from './hovered';
import projects from './projects';
import selectedProject from './selectedProject';
import projectErrors from './projectErrors';
import projectProgress from './projectProgress';
import selectedSoilInvestigations from './selectedSoilInvestigations';
import selectedSoilInvestigationIds from './selectedSoilInvestigationIds';
import soilInvestigationsConfirmDeletionDialogOpen from './dialog';
import exportStatus from './exportStatus';
import soil from './soil';
import map from './map';
import searchTerm from './searchTerm';
import charts from './charts';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const rootReducer = (history: History) =>
	combineReducers({
		projects,
		projectProgress,
		projectErrors,
		selectedProject,
		selectedSoilInvestigations,
		selectedSoilInvestigationIds,
		selected,
		soil,
		hovered,
		exportStatus,
		map,
		searchTerm,
		charts,
		retrievingProjectsFailed,
		soilInvestigationsConfirmDeletionDialogOpen,
		router: connectRouter(history),
	});

export default rootReducer;
