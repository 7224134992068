import React, { FC, forwardRef, Ref } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import classes from './index.module.css';

interface Props {
	ref?: Ref<HTMLDivElement>;
}

const SoilInvestigationSkeleton: FC<Props> = forwardRef<HTMLDivElement, Props>((_, ref) => {
	const height = 700;
	return (
		<div ref={ref} className={classes.root}>
			<div className={classes.header}>
				<Skeleton variant="text" width={164} height={40} className={classes.headerText} />
			</div>
			<div className={classes.graphs}>
				<Skeleton className={classes.coneResistance} variant="rect" width={367} height={height} />
				<Skeleton className={classes.frictionRatio} variant="rect" width={126} height={height}/>
				<Skeleton className={classes.soilBar} variant="rect" width={57} height={height} />
			</div>
		</div>
	);
});

export default SoilInvestigationSkeleton;
