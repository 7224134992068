/**
 * @fileoverview gRPC-Web generated client stub for com.royalhaskoningdhv.mondriaan.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.10
// source: soil.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.com = {};
proto.com.royalhaskoningdhv = {};
proto.com.royalhaskoningdhv.mondriaan = {};
proto.com.royalhaskoningdhv.mondriaan.proto = require('./soil_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList>}
 */
const methodDescriptor_SoilLibraryService_allGlobalLibraries = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/allGlobalLibraries',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServiceClient.prototype.allGlobalLibraries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/allGlobalLibraries',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_allGlobalLibraries,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServicePromiseClient.prototype.allGlobalLibraries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/allGlobalLibraries',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_allGlobalLibraries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil,
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.Soil>}
 */
const methodDescriptor_SoilLibraryService_create = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/create',
  grpc.web.MethodType.UNARY,
  proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil,
  proto.com.royalhaskoningdhv.mondriaan.proto.Soil,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.royalhaskoningdhv.mondriaan.proto.Soil.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.royalhaskoningdhv.mondriaan.proto.Soil)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.Soil>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/create',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_create,
      callback);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.royalhaskoningdhv.mondriaan.proto.Soil>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/create',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_SoilLibraryService_update = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/update',
  grpc.web.MethodType.UNARY,
  proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/update',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_update,
      callback);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/update',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int64Value,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_SoilLibraryService_delete = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/delete',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.Int64Value,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Int64Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int64Value} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/delete',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_delete,
      callback);
};


/**
 * @param {!proto.google.protobuf.Int64Value} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.SoilLibraryService/delete',
      request,
      metadata || {},
      methodDescriptor_SoilLibraryService_delete);
};


module.exports = proto.com.royalhaskoningdhv.mondriaan.proto;

