import { createSelector } from '@reduxjs/toolkit';
import { ExportStatus,
	SelectedSoilInvestigationLayer,
	 HoveredSoilInvestigationLayer,
	 ProjectProgress, DraggingState,
	  ESoilInvestigationType,
} from '@/models/types';
import { SoilLayer } from '@/proto/build/cpt_pb';
import { AppState, ImportError, Project, SoilInvestigationsListAsObject } from '../types';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import { BoreholeSoilLayer } from '@/proto/build/borehole_pb';

/* Soilinvestigation selectors */
const appState = (s: AppState): AppState => s;

export const getSelectedLayerID = createSelector(appState, (s: AppState): SelectedSoilInvestigationLayer => s.selected);
export const getHoveredLayerID = createSelector(appState, (s: AppState): HoveredSoilInvestigationLayer => s.hovered);

export const getExportStatusSelector = createSelector(appState, (s: AppState): ExportStatus => s.exportStatus);

export const getProjects = createSelector(appState, (s: AppState): Record<string, Project> => s.projects);
export const getSelectedProjectId = createSelector(appState, (s: AppState): number | null => s.selectedProject);
export const getSelectedProject = createSelector(getSelectedProjectId, getProjects, (id, projects): Project | null => {
	return id ? projects[id] : null;
});
export const getSelectedProjectName = createSelector(getSelectedProject, (project: Project | null): string | undefined => project?.name);

export const getProjectProgress = createSelector(appState, (s: AppState): ProjectProgress => s.projectProgress);
export const getProjectErrors = createSelector(appState, (s: AppState): ImportError => s.projectErrors);
export const getRetrievingProjectsFailed = createSelector(appState, (s: AppState): boolean => s.retrievingProjectsFailed);
export const getAllCPTsFailed = createSelector(appState, (s: AppState): boolean => s.projectErrors.allFilesFailed);

export const getSelectedSoilInvestigationsList = createSelector(
	appState, (s: AppState): SoilInvestigationsListAsObject => s.selectedSoilInvestigations);
export const getSelectedSoilInvestigationIds = createSelector(
	appState, (s: AppState): SoilInvestigationId.AsObject[] => s.selectedSoilInvestigationIds);
export const getSelectedSoilInvestigation = createSelector(
	getSelectedLayerID,
	getSelectedSoilInvestigationsList,
	(layerId, soilInvestigations) => {
		const { cptsList, boreholeList } = soilInvestigations || {};
		if ((!cptsList && !boreholeList) || layerId.soilInvestigationId === '') return;
		const soilInvestigationslist = layerId.soilInvestigationType === ESoilInvestigationType.CPT ? cptsList : boreholeList;
		const selectedSoilInvestigation = soilInvestigationslist[layerId?.soilInvestigationId];

		return selectedSoilInvestigation;
	},
);

export const getProjectIdsFromSelectedSoilInvestigationsList = createSelector(
	getSelectedSoilInvestigationsList,
	({ cptsList, boreholeList }): number[] => {
		const arr: number[] = [];
		[...Object.values(cptsList || {}), ...Object.values(boreholeList || {})].forEach(soilInvestigation => {
			if (!arr.includes(soilInvestigation.projectId)) {
				arr.push(soilInvestigation.projectId);
			}
		});

		return arr;
	},
);

export const getConfirmDeletionSoilInvestigationDialogOpen =
createSelector(appState, (s: AppState): boolean => s.soilInvestigationsConfirmDeletionDialogOpen);

export const getSearchTerm = createSelector(appState, (s: AppState): string => s.searchTerm);

export const getDraggingLayerState = createSelector(appState, (s: AppState): DraggingState => s.selected.isDragging);

export const isSoilInvestigationLayerSelected = createSelector(appState, (s: AppState): boolean => s.selected.soilInvestigationId !== '');
export const getSelectedSoilInvestigationSoilLayer = createSelector(
	getSelectedLayerID, getSelectedSoilInvestigationsList,
	(soilInvestigationLayer, soilInvestigations): SoilLayer.AsObject | BoreholeSoilLayer.AsObject | undefined => {
		const { soilInvestigationId, soilInvestigationType } = soilInvestigationLayer;
		const isCPT = soilInvestigationType === ESoilInvestigationType.CPT;
		if (!soilInvestigationId) return;
		const { cptsList, boreholeList } = soilInvestigations;
		if (!cptsList && !boreholeList) return;

		const listToSearchTrough = isCPT ? cptsList : boreholeList;
		const selectedSoilInvestigation = listToSearchTrough[soilInvestigationId];
		if (!selectedSoilInvestigation) return;

		const selectedSoilInvestigationsLayers = 'soilLayersList' in selectedSoilInvestigation
			? selectedSoilInvestigation.soilLayersList
			: selectedSoilInvestigation.layersList;

		const layer = Object.values(selectedSoilInvestigationsLayers).find((layer) => {
			return layer.id.toString() === soilInvestigationLayer.id;
		});

		return layer;
	});

export const getMainImportError = createSelector(getProjectErrors, (errorInformation): string => {
	return errorInformation.mainReason !== '' ? errorInformation.mainReason : 'corrupted values';
});

export const getWarningMessage = createSelector(getProjectErrors, getMainImportError, (errorInformation, mainImportError): string => {
	let warningMsg = '';

	const soilInvestigationsAffected = Object.keys(errorInformation.list);
	// If there's any type of error...
	if (soilInvestigationsAffected.length > 0) {
		const filenames = soilInvestigationsAffected
			.map((cpt) => cpt)
			.reduce((left, right, index) => (index !== soilInvestigationsAffected.length - 1 ? `${left}, ${right}` : `${left} and ${right}`));

		if (soilInvestigationsAffected.length === 1) {
			warningMsg = `File ${filenames} was not processed because of ${mainImportError}`;
		} else if (soilInvestigationsAffected.length <= 3) {
			warningMsg = `Files ${filenames} were not processed because of ${mainImportError}`;
		} else {
			warningMsg = `${soilInvestigationsAffected.length} files were not processed because of ${mainImportError}`;
		}
	}

	return warningMsg;
});

export const getChartsVisibility = createSelector(appState, (s: AppState): string[] => s.charts.visible);
