import React, { FC } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ProjectStatusType } from '@/models/types';
import classes from './index.module.css';

interface Props {
	progress: number;
	type: ProjectStatusType;
}

const twoStepLoader = (isUploading: boolean, progress: number): JSX.Element => {
	return (
		<div className={classes.loaders}>
			<LinearProgress
				variant={isUploading ? 'indeterminate' : 'determinate'}
				value={isUploading ? 0 : 100}
				classes={{
					root: classes.progressUploading,
					barColorPrimary: classes.progressBarUploading,
				}}
			/>
			<LinearProgress
				variant="determinate"
				value={progress}
				classes={{
					root: classes.progressProcessing,
					barColorPrimary: classes.progressBarProcessing,
				}}
			/>
		</div>
	);
};

const oneStepLoader = (): JSX.Element => {
	return (
		<LinearProgress
			variant={'indeterminate'}
			classes={{
				root: classes.progressUpdating,
				barColorPrimary: classes.progressBarUpdating,
			}}
		/>
	);
};

const ProgressBarDialog: FC<Props> = ({ progress, type }): JSX.Element => {
	const isUploading = progress <= 0;

	const getStatus = (type: ProjectStatusType, isUploading: boolean, progress: number): string => {
		switch (type) {
			case ProjectStatusType.CREATING:
				return `Step ${isUploading ? '1' : '2'}/2: ${isUploading ? 'Uploading...' : `Processing ${progress}%`} `;
			case ProjectStatusType.UPDATING:
				return 'Processing...';
			default:
				return '';
		}
	};

	return (
		<div className={classes.box}>
			<span className={classes.title}>{getStatus(type, isUploading, progress)}</span>
			{type === ProjectStatusType.CREATING ? twoStepLoader(isUploading, progress) : oneStepLoader()}
		</div>
	);
};

export default ProgressBarDialog;
