import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { ESoilInvestigationType, SelectedSoilInvestigationLayer } from '@/models/types';

const initialState: SelectedSoilInvestigationLayer = {
	soilInvestigationId: '',
	soilInvestigationType: ESoilInvestigationType.CPT,
	id: '',
	isDragging: undefined,
};

export default createReducer(initialState, {
	[actions.setSelectedLayer.type]: (draft, { payload }): SelectedSoilInvestigationLayer => ({ ...draft, ...payload }),
	[actions.setDraggingState.type]: (draft, { payload }): SelectedSoilInvestigationLayer => ({ ...draft, isDragging: payload }),
	[actions.resetSelectedSoilLayer.type]: (): SelectedSoilInvestigationLayer => initialState,
	[actions.resetState.type]: (): SelectedSoilInvestigationLayer => initialState,
});
