import React, { FC } from 'react';

interface Props {
	cx: number;
	cy: number;
	r: number;
}

const CustomDot: FC<Props> = ({ cx, cy, r }): JSX.Element => {
	return <circle key={`dot_${r}`} cx={cx} cy={cy} fill="#01cebd" r={r} />;
};

export default React.memo(CustomDot);
