import React, { FC } from 'react';

interface Props {
	children: React.ReactNode;
	index: number;
	value: number;
	isHidden?: boolean;
	className?: string;
}

const TabPanel: FC<Props> = ({ children, value, index, isHidden, className }: Props): JSX.Element => {
	return (
		<div
			role="tabpanel"
			hidden={isHidden || value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className={className}
		>
			{value === index && children}
		</div>
	);
};

export default TabPanel;
