import { RouterState } from 'connected-react-router';
import { Project as ProjectGrpc } from '@/proto/build/project_pb';
import { ExportStatus, ProjectProgress, SelectedSoilInvestigationLayer, HoveredSoilInvestigationLayer } from '@/models/types';
import {	Soil, SoilCategory, SoilLibrary as GrpcSoilLibrary } from '@/proto/build/soil_pb';
import { Borehole, BoreholeSoilLayer } from '@/proto/build/borehole_pb';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import { CPT, SoilLayer } from '@/proto/build/cpt_pb';

export type CPTAsObject = Omit<CPT.AsObject, 'soilLayersList'> & {
	soilLayersList: Record<string, SoilLayer.AsObject>;
};

export type BoreholeAsObject = Omit<Borehole.AsObject, 'layersList'> & {
	layersList: Record<string, BoreholeSoilLayer.AsObject>;
}

export type SoilInvestigationsListAsObject = {
	cptsList: Record<string, CPTAsObject>,
	boreholeList: Record<string, BoreholeAsObject>,
};

export type Project = Omit<ProjectGrpc.AsObject, 'customSoilLibrary'> & {
	customSoilLibrary?: SoilLibrary;
}

export type SoilLibrary = Omit<GrpcSoilLibrary.AsObject, 'soilsList'> & {
	soilsList: number[];
}

export type ImportError = {
	// Contains the reason for the error in case there was only 1 type of error.
	// In case of multiple types of errors, this field becomes blank and one has to check the `list` below for the errors.
	mainReason: string;
	allFilesFailed: boolean;
	// Dictionary with key being the CPT filename and the value being the list of error during import
	list: Record<string, string[]>;
	createProjectFailed: boolean;
};

export interface AppState {
	selected: SelectedSoilInvestigationLayer;
	hovered: HoveredSoilInvestigationLayer;
	exportStatus: ExportStatus;
	projects: Record<string, Project>;
	soil: SoilState;
	selectedProject: number | null;
	projectProgress: ProjectProgress;
	projectErrors: ImportError;
	selectedSoilInvestigations: SoilInvestigationsListAsObject;
	map: MapPositioning;
	selectedSoilInvestigationIds: SoilInvestigationId.AsObject[];
	router: RouterState;
	searchTerm: string;
	charts: {visible: string[]; visibilityChangedManually: boolean};
	retrievingProjectsFailed: boolean;
	soilInvestigationsConfirmDeletionDialogOpen: boolean;
}

export interface SoilState {
	soils: Record<number,  Soil.AsObject>;
	libraries: Record<string, SoilLibrary>;
	categories: Record<number, SoilCategory.AsObject>;
}

export const noImportErrors = (): ImportError => {
	return {
		mainReason: '',
		allFilesFailed: false,
		list: {},
		createProjectFailed: false,
	};
};

export const withSoilInvestigationsErrors = (reason: string, allFilesFailed: boolean, list: Record<string, string[]>): ImportError => {
	return {
		mainReason: reason,
		allFilesFailed,
		list,
		createProjectFailed: false,
	};
};

export const withCreateProjectFailure = (): ImportError => {
	return {
		mainReason: 'Error creating project',
		createProjectFailed: true,
		list: {},
		allFilesFailed: false,
	};
};

export type MapPositioning = {
	bounds: MapViewCoords | null;
};

export type ProjectInformation = {
	lastSeenProjectId?: number;
	lastSeenBoundary?: MapViewCoords;
};

export type MapViewCoords = {
	north: number;
	east: number;
	south: number;
	west: number;
};

export type ProjectWithMapView = {
	projectId: number | null;
	view: MapViewCoords;
};

export function createProjectWithMapView(projectId: number | null, view: MapViewCoords): ProjectWithMapView {
	return {
		projectId,
		view: view,
	};
}
