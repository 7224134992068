import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { Project as ProjectGrpc } from '@/proto/build/project_pb';
import { Soil } from '@/proto/build/soil_pb';
import { Project } from '../types';

const initialState: Record<string, Project> = {};

export default createReducer(initialState, {
	[actions.createProject.type]: (draft, { payload }): Record<string, Project> => {
		return {
			...draft,
			[`${payload.project.id}`]: { ...payload.project },
		};
	},
	[actions.updateProject.type]: (draft, { payload }): Record<string, Project> => {
		return {
			...draft,
			[`${payload.project.id}`]: {
				...draft[payload.project.id],
				...payload.project,
			},
		};
	},
	[actions.addMultipleProjects.type]: (draft, { payload }): Record<string, Project> => {
		payload.forEach((project: ProjectGrpc.AsObject) => {
			draft[project.id] = {
				...project,
				customSoilLibrary: project.customSoilLibrary ? {
					...project.customSoilLibrary,
					soilsList: project.customSoilLibrary?.soilsList.map((soil: Soil.AsObject) => soil.id) || [],
				} : undefined,
			};
		});

		return draft;
	},
	[actions.importSoilInvestigationToProject.type]: (draft, { payload }): Record<string, Project> => {
		const { project } =  payload;

		draft[project.id].cptsList = [...draft[project.id].cptsList, ...project.cptsList];
		draft[project.id].boreholesList = [...draft[project.id].boreholesList, ...project.boreholesList];
		return draft;
	},
	[actions.deleteSoilInvestigationsSuccess.type]: (
		draft,
		action: ReturnType<typeof actions.deleteSoilInvestigationsSuccess>,
	): Record<string, Project> => {
		for (const p of Object.values(draft)) {
			const { cptsList, boreholesList } = p;
			p.cptsList = cptsList.filter(
				(cpt) => !action.payload.soilInvestigationIds.some((soilInvestigationId) => soilInvestigationId.soilInvestigationId === cpt.id));
			p.boreholesList = boreholesList.filter(
				(bh) => !action.payload.soilInvestigationIds.some((soilInvestigationId) => soilInvestigationId.soilInvestigationId === bh.id));
		}
		return draft;
	},
	[actions.deleteProjectSuccess.type]: (draft, { payload }): void => {
		delete draft[payload];
	},
	[actions.addSoilToProject.type]: (draft, { payload: { projectId, soilId } }): void => {
		draft[projectId]?.customSoilLibrary?.soilsList.push(soilId);
	},
	[actions.deleteSoilFromProject.type]: (draft, { payload: { projectId, soilId } }): void => {
		draft[projectId]?.customSoilLibrary?.soilsList.filter((it) => it !== soilId);
	},
});
