import React, { FC } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { CPTRecord } from '@/proto/build/cpt_pb';
import { replaceHyphensWithMinus } from '@/helpers';
import classes from './index.module.css';

interface PayloadWithPayload extends Payload<string, number> {
	payload?: CPTRecord.AsObject;
}

interface ICustomTooltip {
	active?: boolean;
	payload?: Array<PayloadWithPayload>;
	shouldDisplay?: boolean;
}

const CustomTooltip: FC<ICustomTooltip> = ({ active = true, shouldDisplay, payload }: ICustomTooltip) => {
	if (
		!active ||
		!shouldDisplay ||
		!payload ||
		!payload[0]?.payload ||
		(!payload[0].payload.coneResistance && !payload[0].payload.frictionNumber && !payload[0].payload.porePressure)
	)
		return null;

	const qcValue = payload[0].payload.coneResistance ?
		payload[0].payload.coneResistance.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-';
	const rfValue = payload[0].payload.frictionNumber ?
		payload[0].payload.frictionNumber.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-';
	const depth = payload[0].payload.depthBasedOnZValue ?
		payload[0].payload.depthBasedOnZValue.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-';

	const porePressurePayload = payload[0].payload.porePressure;
	const porePressureValue = porePressurePayload?.hasOwnProperty('value') ? porePressurePayload.value : porePressurePayload;
	const porePressure = porePressureValue && porePressureValue >= -0.2 ?
		porePressureValue.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-';

	return (
		<div className={classes.tooltip}>
			<span className={classes.tooltipLabelBold}>z: {replaceHyphensWithMinus(depth)}</span>
			<span className={classes.tooltipLabel}>
				q<sub>c</sub>: {qcValue}
			</span>
			<span className={classes.tooltipLabel}>
				R<sub>f</sub>: {rfValue}
			</span>
			<span className={classes.tooltipLabelLast}>
				u<sub>2</sub>: {porePressure}
			</span>
		</div>
	);
};

export default React.memo(CustomTooltip);
