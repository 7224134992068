import Skeleton from '@material-ui/lab/Skeleton';
import React, { FC } from 'react';
import classes from './index.module.css';

interface Props {
	items: number;
}

const MapSideBarSkeleton: FC<Props> = ({ items }): JSX.Element=> {
	const itemsArray = Array.from(Array(items).keys());

	const Item = (): JSX.Element => {
		return (
			<div className={classes.item}>
				<Skeleton variant="rect" width={66} height={24} className={classes.header}/>
				<Skeleton variant="rect" width={320} height={80} className={classes.content}/>
			</div>
		);
	};

	return (<div className={classes.skeleton}>
		{itemsArray.map((item) => <Item key={`Item_${item}`} />)}
	</div>);
};

export default MapSideBarSkeleton;
