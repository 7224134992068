// source: borehole.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var location_pb = require('./location_pb.js');
goog.object.extend(proto, location_pb);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.Borehole', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionRank', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilPattern', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.Borehole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.Borehole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    boreholeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    position: (f = msg.getPosition()) && location_pb.LatLng.toObject(includeInstance, f),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    interpretationMethod: jspb.Message.getFieldWithDefault(msg, 6, ""),
    horizontalReferencePlane: jspb.Message.getFieldWithDefault(msg, 7, ""),
    descriptionLocation: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hasMultipleDescriptionLocations: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    descriptionQuality: jspb.Message.getFieldWithDefault(msg, 17, ""),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    contractor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    chamberOfCommerceNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    groundWaterLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    groundWaterLevelHighestAverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    groundWaterLevelLowestAverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    fileName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    layersList: jspb.Message.toObjectList(msg.getLayersList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.Borehole;
  return proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoreholeId(value);
      break;
    case 4:
      var value = new location_pb.LatLng;
      reader.readMessage(value,location_pb.LatLng.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterpretationMethod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHorizontalReferencePlane(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionLocation(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMultipleDescriptionLocations(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionQuality(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractor(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setChamberOfCommerceNumber(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundWaterLevel(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundWaterLevelHighestAverage(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundWaterLevelLowestAverage(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 16:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.deserializeBinaryFromReader);
      msg.addLayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBoreholeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      location_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInterpretationMethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHorizontalReferencePlane();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescriptionLocation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHasMultipleDescriptionLocations();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDescriptionQuality();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getContractor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getChamberOfCommerceNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getGroundWaterLevel();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getGroundWaterLevelHighestAverage();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getGroundWaterLevelLowestAverage();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string borehole_id = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getBoreholeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setBoreholeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LatLng position = 4;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getPosition = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, location_pb.LatLng, 4));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.hasDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string interpretation_method = 6;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getInterpretationMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setInterpretationMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string horizontal_reference_plane = 7;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getHorizontalReferencePlane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setHorizontalReferencePlane = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description_location = 8;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getDescriptionLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setDescriptionLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool has_multiple_description_locations = 18;
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getHasMultipleDescriptionLocations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setHasMultipleDescriptionLocations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string description_quality = 17;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getDescriptionQuality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setDescriptionQuality = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional float z = 9;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string contractor = 10;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getContractor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setContractor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string chamber_of_commerce_number = 11;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getChamberOfCommerceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setChamberOfCommerceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float ground_water_level = 12;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getGroundWaterLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setGroundWaterLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float ground_water_level_highest_average = 13;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getGroundWaterLevelHighestAverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setGroundWaterLevelHighestAverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float ground_water_level_lowest_average = 14;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getGroundWaterLevelLowestAverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setGroundWaterLevelLowestAverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string file_name = 15;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated BoreholeSoilLayer layers = 16;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.getLayersList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer, 16));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.setLayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.addLayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.prototype.clearLayersList = function() {
  return this.setLayersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    depthFromTop: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    description: (f = msg.getDescription()) && proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fractionsList: jspb.Message.toObjectList(msg.getFractionsList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.toObject, includeInstance),
    id: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepthFromTop(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    case 3:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.deserializeBinaryFromReader);
      msg.addFractions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepthFromTop();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFractionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional float depth_from_top = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.getDepthFromTop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.setDepthFromTop = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float height = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional BoreholeDescription description = 3;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.getDescription = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription, 3));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated BoreholeSoilFraction fractions = 5;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.getFractionsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction, 5));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.setFractionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.addFractions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.clearFractionsList = function() {
  return this.setFractionsList([]);
};


/**
 * optional int64 id = 6;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilLayer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codesList: jspb.Message.toObjectList(msg.getCodesList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    case 2:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.deserializeBinaryFromReader);
      msg.addCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string template = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated BoreholeDescriptionCode codes = 2;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.getCodesList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode, 2));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.setCodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.addCodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescription.prototype.clearCodesList = function() {
  return this.setCodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    index: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 index = 3;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeDescriptionCode.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.toObject = function(includeInstance, msg) {
  var f, obj = {
    soil: (f = msg.getSoil()) && proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.toObject(includeInstance, f),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gradation: (f = msg.getGradation()) && proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.toObject(includeInstance, f),
    nameWithGradation: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.deserializeBinaryFromReader);
      msg.setSoil(value);
      break;
    case 2:
      var value = /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionRank} */ (reader.readEnum());
      msg.setRank(value);
      break;
    case 3:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.deserializeBinaryFromReader);
      msg.setGradation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameWithGradation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSoil();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGradation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.serializeBinaryToWriter
    );
  }
  f = message.getNameWithGradation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional BoreholeSoil soil = 1;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.getSoil = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil, 1));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.setSoil = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.clearSoil = function() {
  return this.setSoil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.hasSoil = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BoreholeFractionRank rank = 2;
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionRank}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.getRank = function() {
  return /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionRank} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionRank} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.setRank = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BoreholeFractionGradation gradation = 3;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.getGradation = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation, 3));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.setGradation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.clearGradation = function() {
  return this.setGradation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.hasGradation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name_with_gradation = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.getNameWithGradation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilFraction.prototype.setNameWithGradation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    boreholeSoilPattern: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilPattern} */ (reader.readEnum());
      msg.setBoreholeSoilPattern(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBoreholeSoilPattern();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BoreholeSoilPattern borehole_soil_pattern = 3;
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilPattern}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.getBoreholeSoilPattern = function() {
  return /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilPattern} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilPattern} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoil.prototype.setBoreholeSoilPattern = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.toObject = function(includeInstance, msg) {
  var f, obj = {
    formattedName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormattedName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string formatted_name = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.prototype.getFormattedName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.prototype.setFormattedName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float width = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionGradation.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    boreholesList: jspb.Message.toObjectList(msg.getBoreholesList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.Borehole;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.deserializeBinaryFromReader);
      msg.addBoreholes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoreholesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.Borehole.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Borehole boreholes = 1;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.prototype.getBoreholesList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.Borehole, 1));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.prototype.setBoreholesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Borehole}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.prototype.addBoreholes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.Borehole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeList.prototype.clearBoreholesList = function() {
  return this.setBoreholesList([]);
};


/**
 * @enum {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeSoilPattern = {
  GRAVEL: 0,
  SAND: 1,
  VERTICAL_LINE: 2,
  HORIZONTAL_LINE: 3,
  FORWARD_DIAGONAL_LINE: 4,
  BACKWARD_DIAGONAL_LINE: 5,
  BLANK: 6,
  DASHED_LINE: 7
};

/**
 * @enum {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.BoreholeFractionRank = {
  PRIMARY: 0,
  SECONDARY: 1,
  TERTIARY: 3,
  QUATERNARY: 4
};

goog.object.extend(exports, proto.com.royalhaskoningdhv.mondriaan.proto);
