import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import * as actions from '@/store/actions';
import { DEFAULT_PROPOSED_LAYER_THICKNESS, MIN_LAYER_THICKNESS, PROPOSED_LAYER_SOIL_ID } from '@/models/constants';
import { sortLayerByTop } from '@/helpers';
import { SoilInvestigationsListAsObject } from '../types';

const initialState: SoilInvestigationsListAsObject = {
	cptsList: {},
	boreholeList: {},
};

// TODO: Review this file to see if we can reduce its size
export default createReducer(initialState, {
	[actions.setSelectedSoilInvestigations.type]: (draft, { payload }): SoilInvestigationsListAsObject => payload,
	[actions.createSoilLayer.type]: (draft, { payload }): void => {
		const { soilInvestigationId, layer } = payload;
		/* Convert proposed layer to actual layer with material */
		draft.cptsList[soilInvestigationId].soilLayersList[layer.id] = layer;

		// The latest (deepest) record of the list
		const deepestRecord = draft.cptsList[soilInvestigationId].recordsList[
			Object.keys(draft.cptsList[soilInvestigationId].recordsList).length - 1];
		const top = layer.depthTop - layer.depthBase; // The top of the new created proposed layer
		let depth = Math.abs(deepestRecord.depthBasedOnZValue - top); // Depth of the proposed layer

		if (depth > DEFAULT_PROPOSED_LAYER_THICKNESS) depth = DEFAULT_PROPOSED_LAYER_THICKNESS;

		if (depth > MIN_LAYER_THICKNESS) {
			const id = uuidv4();
			/* Create new proposed layer */
			draft.cptsList[soilInvestigationId].soilLayersList[id] = {
				soilId: PROPOSED_LAYER_SOIL_ID,
				depthTop: top,
				depthBase: depth,
				id,
			};
		}
	},
	[actions.updateSoilLayer.type]: (draft, { payload }): void => {
		const { soilInvestigationId, layer } = payload;
		const { soilLayersList, recordsList, z } = { ...draft.cptsList[soilInvestigationId] };

		const deepestRecord = recordsList[Object.keys(recordsList).length - 1].depthBasedOnZValue;
		const layersList = Object.values(soilLayersList).sort((a, b) => (a.depthTop > b.depthTop ? -1 : 0));
		const oldLayer = soilLayersList[layer.id];
		const editedIndex = layersList.indexOf(oldLayer);

		let editedStart = layer.depthTop;
		let editedEnd = editedStart - layer.depthBase;

		/* Make sure that the start is not higher then the CPT's Z */
		if (editedStart > z) {
			editedStart = z;
		}

		/* Make sure that the end is not lower then the last record */
		if (editedEnd < deepestRecord) {
			editedEnd = deepestRecord;
		}

		/* Make sure that minimum layer thickness is respected */
		const editedDepth = Math.abs(editedEnd - editedStart);
		if (editedDepth < MIN_LAYER_THICKNESS) {
			if (editedEnd === oldLayer.depthTop - oldLayer.depthBase) {
				editedStart = editedEnd + MIN_LAYER_THICKNESS;
			} else {
				editedEnd = editedStart - MIN_LAYER_THICKNESS;
			}
		}

		/* Check all collisions above edited layer */
		for (let i = editedIndex - 1; i >= 0; i--) {
			const currLayer = layersList[i];
			const layerStart = currLayer.depthTop;

			/* Edited layer consumes current layer */
			if (editedStart >= layerStart) {
				delete draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id];
				continue;
			}

			/* Expand the edited layer to the end of the current layer since it's shorter than the min width */
			const newDepth = layerStart - editedStart;
			if (newDepth < MIN_LAYER_THICKNESS) {
				editedStart = layerStart;
				delete draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id];
			} else {
				draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id].depthBase = newDepth;
			}
			break;
		}

		/* Check all collisions below edited layer */
		for (let i = editedIndex + 1; i < layersList.length; i++) {
			const currLayer = layersList[i];
			const layerStart = currLayer.depthTop;
			const layerEnd = currLayer.depthTop - currLayer.depthBase;

			/* Edited layer consumes current layer */
			if (layerEnd + MIN_LAYER_THICKNESS >= editedEnd && currLayer.soilId !== PROPOSED_LAYER_SOIL_ID) {
				delete draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id];

				if (layerEnd <= editedEnd) {
					editedEnd = layerEnd;
				}
				continue;
			}

			let depth = Math.abs(layerEnd - editedEnd);

			/* Move the proposed layer up instead of expanding it if it's depth has not yet been changed */
			if (currLayer.soilId === PROPOSED_LAYER_SOIL_ID && currLayer.depthBase === DEFAULT_PROPOSED_LAYER_THICKNESS && layerStart <= editedEnd) {
				depth = DEFAULT_PROPOSED_LAYER_THICKNESS;
			} else if (currLayer.soilId === PROPOSED_LAYER_SOIL_ID && (depth < 1 || editedEnd < layerEnd)) {
				/* The proposed layer should always be at least 1m if possible */
				depth = Math.abs(deepestRecord - editedEnd);
				if (depth > DEFAULT_PROPOSED_LAYER_THICKNESS) depth = DEFAULT_PROPOSED_LAYER_THICKNESS;
				else if (depth < MIN_LAYER_THICKNESS) {
					delete draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id];
					editedEnd = deepestRecord;
					break;
				}
			}

			/* Edited layer collides with current layer */
			draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id] = {
				...draft.cptsList[soilInvestigationId].soilLayersList[currLayer.id],
				depthTop: editedEnd,
				depthBase: depth,
			};
			break;
		}

		draft.cptsList[soilInvestigationId].soilLayersList[layer.id] = { ...layer, depthTop: editedStart, depthBase: editedStart - editedEnd };

		/* Add back proposed layer if enough space was freed and there is not already a proposed layer */
		if (editedIndex === layersList.length - 1 && layer.geologyCode !== '' && layer.soilId !== PROPOSED_LAYER_SOIL_ID) {
			const depth = Math.abs(deepestRecord - editedEnd);

			if (depth > MIN_LAYER_THICKNESS) {
				const id = uuidv4();
				draft.cptsList[soilInvestigationId].soilLayersList[id] = {
					soilId: PROPOSED_LAYER_SOIL_ID,
					depthTop: editedEnd,
					depthBase: depth > DEFAULT_PROPOSED_LAYER_THICKNESS ? DEFAULT_PROPOSED_LAYER_THICKNESS : depth,
					id,
				};
			}
		}
	},
	[actions.deleteSoilLayer.type]: (draft, { payload }): void => {
		const { soilInvestigationId, id } = payload;

		const toBeDeletedLayer = { ...draft.cptsList[soilInvestigationId].soilLayersList[id] };
		const currentIndex = Object.keys(
			draft.cptsList[soilInvestigationId].soilLayersList).findIndex((key) => key === toBeDeletedLayer.id.toString());
		const proposedLayerIndex = Object.keys(draft.cptsList[soilInvestigationId].soilLayersList).length - 1;

		delete draft.cptsList[soilInvestigationId].soilLayersList[toBeDeletedLayer.id];

		if (currentIndex === 0) {
			const layerAfter = Object.values(draft.cptsList[soilInvestigationId].soilLayersList)[currentIndex];
			const afterLayerIsProposed = proposedLayerIndex - 1 === currentIndex;

			if (afterLayerIsProposed) {
				draft.cptsList[soilInvestigationId].soilLayersList[layerAfter.id] = {
					...layerAfter,
					depthTop: toBeDeletedLayer.depthTop,
				};
			} else {
				draft.cptsList[soilInvestigationId].soilLayersList[layerAfter.id] = {
					...layerAfter,
					depthTop: toBeDeletedLayer.depthTop,
					depthBase: toBeDeletedLayer.depthBase + layerAfter.depthBase,
				};
			}
		} else {
			const layerBefore = Object.values(draft.cptsList[soilInvestigationId].soilLayersList)[currentIndex - 1];
			draft.cptsList[soilInvestigationId].soilLayersList[layerBefore.id] = {
				...layerBefore,
				depthBase: layerBefore.depthBase + toBeDeletedLayer.depthBase,
			};
		}
	},
	[actions.splitSoilLayer.type]: (draft, { payload }): void => {
		const { soilInvestigationId, id } = payload;

		const toBeSplittedLayer = { ...draft.cptsList[soilInvestigationId].soilLayersList[id] };
		const topLayerDepth = toBeSplittedLayer.depthBase / 2;

		draft.cptsList[soilInvestigationId].soilLayersList[toBeSplittedLayer.id] = {
			...toBeSplittedLayer,
			depthBase: topLayerDepth,
		};

		const uuid = uuidv4();
		const newLayerTop = toBeSplittedLayer.depthTop - draft.cptsList[soilInvestigationId].soilLayersList[toBeSplittedLayer.id].depthBase;
		draft.cptsList[soilInvestigationId].soilLayersList[uuid] = {
			...toBeSplittedLayer,
			depthTop: newLayerTop,
			depthBase: topLayerDepth,
			id: uuid,
		};

		const soilArrayListToRecord =  Object.fromEntries(
			Object.values(draft.cptsList[soilInvestigationId].soilLayersList).map((val) => [val.id, val]),
		);

		draft.cptsList[soilInvestigationId].soilLayersList = sortLayerByTop(soilArrayListToRecord);
	},

});
