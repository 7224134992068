import { Metadata, StatusCode } from 'grpc-web';
import { v4 as uuidv4 } from 'uuid';
import { Dispatch } from '@reduxjs/toolkit';
import { BoolValue, Int64Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import {
	UploadFileChunk,
	Interpretation,
	SoilLayer,
} from '@/proto/build/cpt_pb';
import {
	CreateProjectData,
	CreateProjectResult,
	ImportSoilInvestigationToProject,
	ImportSoilInvestigationToProjectResult,
	InterpretationsToExport,
	OpenGroundZippedCSV,
	UpdateProjectData,
} from '@/proto/build/project_pb';
import { GrpcError } from '@/models/classes';
import { updateProjectError, createProject, updateProject, setSelectedProject } from '@/store/actions';
import {
	noImportErrors,
	Project,
	SoilInvestigationsListAsObject,
	withCreateProjectFailure,
	withSoilInvestigationsErrors,
} from '@/store/types';
import { importSoilInvestigationToProject, importSoilInvestigationToProjectFailed, updateProgressBar } from '@/store/actions/project';
import { ESoilInvestigationType, ProjectStatusType, UploadMethod } from '@/models/types';
import { DEFAULT_PROPOSED_LAYER_THICKNESS, PROPOSED_LAYER_SOIL_ID } from '@/models/constants';
import mondriaanApi from './facade/mondriaanApi';
import {
	SimpleSoilInvestigationList,
	SoilInvestigationId,
	SoilInvestigationIdList,
	SoilInvestigationList,
} from '@/proto/build/soilInvestigation_pb';

const unavailableServer = 'Mondriaan server is not available.';

function treatGrpcError(operationDescription: string, err: GrpcError): GrpcError {
	console.error(JSON.stringify(err));

	if (err.code === StatusCode.INVALID_ARGUMENT) {
		return err;
	}

	if (err.code === StatusCode.UNAVAILABLE) {
		const metaData: Metadata = { 'grpc-description': 'Try again later, and if the problem persists, please contact the IT department.' };
		return { code: err.code, message: unavailableServer, metadata: metaData };
	}

	const metaData: Metadata = {
		'grpc-description': `If the issue persists, please contact the IT department. Original error: ${err.message}, code ${err.code}.`,
	};
	return { code: err.code, message: operationDescription, metadata: metaData };
}

// Create a project and upload all the selected Soil Investigations
export function createProjectCmd(tempProject: CreateProjectData.AsObject, dispatch: Dispatch, uploadMethod?: UploadMethod): void {
	const project = new CreateProjectData();
	project.setName(tempProject.name);
	project.setDescription(tempProject.description);
	project.setAbwReference(tempProject.abwReference);
	project.setInterpretationMethod(tempProject.interpretationMethod);
	project.setMinimumLayerThickness(tempProject.minimumLayerThickness);
	project.setWaterLevel(tempProject.waterLevel);

	const newChunkList = getSoilInvestigationAsGefChunks(tempProject.soilInvestigationsList);

	project.setSoilInvestigationsList(newChunkList);

	dispatch(
		updateProgressBar({
			show: true,
			progress: 0,
			type: ProjectStatusType.CREATING,
		}),
	);

	const time = new Date().getTime();
	const soilInvestigationCount = project.getSoilInvestigationsList().length;
	mondriaanApi.createProject(project)
		.on('data', (data): void => {
			const resultType = data.getProjectOrProgressCase();
			const ProjectOrProgressCase = CreateProjectResult.ProjectOrProgressCase;

			switch (resultType) {
				case ProjectOrProgressCase.PROJECT_WITH_ERRORS:
					if (data) {
						const project = data.getProjectWithErrors()?.getProject()?.toObject();
						const errors = data.getProjectWithErrors()?.getErrorsMap()?.toArray();
						const mainImportErrorReason = data.getProjectWithErrors()?.getMainImportErrorReason() || '';

						if (!project) return;

						const { cptsList, boreholesList } = project;
						const allFileFailed = !cptsList.length && !boreholesList.length && errors?.length;

						const payload = {
							show: false,
							progress: 0,
							type: ProjectStatusType.UNKNOWN,
						};
						dispatch(updateProgressBar(payload));
						const failedSoilInvestigation = (errors ? errors.length : 0);
						const successfulSoilInvestigation = soilInvestigationCount - failedSoilInvestigation;
						const uploadTime = `${((new Date().getTime() - time) / 1000).toFixed(2)} seconds`;
						dispatch(createProject({ project, successfulSoilInvestigation, failedSoilInvestigation, uploadTime, uploadMethod }));
						dispatch(setSelectedProject({ id: project.id, zoomToProject: true }));

						if (errors?.length) {
							const dictWithErrors = Object.assign({}, ...errors.map((item) => ({ [item[0]]: item[1] })));
							dispatch(updateProjectError(
								withSoilInvestigationsErrors(
									mainImportErrorReason,
									!!allFileFailed,
									dictWithErrors)),
							);
						} else {
							// Clean up old errors
							dispatch(updateProjectError(noImportErrors()));
						}
					}
					break;
				case ProjectOrProgressCase.PROGRESS:
					dispatch(
						updateProgressBar({
							show: true,
							progress: Math.floor(data.getProgress()),
							type: ProjectStatusType.CREATING,
						}),
					);
					break;
				case ProjectOrProgressCase.CREATE_PROJECT_FAILED:
					dispatch(
						updateProgressBar({
							show: false,
							progress: 0,
							type: ProjectStatusType.UNKNOWN,
						}),
					);
					dispatch(updateProjectError(withCreateProjectFailure()));
					break;
				default:
					throw new Error('The returned CreateProjectResult is not recognized');
			}
		})
		.on('error', (error) => {
			console.error(`An error has occurred and the stream has been closed. ${error.message}`);
		});
}

// Edit a project
export function updateProjectCmd(tempProject: UpdateProjectData.AsObject, oldProject: Project, dispatch: Dispatch): void {
	const project = new UpdateProjectData();
	project.setId(tempProject.id);
	project.setName(tempProject.name);
	project.setDescription(tempProject.description);
	project.setAbwReference(tempProject.abwReference);
	project.setInterpretationMethod(tempProject.interpretationMethod);
	project.setMinimumLayerThickness(tempProject.minimumLayerThickness);
	project.setWaterLevel(tempProject.waterLevel);

	dispatch(
		updateProgressBar({
			show: true,
			progress: 0,
			type: ProjectStatusType.UPDATING,
		}),
	);

	mondriaanApi.updateProject(project, (err: GrpcError, _response: Empty): void => {
		if (err) {
			treatGrpcError('The project could not be updated', err);
		} else {
			dispatch(
				updateProgressBar({
					show: false,
					progress: 0,
					type: ProjectStatusType.UNKNOWN,
				}),
			);
			dispatch(updateProject({ project: tempProject, oldProject }));
		}
	});
}

export function getSimpleSoilInvestigationsCmd(
	soilInvestigationsIdsList: SoilInvestigationIdList.AsObject,
): Promise<SimpleSoilInvestigationList.AsObject> {
	const soilInvestigationIdList = new SoilInvestigationIdList();
	soilInvestigationIdList.setCptsList(soilInvestigationsIdsList.cptsList);
	soilInvestigationIdList.setBoreholesList(soilInvestigationsIdsList.boreholesList);

	return new Promise((resolve, reject) => {
		performance.mark('getSimpleSoilInvestigationsCmd:start');

		mondriaanApi.getSimpleSoilInvestigationsByIds(soilInvestigationIdList, (err: GrpcError, response: SimpleSoilInvestigationList) => {
			performance.mark('getSimpleSoilInvestigationsCmd:end');
			if (err) {
				reject(treatGrpcError('The simple soil investigations could not be retrieved.', err));
			} else {
				const cptsList = response.toObject().cptsList || [];
				const boreholesList = response.toObject().boreholesList || [];
				resolve({
					cptsList,
					boreholesList,
				});
			}
			performance.measure('getSimpleSoilInvestigationsCmd',
				'getSimpleSoilInvestigationsCmd:start', 'getSimpleSoilInvestigationsCmd:end');
		});
	});
}

export function getSelectedSoilInvestigationsWithDetailsCmd(
	soilInvestigationsIdsList: SoilInvestigationIdList.AsObject,
): Promise<SoilInvestigationsListAsObject> {
	const soilInvestigationIdList = new SoilInvestigationIdList();
	soilInvestigationIdList.setCptsList(soilInvestigationsIdsList.cptsList);
	soilInvestigationIdList.setBoreholesList(soilInvestigationsIdsList.boreholesList);

	return new Promise((resolve, reject) => {
		performance.mark('getSelectedSoilInvestigationsCmd:start');

		mondriaanApi.getDetailedSoilInvestigationInfo(soilInvestigationIdList, (err: GrpcError, response: SoilInvestigationList) => {
			performance.mark('getSelectedSoilInvestigationsCmd:end');
			if (err) {
				reject(treatGrpcError('The selected soil investigations data could not be retrieved.', err));
			} else {
				const cptsList = response.toObject().cpts?.cptsList || [];
				const boreholesList = response.toObject().boreholes?.boreholesList || [];

				const cptListRecord = Object.fromEntries(
					cptsList.map((cpt) => {
						const sortedRecordList = cpt.recordsList.sort((a, b) => a.penetrationLength - b.penetrationLength);
						const latestRecordItem = sortedRecordList[sortedRecordList.length - 1];
						const latestSoilLayer = cpt.soilLayersList[cpt.soilLayersList.length - 1];
						const proposedLayerDepthTop = latestSoilLayer?.depthTop - latestSoilLayer?.depthBase;
						const difference = Math.abs(latestRecordItem.depthBasedOnZValue - proposedLayerDepthTop);

						const proposedLayer = {
							soilId: PROPOSED_LAYER_SOIL_ID,
							id: uuidv4(),
							depthTop: proposedLayerDepthTop,
							depthBase: difference >= DEFAULT_PROPOSED_LAYER_THICKNESS ? DEFAULT_PROPOSED_LAYER_THICKNESS : difference,
						};

						const soilLayersList = Object.fromEntries(cpt.soilLayersList.map(layer => [
							layer.id, { ...layer },
						]));

						const cptWithSortedRecordList = {
							...cpt,
							recordsList: sortedRecordList,
							soilLayersList: {
								...soilLayersList,
								[`${proposedLayer.id}`]: { ...proposedLayer },
							},
						};

						return [cpt.id, cptWithSortedRecordList];
					}),
				);
				const bhList = Object.fromEntries(
					boreholesList.map((bh) => {
						const layersList = Object.fromEntries(bh.layersList.map(layer => [
							layer.id, { ...layer },
						]));

						const bhWithMapedLayers = {
							...bh,
							layersList,
						};
						return [bh.id, bhWithMapedLayers];
					}));

				resolve({
					cptsList: cptListRecord,
					boreholeList: bhList,
				});
			}
			performance.measure('getSelectedSoilInvestigationsCmd', 'getSelectedSoilInvestigationsCmd:start', 'getSelectedSoilInvestigationsCmd:end');
		});
	});
}

export function exportInterpretationsCmd(projectId: number, soilInvestigationIds: SoilInvestigationId.AsObject[]): Promise<OpenGroundZippedCSV> {
	const cptIds = Object.values(soilInvestigationIds).filter((si) => si.type === ESoilInvestigationType.CPT ).map((si) => si.soilInvestigationId);
	const boreholesIds = Object.values(soilInvestigationIds).filter((si) =>
		si.type === ESoilInvestigationType.BOREHOLE ).map((si) => si.soilInvestigationId);

	const interpretationsToExport = new InterpretationsToExport();
	interpretationsToExport.setProjectid(projectId);
	interpretationsToExport.setCptidsList(cptIds);
	interpretationsToExport.setBoreholeidsList(boreholesIds);

	return new Promise((resolve, reject) => {
		performance.mark('exportInterpretationsCmd:start');

		mondriaanApi.exportToOpenGroundCSV(interpretationsToExport, (err: GrpcError, response: OpenGroundZippedCSV) => {
			performance.mark('exportInterpretationsCmd:end');
			if (err) {
				reject(treatGrpcError('The interpretations could not be exported.', err));
			} else {
				resolve(response);
			}
			performance.measure('exportInterpretationsCmd', 'exportInterpretationsCmd:start', 'exportInterpretationsCmd:end');
		});
	});
}

export function updateInterpretationCmd(data: Interpretation.AsObject): Promise<void> {
	const soilLayerList = data.soilLayersList.map((layer) => {
		const soilLayer = new SoilLayer();
		soilLayer.setId(layer.id);
		soilLayer.setDepthTop(layer.depthTop);
		soilLayer.setDepthBase(layer.depthBase);
		soilLayer.setSoilId(layer.soilId);

		return soilLayer;
	}).filter((layer) => layer.getSoilId() !== PROPOSED_LAYER_SOIL_ID);

	const interpretation = new Interpretation();
	interpretation.setCptId(data.cptId);
	interpretation.setSoilLayersList(soilLayerList);

	return new Promise((resolve, reject) => {
		mondriaanApi.updateInterpretation(interpretation, (err: GrpcError, _response: Empty) => {
			if (err) {
				reject(treatGrpcError('The CPT interpretation could not be updated', err));
			} else {
				resolve();
			}
		});
	});
}

export function checkUniqueProjectName(name: StringValue): Promise<BoolValue> {
	return new Promise((resolve, reject) => {
		mondriaanApi.checkUniqueProjectName(name, (err: GrpcError, response: BoolValue) => {
			if (err) {
				reject(treatGrpcError('The check for a unique name could not be performed..', err));
			} else {
				resolve(response);
			}
		});
	});
}

function getSoilInvestigationAsGefChunks(soilInvestigations: UploadFileChunk.AsObject[]): UploadFileChunk[] {
	return soilInvestigations.map((chunk) => {
		const newChunk = new UploadFileChunk();
		newChunk.setFileName(chunk.fileName);
		newChunk.setPayload(chunk.payload);
		return newChunk;
	});
}

// Create a project and upload all the selected Soil investigations
export function uploadSoilInvestigationsToProjectCmd(
	projectId: number,
	soilInvestigations: UploadFileChunk.AsObject[],
	dispatch: Dispatch,
	uploadMethod?: UploadMethod,
): void {
	dispatch(
		updateProgressBar({
			show: true,
			progress: 0,
			type: ProjectStatusType.CREATING,
		}),
	);

	const importSoilInvestigationsData = new ImportSoilInvestigationToProject();
	importSoilInvestigationsData.setProjectId(projectId.toString());
	importSoilInvestigationsData.setSoilinvestigationsList(getSoilInvestigationAsGefChunks(soilInvestigations));

	const time = new Date().getTime();
	const soilInvestigationsCount = importSoilInvestigationsData.getSoilinvestigationsList().length;

	mondriaanApi.importSoilInvestigationsToProject(importSoilInvestigationsData)
		.on('data', (data): void => {
			const resultType = data.getProjectOrProgressCase();
			const ProjectOrProgressCase = ImportSoilInvestigationToProjectResult.ProjectOrProgressCase;

			switch (resultType) {
				case ProjectOrProgressCase.PROJECT_WITH_ERRORS:
					if (data) {
						const project = data.getProjectWithErrors()?.getProject()?.toObject();
						const errors = data.getProjectWithErrors()?.getErrorsMap()?.toArray();
						const mainImportErrorReason = data.getProjectWithErrors()?.getMainImportErrorReason() || '';

						if (project) {
							const failedSoilInvestigation = (errors ? errors.length : 0);
							const successfulSoilInvestigation = soilInvestigationsCount - failedSoilInvestigation;
							const uploadTime = `${((new Date().getTime() - time) / 1000).toFixed(2)} seconds`;
							dispatch(importSoilInvestigationToProject({
								project,
								successfulSoilInvestigation,
								failedSoilInvestigation,
								uploadTime,
								uploadMethod,
							}));

							dispatch(setSelectedProject({ id: project.id, zoomToProject: true }));
						}

						if (errors?.length) {
							const dictWithErrors = Object.assign({}, ...errors.map((item) => ({ [item[0]]: item[1] })));
							const soilInvestigationsLenght = project?.cptsList?.length === 0 && project?.boreholesList?.length === 0;
							dispatch(updateProjectError(
								withSoilInvestigationsErrors(
									mainImportErrorReason,
									soilInvestigationsLenght,
									dictWithErrors)),
							);
						} else {
							// Clean up old errors
							dispatch(updateProjectError(noImportErrors()));
						}
					}
					break;
				case ProjectOrProgressCase.PROGRESS:
					dispatch(
						updateProgressBar({
							show: true,
							progress: Math.floor(data.getProgress()),
							type: ProjectStatusType.CREATING,
						}),
					);
					break;
				default:
					throw new Error('The returned ImportSoilInvestigationsToProjectResult is not recognized');
			}
		})
		.on('error', (error) => {
			console.error(`An error has occurred importing Soils investigation to project: ${error.message}`);
			dispatch(importSoilInvestigationToProjectFailed(error.message));
		})
		.on('end', () => {
			const payload = {
				show: false,
				progress: 0,
				type: ProjectStatusType.UNKNOWN,
			};
			dispatch(updateProgressBar(payload));
		});
}

export function deleteSoilInvestigationsCmd(soilInvestigationsIdsList: SoilInvestigationIdList.AsObject): Promise<boolean> {
	const soilInvestigationsIdList = new SoilInvestigationIdList();
	soilInvestigationsIdList.setCptsList(soilInvestigationsIdsList.cptsList);
	soilInvestigationsIdList.setBoreholesList(soilInvestigationsIdsList.boreholesList);

	return new Promise((resolve, reject) => {
		performance.mark('deleteSoilInvestigationsCmd:start');

		mondriaanApi.deleteSoilInvestigation(soilInvestigationsIdList, (err: GrpcError, response: Empty) => {
			performance.mark('deleteSoilInvestigationsCmd:end');
			if (err) {
				reject(treatGrpcError('The Soil investigations could not be deleted.', err));
			} else {
				resolve(true);
			}
			performance.measure('deleteSoilInvestigationsCmd', 'deleteSoilInvestigationsCmd:start', 'deleteSoilInvestigationsCmd:end');
		});
	});
}

export function deleteProjectCmd(projectId: Int64Value): Promise<boolean> {
	performance.mark('deleteProjectCmd:start');

	return new Promise((resolve, reject) => {
		mondriaanApi.deleteProject(projectId, (err: GrpcError, response: Empty) => {
			performance.mark('deleteProjectCmd:end');
			if (err) {
				reject(treatGrpcError('The Project could not be deleted', err));
			} else {
				resolve(true);
			}
			performance.measure('deleteProjectCmd', 'deleteProjectCmd:start', 'deleteProjectCmd:end');
		});
	});
}
