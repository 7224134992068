import { createAction } from '@reduxjs/toolkit';
import { GrpcError } from '@/models/classes';
import { CPTStatus, ExportStatus } from '@/models/types';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { CPTAsObject } from '../types';

/* Action Types */
enum ActionTypes {
	UPDATE_CPT = '@@mondriaan/CPT/UPDATE',
	UPDATE_STATUS = '@@mondriaan/STATUS/UPDATE',
	UPDATE_ERROR = '@@mondriaan/ERROR/UPDATE',
	RESET_STATE = '@@mondriaan/STATE/RESET',
	UPDATE_EXPORT_STATUS = '@@mondriaan/EXPORT_STATUS/UPDATE',
	UPDATE_RETRIEVING_PROJECTS_FAILED = '@@mondriaan/RETRIEVING_PROJECTS_FAILED/UPDATE',
}

export const updateCpt = createAction<CPTAsObject>(ActionTypes.UPDATE_CPT);

export const updateStatus = createAction<CPTStatus>(ActionTypes.UPDATE_STATUS);

export const updateExportStatus = createAction<{ projectId: number; projectName: string; interpretationMethod: InterpretationMethod;
	soilInvestigationsCount: number; status: ExportStatus; }>(ActionTypes.UPDATE_EXPORT_STATUS);

export const updateError = createAction<GrpcError>(ActionTypes.UPDATE_ERROR);

export const resetState = createAction(ActionTypes.RESET_STATE);

export const updateRetrievingProjectsFailed = createAction<boolean>(ActionTypes.UPDATE_RETRIEVING_PROJECTS_FAILED);
