import { NewCustomSoil, Soil, UpdateCustomSoilData } from '@/proto/build/soil_pb';

export type TempSoilType = Omit<NewCustomSoil.AsObject, 'projectId'> | Omit<UpdateCustomSoilData.AsObject, 'id'>;

export type DerivedTypeSoilValidation<T> = {
	[K in keyof T]: string | null;
};

export const defaultSoilValidationErrors: DerivedTypeSoilValidation<NewCustomSoil.AsObject> = {
	projectId: null,
	categoryId: null,
	name: null,
	color: null,
	cohesion: null,
	frictionAngle: null,
};

export const soilTypeIsValid = (
	soil: TempSoilType,
	allSoils: Record<number, Soil.AsObject>,
	editedId?: number,
): DerivedTypeSoilValidation<NewCustomSoil.AsObject> => {
	const validation = { ...defaultSoilValidationErrors };

	if (soil.name === '') {
		validation.name = 'This field is mandatory';
	}

	const soilNameExist = Object.values(allSoils).find((s) => {
		return (s.name.toLocaleLowerCase() === soil?.name?.toLocaleLowerCase()) && (editedId !== s.id);
	});

	if (soilNameExist) {
		validation.name = 'This name is already in use';
	}

	return validation;
};
