import React, { FC, Fragment, MouseEvent } from 'react';
import { Divider, List, ListItem, ListSubheader, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { dateTimestampToString, getTimeStamp } from '@/helpers';
import { useFilesUtils } from '@/hooks/useFilesUtils';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import FilesSelectionMenu from '../FilesSelectionMenu';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { setConfirmSoilInvestigationsDeletionDialogOpen } from '@/store/actions';

const useStyles = makeStyles((theme) => ({
	header: {
		color: '#aaaaaa',
		paddingLeft: '8px',
		fontFamily: 'Barlow, sans-serif',
		fontSize: '12px',
		fontWeight: 500,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '31px',
		letterSpacing: '1px',
		margin: '8px 0 0 0',
	},
	cptList: {
		paddingLeft: 'unset',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 0,
		marginBlocEnd: '0 !important',
	},
	cptItem: {
		display: 'flex',
		justifyContent: 'space-between',
		listStyleType: 'none',
		alignItems: 'center',
		borderRadius: '1px',
		padding: '3px 8px',
		cursor: 'pointer',
		backgroundColor: '#644bff',
	},
	cptNumber: {
		fontFamily: 'Barlow',
		fontSize: '16px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '24px',
		letterSpacing: 'normal',
	},
	cptDate: {
		fontFamily: 'Barlow',
		fontSize: '14px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '20px',
		letterSpacing: 'normal',
		color: '#aaaaaa',
	},
	divider: {
		margin: theme.spacing(1, 0, 0),
	},
}));

interface Props {
	onClick: (event: MouseEvent, soilInvestigationIdAsObject: SoilInvestigationId.AsObject) => void,
	onDoubleClick: (event: MouseEvent, soilInvestigationIdAsObject: SoilInvestigationId.AsObject) =>  void,
	onContextMenu: (event: MouseEvent, status: boolean) => void,
}

export const UnactiveSelectedFiles: FC<Props> = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const {
		selectedSoilInvestigationIds,
		simpleUnactiveSoilInvestigationList,
		handleOnClick,
		handleRightClick,
		handleDoubleCLick,
		mouseClickPos,
		handleMouseClickPosition,
	 } = useFilesUtils();

	 if (!Object.values(simpleUnactiveSoilInvestigationList).length) return null;

	 const confirmDeleteSoilInvestigationsDialog = (): void => {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(true));
	};

	return (
		<Fragment>
			<Divider className={classes.divider}/>
			<List
				className={classes.cptList}
				aria-labelledby="nested-list-subheader"
				subheader={
					<ListSubheader className={classes.header}>
          	UNACTIVE PROJECT(S) FILES
					</ListSubheader>
				}
			>
				{Object.values(simpleUnactiveSoilInvestigationList).map((soilInvestigation) => {
					const date = dateTimestampToString(getTimeStamp(soilInvestigation.date));
					const soilInvestigationIdAsObject =  {
						soilInvestigationId: soilInvestigation.id,
						type: soilInvestigation.type,
					};
					return (
						<ListItem
							key={soilInvestigation.testId}
							className={classes.cptItem}
							onClick={(event): void => handleOnClick(event, soilInvestigationIdAsObject)}
							onDoubleClick={(event): void => handleDoubleCLick(event, soilInvestigationIdAsObject)}
							onContextMenu={(e): void => handleRightClick(e, true)}
						>
							<span className={classes.cptNumber}>{soilInvestigation.testId}</span>
 							<span className={classes.cptDate}>{date}</span>
						</ListItem>
					);
				})}
			</List>
			<FilesSelectionMenu
				handleClose={(): void => handleMouseClickPosition()}
				projectId={-99}
				projectName={''}
				interpretationMethod={InterpretationMethod.MANUAL}
				soilInvestigationIds={selectedSoilInvestigationIds}
				mousePosition={mouseClickPos}
				open={mouseClickPos?.mouseY !== null}
				handleDeleteSoilInvestigation={confirmDeleteSoilInvestigationsDialog}
			/>
		</Fragment>
	);
};

export default UnactiveSelectedFiles;
