import React, { FC, Fragment, MouseEvent, UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import DepthRuler from '@/components/Layout/DepthRuler';
import { getChartsVisibility, getSelectedSoilInvestigationsList, getSelectedSoilInvestigationIds } from '@/store/selectors';
import { getHighestZIndex, soilInvestigationIdWithTypeToList } from '@/helpers';
import { setSelectedSoilInvestigations, showWaterPressure } from '@/store/actions';
import { getSelectedSoilInvestigationsWithDetailsCmd } from '@/net';
import SoilInvestigationSkeleton from '@/components/Interpretation/SoilInvestigations/SoilInvestigationSkeleton';
import CPT from '@/components/Interpretation/SoilInvestigations/CPT';
import Borehole from '@/components/Interpretation/SoilInvestigations/Borehole';
import classes from './index.module.css';

interface Props {
	zoomLevel: number;
	onZoomInOut: (value: number) => void;
	openSideBar: boolean;
}

const soilInvestigationsInViewport = (soilInvestigationsRefs: React.MutableRefObject<RefVisbility[]>): Record<string, boolean> => {
	let soilInvestigationVisibility: Record<string, boolean> = {};
	soilInvestigationsRefs.current?.forEach((soilInvestigation) => {
		const rect = soilInvestigation?.element?.getBoundingClientRect();

		if (rect) {
			const isPartiallyOrBetweenScreen = (left: number, right: number): boolean => {
				const viewportWidth = document.documentElement.clientWidth;
				const visible = (left >= 0 && left <= viewportWidth) ||
				(right >= 0 && right <= viewportWidth) ||
				(left <= 0 && right >= viewportWidth);
				return visible;
			};

			const isInsideViewPort = isPartiallyOrBetweenScreen(rect.left, rect.right);

			soilInvestigationVisibility = {
				...soilInvestigationVisibility,
				[`${soilInvestigation.id}`]: isInsideViewPort,
			};
		} else {
			soilInvestigationVisibility = {
				...soilInvestigationVisibility,
				[`${soilInvestigation.id}`]: true,
			};
		}
	});

	return soilInvestigationVisibility;
};

type RefVisbility = {
	id: string,
	element: HTMLDivElement
}

const Content: FC<Props> = ({ zoomLevel, onZoomInOut, openSideBar }): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();

	const rootRef = useRef<HTMLDivElement>(null);
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const soilInvesigationsRefs = useRef<RefVisbility[]>([]);
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const { cptsList, boreholeList } = useSelector(getSelectedSoilInvestigationsList);
	const chartsVisibility = useSelector(getChartsVisibility);
	const [soilInvesigationsVisible, setSoilInvesigationsVisible] = useState<Record<string, boolean>>({});

	useEffect(() => {
		if (selectedSoilInvestigationIds.length) {
			getSelectedSoilInvestigationsWithDetailsCmd(soilInvestigationIdWithTypeToList(selectedSoilInvestigationIds))
				.then((soilInvestigationList) => {
					dispatch(setSelectedSoilInvestigations(soilInvestigationList));
				})
				.catch((error) => {
					throw new Error(`Could not get the list of selected cpts ${error.message}`);
				});
		} else {
			history.push('/');
		}
	}, [dispatch, selectedSoilInvestigationIds, history]);

	const [isDragging, setIsDragging] = useState(false);
	const [rulerScroll, setRulerScroll] = useState<(event: UIEvent<HTMLDivElement>) => void | undefined>();
	const [pos, setPos] = useState({ top: 0, left: 0, x: 0, y: 0 });

	const addToSoilInvestigationsRefs = (el: RefVisbility | null): void => {
		if (el && !soilInvesigationsRefs.current.includes(el)) {
			soilInvesigationsRefs.current.push(el);
		}
	};

	const setSoilInvestigationsVisibility = (refs: React.MutableRefObject<RefVisbility[]>): void => {
		const soilInvestigationsVisibility = soilInvestigationsInViewport(refs);
		setSoilInvesigationsVisible(soilInvestigationsVisibility);
	};

	useEffect(() => {
		setSoilInvestigationsVisibility(soilInvesigationsRefs);
	}, [cptsList, boreholeList, zoomLevel, chartsVisibility]);

	useEffect(() => {
		if (!Object.keys({ ...cptsList }).length) return;
		let cptsContainPorePressure = false;

		Object.values(cptsList).forEach((cpt) => {
			cpt.recordsList.forEach((record) => {
				if (record.porePressure) {
					cptsContainPorePressure = true;
					return;
				}
			});
		});
		dispatch(showWaterPressure(cptsContainPorePressure));
	}, [cptsList, dispatch]);

	useEffect(() => {
		const onMouseWheel = (event: WheelEvent): void => {
			// if ctrl key is pressed down
			if (event.ctrlKey) {
				event.preventDefault(); //prevent default browser zoom
				const newValue = +(zoomLevel - (event.deltaY / 100) * 0.25).toFixed(2);
				onZoomInOut(newValue);
			}
		};

		/* Attach the 'mouse up' event to the document.
		This makes sure that the 'dragging' state is set to false
		even if you let go outside of the scrollable container */
		document.addEventListener('mouseup', onMouseUp);
		document.addEventListener('keyup', onKeyPress);

		/*The 3rd param (passive) is needed to solve the follwing error in Chrome and some other browsers (Eg edge)
		[Intervention] Unable to preventDefault inside passive event listener due
		to target being treated as passive.See<https://www.chromestatus.com/features/6662647093133312> */
		document.addEventListener('wheel', onMouseWheel, { passive: false });

		return (): void => {
			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('wheel', onMouseWheel);
			document.removeEventListener('keyup', onKeyPress);
		};
	}, [onZoomInOut, zoomLevel]);

	const maxCptZIndex = useMemo(() => getHighestZIndex(cptsList), [cptsList]);
	const maxBoreholeZIndex = useMemo(() => getHighestZIndex(boreholeList), [boreholeList]);

	const onMouseDown = (event: MouseEvent<HTMLDivElement>): void => {
		if (!scrollContainerRef.current) return;

		setPos({
			/* The current scroll */
			left: scrollContainerRef.current.scrollLeft,
			top: scrollContainerRef.current.scrollTop,

			/* Get the current mouse position */
			x: event.clientX,
			y: event.clientY,
		});

		if (!isDragging) {
			setIsDragging(true);
		}
	};

	const onMouseMove = (event: MouseEvent<HTMLDivElement>): void => {
		if (!isDragging || !scrollContainerRef.current) return;

		/* How far the mouse has been moved */
		const dx = event.clientX - pos.x;
		const dy = event.clientY - pos.y;

		/* Scroll the element */
		scrollContainerRef.current.scrollTop = pos.top - dy;
		scrollContainerRef.current.scrollLeft = pos.left - dx;
	};

	const onMouseUp = (): void => {
		setIsDragging(false);
	};

	const onScroll = (event: UIEvent<HTMLDivElement>): void => {
		event.preventDefault();
		event.stopPropagation();
		event.persist();

		if (rulerScroll) {
			setSoilInvestigationsVisibility(soilInvesigationsRefs);
			rulerScroll(event);
		}
	};

	const onKeyPress = (event: KeyboardEvent): void => {
		const wrapper = wrapperRef.current;

		if (wrapper) {
			const scrollContainer = scrollContainerRef.current;

			if (!scrollContainer) return;

			const children = wrapper.children;

			const containerBBox = scrollContainer?.getBoundingClientRect();

			let closestChildToSideIndex = -1;
			let tempDistance = Number.MAX_SAFE_INTEGER;

			const getChildToCenter = (containerSide: number, childSide: number, index: number): void => {
				const distance = Math.abs(containerSide - childSide);

				if (distance < tempDistance) {
					closestChildToSideIndex = index;
					tempDistance = distance;
				}
			};

			switch (event.key) {
				case 'ArrowRight':
					Object.values(children).forEach((child, index) => {
						const childBBox = child.getBoundingClientRect();
						getChildToCenter(containerBBox.right, childBBox.left, index);
					});
					break;
				case 'ArrowLeft':
					Object.values(children).forEach((child, index) => {
						const childBBox = child.getBoundingClientRect();
						getChildToCenter(containerBBox.left, childBBox.right, index);
					});
					break;
				default:
					return;
			}

			if (closestChildToSideIndex !== -1) {
				const childBBox = children[closestChildToSideIndex].getBoundingClientRect();
				const gap = (containerBBox.width - childBBox.width) / 2;
				const move = childBBox.x - gap;
				scrollContainer.scrollBy(move, childBBox.top - 100);

			} else {
				throw new Error('Could not center the next/previous CPT');
			}
		}
	};

	return (
		<div ref={rootRef} className={classNames(classes.root, { [classes.closedSideBar]: !openSideBar })}>
			<DepthRuler zoomLevel={zoomLevel} startingPoint={Math.max(maxCptZIndex, maxBoreholeZIndex)} setScrollCallback={setRulerScroll} />
			<div
				ref={scrollContainerRef}
				className={classNames(classes.scrollContainer, { [classes.scrollContainerDrag]: isDragging })}
				onMouseDown={onMouseDown}
				onMouseMove={onMouseMove}
				onScroll={onScroll}
			>
				<div className={classes.wrapper} ref={wrapperRef}>
					{
						Object.values({ ...cptsList, ...boreholeList }).length <= 0
							? [1, 2, 3, 4, 5].map((sk) => <SoilInvestigationSkeleton key={`skeleton_${sk}`} />)
							: <Fragment>
								{
									Object.values(cptsList)
										.sort((a, b) => a.cptNumber.toLowerCase() > b.cptNumber.toLowerCase() ? 1 : -1)
										.map((cpt, index) => {
											return (
												<div key={`cpt_${index}`} style={{ zIndex: 999 - index }}>
													<CPT
														ref={(el: HTMLDivElement): void => {
															addToSoilInvestigationsRefs({
																id: cpt.cptNumber,
																element: el,
															});
														}}
														cpt={cpt}
														zoomLevel={zoomLevel}
														maxZIndex={Math.max(maxCptZIndex, maxBoreholeZIndex)}
														visibility={soilInvesigationsVisible[cpt.cptNumber]}
													/>
												</div>
											);
										})
								}
								{
									Object.values(boreholeList)
										.sort((a, b) => a.boreholeId.toLowerCase() > b.boreholeId.toLowerCase() ? 1 : -1)
										.map((bh, index) => {
											return (
												<div key={`bh_${index}`} style={{ zIndex: 999 - index }}>
													<Borehole
														ref={(el: HTMLDivElement): void => {
															addToSoilInvestigationsRefs({
																id: bh.boreholeId,
																element: el,
															});
														}}
														borehole={bh}
														zoomLevel={zoomLevel}
														maxZIndex={Math.max(maxCptZIndex, maxBoreholeZIndex)}
														visibility={soilInvesigationsVisible[bh.boreholeId]}
													/>
												</div>
											);
										})
								}
							</Fragment>
					}
				</div>
			</div>
		</div>
	);
};

export default Content;
