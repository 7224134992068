import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import classNames from 'classnames';
import { ClickAwayListener, MenuItem } from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { TextButtonNeutral, TextButtonTurquiose, TextIconFlatButton } from '@/components/custom/buttons';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { onKeyPress } from '@/helpers';
import { DerivedTypeSoilValidation, defaultSoilValidationErrors, soilTypeIsValid, TempSoilType } from '@/helpers/soil';
import { NewCustomSoil, Soil } from '@/proto/build/soil_pb';
import { getProjectSoils, getSoilCategories } from '@/store/selectors/soil';
import { getSelectedProjectId, getSelectedProjectName } from '@/store/selectors';
import { addCustomSoilCmd, updateCustomSoilCmd, deleteCustomSoilCmd } from '@/net/soil';
import { SoilDialogState } from '@/models/types';
import classes from './index.module.css';

interface Props {
	soilDialogIsOpen: boolean;
	soil: Soil.AsObject | null;
	handleSoilDialog: (status: SoilDialogState) => void;
}

const initialSoilType: TempSoilType = {
	categoryId: 1,
	name: '',
	cohesion: '',
	frictionAngle: '',
	color: '',
};

const SOIL_TYPE_CAT_ID_KEY = 'soilTypeCategoryId';

export const SoilTypeDialog: FC<Props> = ({ soilDialogIsOpen, soil, handleSoilDialog }): JSX.Element => {
	const dispatch = useDispatch();
	const projectId = useSelector(getSelectedProjectId);
	const projectName = useSelector(getSelectedProjectName) || '';
	const soilCategories = useSelector(getSoilCategories);
	const allProjectSoils = useSelector(getProjectSoils);
	const [soilValidatedErrors, setSoilValidatedErrors] = useState<DerivedTypeSoilValidation<NewCustomSoil.AsObject>>(defaultSoilValidationErrors);
	const [colorPickerIsOpen, setColorPickerIsOpen] = useState<boolean>(false);
	const [tempSoilType, setTempSoilType] = useState<TempSoilType>(initialSoilType);

	useEffect(() => {
		const inStoreSoilKey = localStorage.getItem(SOIL_TYPE_CAT_ID_KEY);
		const catIndex = inStoreSoilKey ? +inStoreSoilKey : initialSoilType.categoryId;

		if (!catIndex) return;

		let tempSoil = {
			...initialSoilType,
			categoryId: catIndex,
			color: soilCategories[catIndex]?.color,
		};

		if (soil) {
			tempSoil = { ...soil };
		}

		setTempSoilType(tempSoil);
	}, [soilCategories, soil]);

	const handleTextFieldOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, input: string): void => {
		const { value } = event.target;
		setTempSoilType(
			(old): TempSoilType => {
				return {
					...old,
					[`${input}`]: value,
				};
			},
		);

		if (input === 'name' && soilValidatedErrors.name) {
			setSoilValidatedErrors((old) => {
				return {
					...old,
					name: null,
				};
			});
		}

		if (input === 'categoryId') {
			const currentCatColor = Object.values(soilCategories).find((cat) => cat.id === +value)?.color || initialSoilType.color;
			if (currentCatColor) {
				handleChangeColor(currentCatColor);
			}
		}
	};

	const handleChangeColor = (color: string): void => {
		setTempSoilType((old) => {
			return {
				...old,
				color,
			};
		});
	};

	const handleApply = (): void => {
		if (!projectId) return;
		const customSoil = { ...tempSoilType };

		const validatedSoilErrors: DerivedTypeSoilValidation<NewCustomSoil.AsObject> = soilTypeIsValid(customSoil, allProjectSoils, soil?.id);
		setSoilValidatedErrors(validatedSoilErrors);

		const isValid = Object.values(validatedSoilErrors).every((soilError) => !soilError);

		if (isValid) {
			if (soil) {
				updateCustomSoilCmd({ ...customSoil, id: soil.id }, dispatch);
			} else {
				const categoryName = Object.values(soilCategories).find((cat) => cat.id === +customSoil.categoryId)?.name || '';
				addCustomSoilCmd({ ...customSoil, projectId }, projectName, categoryName, dispatch);
			}
			localStorage.setItem(SOIL_TYPE_CAT_ID_KEY, JSON.stringify(customSoil.categoryId));
			resetAndClose();
		}
	};

	const handleDeleteSoil = (): void => {
		if (soil) {
			deleteCustomSoilCmd(soil, dispatch);
			resetAndClose();
		}
	};

	const resetAndClose = (): void => {
		setColorPickerIsOpen(false);
		setTempSoilType(() => {
			const catIndex = initialSoilType.categoryId;

			return {
				...initialSoilType,
				color: soilCategories[catIndex].color,
			};
		});
		setSoilValidatedErrors(defaultSoilValidationErrors);
		handleSoilDialog(SoilDialogState.HIDDEN);
	};

	const DialogActions = (
		<Fragment>
			<TextButtonNeutral onClick={resetAndClose}>CANCEL</TextButtonNeutral>
			<TextButtonTurquiose classes={{ root: classes.applyButton }} disabled={!soilTypeIsValid} onClick={handleApply}>
				APPLY
			</TextButtonTurquiose>
		</Fragment>
	);

	return (
		<BaseDialog title="Project specific soil type" isOpen={soilDialogIsOpen} closeDialog={resetAndClose} actionButtons={DialogActions}>
			{
				soil &&
				<TextIconFlatButton
					variant="contained"
					className={classes.deleteSoilButton}
					onClick={handleDeleteSoil}
				>
					<DeleteSweepIcon fontSize="large"/>
					<span className={classes.buttonText}>
						Remove from
						<span className={classes.buttonTextLibrary}> library</span>
					</span>
				</TextIconFlatButton>
			}
			<div className={classes.fields}>
				<div className={classes.column}>
					<OutlinedTextField
						fullWidth
						value={tempSoilType.name}
						className={classes.textField}
						classes={{ root: !!soilValidatedErrors?.name ? classes.error : '' }}
						label="Name"
						onChange={(e): void => handleTextFieldOnChange(e, 'name')}
						onKeyPress={onKeyPress}
						inputProps={{
							maxLength: 100,
							autoComplete: 'off',
						}}
						type="text"
						error={!!soilValidatedErrors?.name}
						helperText={soilValidatedErrors?.name}
					/>
					<OutlinedTextField
						fullWidth
						value={tempSoilType.cohesion}
						className={classNames(classes.textField, classes.cohesionTextField)}
						classes={{ root: !!soilValidatedErrors?.cohesion ? classes.error : '' }}
						label="Cohesion, c’"
						onChange={(e): void => handleTextFieldOnChange(e, 'cohesion')}
						onKeyPress={onKeyPress}
						type="text"
						InputProps={{
							endAdornment: <span className={classes.endAdorment}>kPa</span>,
						}}
						error={!!soilValidatedErrors?.cohesion}
						helperText={soilValidatedErrors?.cohesion}
					/>
					<div className={classes.colorPickerWrapper}>
						<h4 className={classes.colorPickerHeader}>COLOUR PICKER</h4>
						<div className={classes.colorPickerBoxes}>
							<OutlinedTextField
								value={tempSoilType?.color?.replace('#', '').toLocaleUpperCase()}
								className={classes.colorPickerHexBox}
								classes={{ root: !!soilValidatedErrors?.color ? classes.error : '' }}
								disabled
								label="Hex"
								type="text"
								error={!!soilValidatedErrors?.color}
								helperText={soilValidatedErrors?.color}
							/>
							<span
								className={classes.colorPickerColorBox}
								style={{ backgroundColor: tempSoilType.color }}
								onClick={(): void => {
									if (!tempSoilType.categoryId) return;
									setColorPickerIsOpen(true);
								}}
							>
								{colorPickerIsOpen && (
									<ClickAwayListener
										onClickAway={(): void => {
											setColorPickerIsOpen(!colorPickerIsOpen);
										}}
									>
										<SketchPicker
											color={tempSoilType.color}
											className={classes.colorPicker}
											width="200px"
											presetColors={undefined}
											disableAlpha
											onChangeComplete={(color): void => handleChangeColor(color.hex)}
										/>
									</ClickAwayListener>
								)}
							</span>
						</div>
					</div>
				</div>
				<div className={classes.column}>
					<OutlinedTextField
						fullWidth
						select
						value={tempSoilType.categoryId}
						className={classes.textField}
						classes={{ root: !!soilValidatedErrors?.categoryId ? classes.error : '' }}
						label="Category"
						onChange={(e): void => handleTextFieldOnChange(e, 'categoryId')}
						SelectProps={{
							IconComponent: KeyboardArrowDownRounded,
							classes: { icon: classes.categoryArrowIcon },
						}}
						type="text"
						error={!!soilValidatedErrors?.categoryId}
						helperText={soilValidatedErrors?.categoryId}
					>
						{Object.values(soilCategories).map((cat) => {
							return (
								<MenuItem key={cat.id} value={cat.id}>
									{cat.name}
								</MenuItem>
							);
						})}
					</OutlinedTextField>
					<OutlinedTextField
						fullWidth
						value={tempSoilType.frictionAngle}
						className={classNames(classes.textField, classes.frictionAngleTextField)}
						classes={{ root: !!soilValidatedErrors?.frictionAngle ? classes.error : '' }}
						label="Friction angle, φ'"
						onChange={(e): void => handleTextFieldOnChange(e, 'frictionAngle')}
						onKeyPress={onKeyPress}
						type="text"
						InputProps={{
							endAdornment: <span className={classes.endAdorment}>°</span>,
						}}
						error={!!soilValidatedErrors?.frictionAngle}
						helperText={soilValidatedErrors?.frictionAngle}
					/>
				</div>
			</div>
		</BaseDialog>
	);
};

export default SoilTypeDialog;
