import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { ImportError } from '../types';

const initialState: ImportError = {
	mainReason: '',
	allFilesFailed: false,
	list: {},
	createProjectFailed: false,
};

export default createReducer(initialState, {
	[actions.updateProjectError.type]: (draft, { payload }): ImportError => {
		return {
			...draft,
			...payload,
		};
	},
	[actions.resetProjectErrors.type]: (draft, _): ImportError => {
		return initialState;
	},
});
