/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SagaIterator } from 'redux-saga';
import { call, spawn, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { retrieveSoilLibrariesAndCategories } from '@/sagas/soil';
import mondriaanApi from '@/net/facade/mondriaanApi';
import { trackSignIn } from '@/tracking/mixPanel';
import { setProjectActive, restoreProjectLocation, storeMapBounds, updateInterpretation, fetchAllProjects } from './project';
import { openCptOnInterpretationPage } from './cpt';

export function* runUserLoginPipeline(action: ReturnType<typeof actions.userLoggedIn>) {
	if (!action.payload) {
		console.warn('Unexpected login without data');
		return;
	}

	mondriaanApi.setAccessToken(action.payload.accessCode);
	trackSignIn(action.payload.user);

	yield call(restoreProjectLocation);
	yield spawn(fetchAllProjects);
	yield spawn(retrieveSoilLibrariesAndCategories);
}

export default function* launchSagas(): SagaIterator {
	yield takeLeading(actions.userLoggedIn, runUserLoginPipeline);

	yield takeLatest(actions.setSelectedProject, setProjectActive);
	yield takeLatest(actions.updateMapBounds, storeMapBounds);
	yield takeEvery([actions.updateSoilLayer, actions.createSoilLayer, actions.deleteSoilLayer, actions.splitSoilLayer], updateInterpretation);
	yield takeLeading(actions.viewSoilInvestigations, openCptOnInterpretationPage);
}
