import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedProject, setSelectedSoilInvestigationIds } from '@/store/actions';
import { Project } from '@/store/types';
import { useFilesUtils } from '@/hooks/useFilesUtils';
import classes from './index.module.css';

interface Props {
	project: Project;
}

export const ProjectItem: FC<Props> = ({ project }) => {
	const dispatch = useDispatch();
	const { selectedSoilInvestigationIds } = useFilesUtils();
	const { cptsList, boreholesList } = project;
	const soilInvestigationsCount = [...cptsList, ...boreholesList].length;

	const handleNameClicked = (id: number): void => {
		if (selectedSoilInvestigationIds.length) dispatch(setSelectedSoilInvestigationIds([]));
		dispatch(setSelectedProject({ id, zoomToProject: true, origin: 'Search' }));
	};

	return (
		<div className={classes.project} onClick={(): void => handleNameClicked(project.id)}>
			<div className={classes.title}>
				<span className={classes.link}>
					{project.name}
				</span>
				<span className={classes.soilInvestigationCount}>{soilInvestigationsCount}</span>
			</div>
			<span className={classes.abwReference}>{project.abwReference}</span>
			<span className={classes.description}>{project.description}</span>
		</div>
	);
};

export default ProjectItem;
