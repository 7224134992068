import React, { FC, ReactNode } from 'react';
import { Popup } from '@urbica/react-map-gl';
import classes from './index.module.css';

interface Props {
	lat: number;
	long: number;
	children?: ReactNode;
}

const CustomPopup: FC<Props> = ({ lat, long, children }): JSX.Element => {
	return (
		<Popup longitude={long} latitude={lat} closeButton={false} closeOnClick={false} anchor="bottom" className={classes.popup}>
			{children}
		</Popup>
	);
};

export default CustomPopup;
