import React, { FC } from 'react';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { TextButtonNeutral } from '@/components/custom/buttons';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { onKeyPress } from '@/helpers';
import { Soil } from '@/proto/build/soil_pb';
import { SoilDialogState } from '@/models/types';
import classes from './index.module.css';

interface Props {
	soilDialogIsOpen: boolean;
	soil: Soil.AsObject | null;
	handleSoilDialog: (status: SoilDialogState) => void;
}

export const SoilTypeReadOnlyDialog: FC<Props> = ({ soilDialogIsOpen, soil, handleSoilDialog }): JSX.Element | null => {
	if (!soil) return null;
	return (
		<BaseDialog
			title={soil.name}
			isOpen={soilDialogIsOpen}
			closeDialog={(): void => handleSoilDialog(SoilDialogState.HIDDEN)}
			actionButtons={<TextButtonNeutral onClick={(): void => handleSoilDialog(SoilDialogState.HIDDEN)}>CLOSE</TextButtonNeutral>}
		>
			<div className={classes.fields}>
				<OutlinedTextField
					fullWidth
					disabled
					value={soil?.cohesion}
					className={classes.cohesionTextField}
					label="Cohesion, c’"
					onKeyPress={onKeyPress}
					type="text"
					InputProps={{
						endAdornment: <span className={classes.endAdorment}>kPa</span>,
					}}
				/>
				<OutlinedTextField
					fullWidth
					disabled
					value={soil?.frictionAngle}
					className={classes.frictionAngleTextField}
					label="Friction angle, φ'"
					onKeyPress={onKeyPress}
					type="text"
					InputProps={{
						endAdornment: <span className={classes.endAdorment}>°</span>,
					}}
				/>
			</div>
		</BaseDialog>
	);
};

export default SoilTypeReadOnlyDialog;
