import { FC, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { projectSelected } from '@/routes';
import { setSelectedProject, updateMapBounds } from '@/store/actions';
import { createProjectWithMapView, MapViewCoords } from '@/store/types';
import { getSelectedProjectId } from '@/store/selectors';

// Sample params: http://localhost:3000/projects/1?west=4.446636370052632&south=52.16560917145359&east=4.4531685106296655&north=52.168085369644515
function generateMapViewFromParams(params: URLSearchParams): MapViewCoords | null {
	if (!params.has('west') || !params.has('south') || !params.has('east') || !params.has('north'))
		return null;

	return {
		north: Number(params.get('north')),
		east: Number(params.get('east')),
		south: Number(params.get('south')),
		west: Number(params.get('west')),
	};
}

const RouterToStoreConnect: FC<RouteComponentProps> = ({ location }): null | JSX.Element => {

	const [lastProjectId, setLastProjectId] = useState<number | null>(null);
	const [lastSearchString, setLastSearchString] = useState<string | null>(null);
	const currentProjectId = useSelector(getSelectedProjectId);

	/* Store dispatcher */
	const dispatch = useDispatch();

	useEffect((): void => {
		const matchProject = projectSelected.matchPath(location.pathname);
		const projectId = matchProject ? Number(matchProject.params.id) : null;

		if (lastProjectId !== projectId) {
			setLastProjectId(projectId);
			if (projectId) {
				if (projectId !== currentProjectId)
					dispatch(setSelectedProject(projectId));
			}	else {
				dispatch(setSelectedProject());
			}
		}

		if (lastSearchString !== location.search) {
			setLastSearchString(location.search);
			const mapView = generateMapViewFromParams(new URLSearchParams(location.search));
			if (mapView != null) {
				dispatch(updateMapBounds(createProjectWithMapView(projectId, mapView)));
			}
		}

	}, [location.pathname, lastProjectId, dispatch, location.search, lastSearchString, currentProjectId]);

	return null;
};

export default withRouter(RouterToStoreConnect);
