import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { MapPositioning } from '../types';

const initialState: MapPositioning = {
	bounds: {
		north: 52.42954547928613,
		east: 5.517161924086025,
		south: 51.75204407510262,
		west: 3.3589522762422916,
	},
};

export default createReducer(initialState, {
	[actions.updateMapBounds.type]: (draft, { payload }): MapPositioning  => {
		return {
			...draft,
			bounds: payload.bounds,
		};
	},
});
