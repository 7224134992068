import React, { FC, ReactNode } from 'react';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { TextButtonOrange } from '@/components/custom/buttons';
import classes from './index.module.css';

interface Props {
	children: ReactNode;
	visible: boolean;
	onHide: () => void;
}

const AlertBanner: FC<Props> = ({ children, visible, onHide }): JSX.Element => {
	return (
		<Collapse in={visible} timeout={10}>
			<Alert
				className={classes.root}
				classes={{ icon: classes.icon, message: classes.message, action: classes.action }}
				icon={<BrokenImageIcon fontSize="inherit" />}
				action={
					<TextButtonOrange className={classes.removeBtn} onClick={onHide}>
						DISMISS
					</TextButtonOrange>
				}
			>
				{children}
			</Alert>
		</Collapse>
	);
};

export default AlertBanner;
