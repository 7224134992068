import React, { FC, ChangeEvent, useRef } from 'react';
import { TextRaisedButton, TextIconFlatButton, TextButtonTurquiose, TextButtonOrange } from '@/components/custom/buttons';
import { CustomButtonType } from '@/models/types';
import classes from './index.module.css';

interface Props {
	accept: string;
	buttonText: string;
	onFilesSelect: (files: File[]) => void;
	multiple: boolean;
	className?: string;
	buttonType: CustomButtonType;
	children?: React.ReactNode;
	disabled?: boolean;
}

const getButtonType = (type: CustomButtonType): typeof TextIconFlatButton => {
	switch (type) {
		case 'purple':
			return TextRaisedButton;
		case 'gray':
			return TextIconFlatButton;
		case 'orange':
			return TextButtonOrange;
		case 'turquoise':
			return TextButtonTurquiose;
		default:
			return TextIconFlatButton;
	}
};

const FileUploadButton: FC<Props> = ({
	accept,
	multiple,
	className = '',
	onFilesSelect,
	buttonText,
	children,
	buttonType,
	disabled,
}): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const Component = getButtonType(buttonType);

	const onFileSelected = (event: ChangeEvent<HTMLInputElement>): void => {
		const { files } = event.target;
		if (files && files.length > 0) {
			const fileListAsArray = Array.from(files);
			onFilesSelect(fileListAsArray);
		}
		event.target.value = '';
	};

	return (
		<label className={classes.fileUpload}>
			<input
				ref={inputRef}
				type="file"
				accept={accept}
				onChange={(e): void => onFileSelected(e)}
				multiple={multiple}
				disabled={disabled}
				className={classes.fileUploadInput}
			/>
			<Component
				disabled={disabled}
				variant="contained"
				className={className}
				onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
					e.preventDefault();
					inputRef.current?.click();
				}}
			>
				{children}
				<span className={classes.buttonText}>{buttonText}</span>
			</Component>
		</label>
	);
};

export default FileUploadButton;
