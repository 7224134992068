import React, { FC } from 'react';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { default as MuiOutlinedInput, OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import classes from './index.module.css';

const OutlinedTextField: FC<TextFieldProps> = (props) => (
	<TextField
		{...props}
		className={classNames(classes.textField, props.className)}
		InputProps={{
			...props.InputProps,
			classes: {
				root: classNames(classes.root, props.InputProps?.classes?.root),
				input: classes.input,
				focused: classes.focused,
				error: classes.error,
			},
		}}
		InputLabelProps={{
			...props.InputLabelProps,
			classes: {
				root: classes.labelRoot,
				focused: classes.labelFocused,
			},
		}}
		variant="outlined"
	/>
);

export const OutlinedInput: FC<OutlinedInputProps> = (props) => (
	<MuiOutlinedInput
		{...props}
		className={classNames(classes.textField, props.className)}
		classes={{
			root: classes.root,
			input: classes.input,
			focused: classes.focused,
			error: classes.error,
		}}
	/>
);

export { OutlinedTextField };
