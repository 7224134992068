import React, { FC } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MuiMenuList from '@material-ui/core/MenuList';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import classes from './index.module.css';

export interface MenuListProps extends PopperProps {
	header: string;
	open: boolean;
	setOpen: (isOpen: boolean) => void;
}

export const MenuList: FC<MenuListProps> = ({ open, header, setOpen, anchorEl, children }): JSX.Element => {
	return (
		<Popper open={open} anchorEl={anchorEl} transition placement="bottom-end" className={classes.popper}>
			{({ TransitionProps }): JSX.Element => (
				<Grow {...TransitionProps} style={{ transformOrigin: 'right 0' }}>
					<Paper className={classes.popupMenu}>
						<span className={classes.header}>{header}</span>
						<ClickAwayListener onClickAway={(): void => setOpen(false)}>
							<MuiMenuList>{children}</MuiMenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
};

export default MenuList;
