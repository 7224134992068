import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import { useAuth0 } from '@auth0/auth0-react';
import { ReferenceObject } from 'popper.js';
import Tooltip from '@material-ui/core/Tooltip';
import { getChartsVisibility, getSelectedProjectId } from '@/store/selectors';
import AppBar from '@/components/Layout/AppBar';
import Content from '@/components/Layout/Content';
import SideBar from '@/components/Layout/SideBar';
import ExportInterpretationButton from '@/components/custom/ExportInterpretationButton';
import { updateChartsVisibility } from '@/store/actions';
import { default as logoutIcon } from '@/assets/images/menu/logout-24px.svg';
import MenuList from '@/components/custom/IconPoperListMenu';
import { CustomIconButton } from '@/components/custom/iconButtons';
import { mixPanelReset } from '@/tracking/mixPanel';
import classes from './index.module.css';

const Interpretation: FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const visibleCharts = useSelector(getChartsVisibility);
	const selectedProjectId = useSelector(getSelectedProjectId);
	const [zoomLevel, setZoomLevel] = useState(1);
	const [openSideBar, setOpenSideBar] = useState(true);
	const { user, isAuthenticated, logout } = useAuth0();
	const [anchorEl, setAnchorEl] = React.useState<null | ReferenceObject | (() => ReferenceObject)>(null);
	const [menuOpen, setMenuOpen] = React.useState(false);

	const handleOnZoomInOut = (value: number): void => {
		setZoomLevel((currentZoomLevel) => {
			return value >= 0.3 && value <= 8 ? value : currentZoomLevel;
		});
	};

	const handleSideBar = (): void => {
		setOpenSideBar(!openSideBar);
	};

	const handleChartVisibilityChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
		const { value } = event.target as HTMLSelectElement;
		const values: string[] = [];

		for (let i = 0, l = value.length; i < l; i += 1) {
			if (value[i]) {
				values.push(value[i]);
			}
		}

		dispatch(updateChartsVisibility({ visible: values, visibilityChangedManually: true }));
	};

	const signUserOut = (): void => {
		mixPanelReset();
		logout();
	};

	return (
		<div className={classes.root}>
			<AppBar
				zoomLevel={zoomLevel}
				onZoomInOut={handleOnZoomInOut}
				visibleCharts={visibleCharts}
				handleChartsVisibility={handleChartVisibilityChange}
			>
				<Tooltip title={!selectedProjectId ? 'A project need to be selected to be able to export!' : ''} placement="left">
					<span><ExportInterpretationButton disabled={!selectedProjectId}  buttonText="Export" /></span>
				</Tooltip>
				<CustomIconButton classes={{ root: classes.profileButton }} onClick={ (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
					setAnchorEl(e.currentTarget);
					setMenuOpen(!menuOpen);
					e.stopPropagation();
				} }>
					<PersonIcon classes={{ root: classes.profileIcons }} />
					<MenuList
						header={ isAuthenticated ? user?.name as string : 'Guest' }
						open={ menuOpen }
						setOpen={ setMenuOpen }
						anchorEl={ anchorEl }
					>
						<MenuItem className={ classes.menuItem } onClick={ signUserOut }>
							<img
								src={logoutIcon}
								alt="Sign out"
								className={classes.profileIcons}
							/>
							<span className={ classes.itemText }>Sign out</span>
						</MenuItem>
					</MenuList>
				</CustomIconButton>
			</AppBar>
			<div className={classes.interpretation}>
				<Content zoomLevel={zoomLevel} onZoomInOut={handleOnZoomInOut} openSideBar={openSideBar} />
				<SideBar handleSideBar={handleSideBar} openSideBar={openSideBar} />
			</div>
		</div>
	);
};

export default Interpretation;
