import React, { FC, Fragment } from 'react';
import { dateTimestampToString, getTimeStamp } from '@/helpers';
import { BoreholeAsObject, CPTAsObject } from '@/store/types';
import classes from './index.module.css';

interface Props {
	data: CPTAsObject | BoreholeAsObject;
}

const MetaDataContent: FC<Props> = ({ data }) => {
	const isCpt = 'cptNumber' in data;

	const getGroundWaterLevel = () => {
		if (isCpt) {
			return '-';
		} else {
			if (data.groundWaterLevel === -999) {
				return '-';
			} else {
				return (Math.round(data.groundWaterLevel * 100) / 100).toString();
			}
		}
	};

	return (
		<Fragment>
			<p className={classes.data}>
				<span className={classes.head}>SI contractor</span>
				{isCpt && <span className={classes.value}>{data.contractor}</span>}
				{!isCpt &&
					<span className={classes.value}>{`${data.contractor}` || ''}{data.chamberOfCommerceNumber
						&& `KVK: ${data.chamberOfCommerceNumber}`}</span>}
			</p>
			<p className={classes.data}>
				<span className={classes.head}>Project</span>
				<span className={classes.value}>{isCpt ? data.project || '-' : '-'}</span>
			</p>
			<p className={classes.data}>
				<span className={classes.head}>Location</span>
				<span className={classes.value}>{isCpt ? data.location || '-' : `${data.position?.latitude}, ${data.position?.longitude}` }</span>
			</p>
			{isCpt &&
			<p className={classes.data}>
				<span className={classes.head}>Position</span>
				<span className={classes.value}>{`${data.position?.longitude}, ${data.position?.latitude}`}</span>
			</p>
			}
			{isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Predrill</span>
					<span className={classes.value}>{data.preDrill || '-'}</span>
				</p>
			}
			<p className={classes.data}>
				<span className={classes.head}>Date</span>
				<span className={classes.value}>{dateTimestampToString(getTimeStamp(data.date)) || '-'}</span>
			</p>
			{isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Cone No.</span>
					<span className={classes.value}>{data.coneNumber || '-'}</span>
				</p>
			}
			{!isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Interpretation norm</span>
					<span className={classes.value}>{data.interpretationMethod || '-'}</span>
				</p>
			}
			{isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Project no.</span>
					<span className={classes.value}>{data.projectNumber || '-'}</span>
				</p>
			}
			<p className={classes.data}>
				<span className={classes.head}>{isCpt ? 'CPT no.' : 'Borehole no.'}</span>
				<span className={classes.value}>{isCpt ? data.cptNumber || '-' : data.boreholeId}</span>
			</p>
			{!isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Identification location</span>
					<span className={classes.value}>
						{data.descriptionLocation.toLocaleUpperCase()}
						{data.hasMultipleDescriptionLocations && ' (This file has multiple description locations)'}
					</span>
				</p>
			}
			{!isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Surface level</span>
					<span className={classes.value}>{data.z.toFixed(3) || '-'}</span>
				</p>
			}
			{!isCpt &&
				<p className={classes.data}>
					<span className={classes.head}>Groundwater level</span>
					<span className={classes.value}>{getGroundWaterLevel()}</span>
				</p>
			}
		</Fragment>
	);
};

export default MetaDataContent;
