import React, { FC, Fragment, useMemo, useCallback, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@material-ui/core';
import { distanceBetweenSteps } from '@/components/Layout/DepthRuler';
import { ESoilInvestigationType } from '@/models/types';
import {
	setSelectedLayer,
	setHoveredSoilLayer,
	resetHoveredSoilLayer,
} from '@/store/actions';
import { getHoveredLayerID, getSelectedLayerID } from '@/store/selectors';
import { BoreholeSoilFraction, BoreholeSoilLayer as BoreholeSoilLayerGrpc, BoreholeSoilPattern } from '@/proto/build/borehole_pb';
import classes from './index.module.css';

/* Dynamically include all soil layer images */
const imageContext = require.context('@/assets/images/patterns', false, /\.(svg)$/);

interface Props {
	layer: BoreholeSoilLayerGrpc.AsObject;
	boreholeID: number;
	barStart: number;
	distanceBasedOnZoom: number;
	isDeletable?: boolean;
	isHovering?: boolean;
	isSelected: boolean;
}

const getTooltipPosition = (distanceBasedOnZoom: number): number => {
	let soilLayerWidth = 54;
	const defaultMarginLeft = 2;

	// This is an efficient way to get the zoomLevel without the need to pass it through all the component that use distanceBasedOnZoom
	// we have it already in the distanceBasedOnZoom so we need to divide the distanceBasedOnZoom with distanceBetweenSteps
	// Otherwise we would need to refactor a lot of component that are using this distanceBasedOnZoom prop and pass both
	// (distanceBetweenSteps and zoomLevel separated) or pass the zoomLevel the distanceBasedOnZoom separated
	// distanceBasedOnZoom = distanceBetweenSteps * zoomLevel;
	let zoomLevel = distanceBasedOnZoom / distanceBetweenSteps;

	// The default SoilBar width becomes 40px (MIN_WIDTH) when the zoom level is below 75%
	if (zoomLevel < 0.75) {
		soilLayerWidth = 40;
		zoomLevel = 1;
	}
	// The max SoilBar width is 162px (3 * 54)
	if (zoomLevel > 3) {
		zoomLevel = 3;
	}

	return soilLayerWidth * zoomLevel + defaultMarginLeft * zoomLevel;
};

const BoreholeSoilLayer: FC<Props> = ({
	layer,
	boreholeID,
	barStart,
	distanceBasedOnZoom,
	isSelected,
}): JSX.Element | null => {
	const dispatch = useDispatch();
	const { depthFromTop: top, height, fractionsList, id : soilLayerID  } = layer;
	const hovered = useSelector(getHoveredLayerID);
	const selectedLayer = useSelector(getSelectedLayerID);
	const depthInPixels = height * distanceBasedOnZoom;

	/* Calculate the Tooltip when hover left position and memoize it */
	const computedTooltipPosition = useMemo(() => getTooltipPosition(distanceBasedOnZoom), [distanceBasedOnZoom]);

	const handleOnSelect = (e: MouseEvent): void => {
		e.preventDefault();
		e.stopPropagation();
		if (soilLayerID === +selectedLayer.id) return;
		dispatch(
			setSelectedLayer({
				soilInvestigationId: boreholeID.toString(),
				soilInvestigationType: ESoilInvestigationType.BOREHOLE,
				id: String(soilLayerID),
			}),
		);
	};

	const getTopInPx = useCallback((top: number): number => {
		return (barStart - top) * distanceBasedOnZoom - 1;
	}, [barStart, distanceBasedOnZoom]);

	const handleOnMouseEnter = (): void => {
		dispatch(setHoveredSoilLayer({ soilInvestigationId: boreholeID.toString(), id: soilLayerID.toLocaleString() }));
	};

	const handleOnMouseLeave = (): void => {
		dispatch(resetHoveredSoilLayer());
	};

	const getPositionTooltip = (value: string, className: string): JSX.Element => {
		return (
			<div className={classNames(classes.positionTooltip, className)} style={{ left: computedTooltipPosition + 3 }}>
				{value}
			</div>
		);
	};

	const soilTooltip = (): JSX.Element => {
		return (
			<div className={classes.tooltip} style={{ left: computedTooltipPosition }}>
				<span className={classes.tooltipLabel}>{layer.name}</span>
			</div>
		);
	};

	const topInPx = getTopInPx(top);

	const getFractionWidth = (fraction: BoreholeSoilFraction.AsObject): number => {
		const elementWidth = 100;
		let primaryWidth = 0;

		if (fraction.rank === 0) {
			let totalNotPrimaryWidth = 0;
			[...fractionsList].forEach((fraction) => totalNotPrimaryWidth += (fraction.gradation?.width || 0) * 100);
			primaryWidth = elementWidth - totalNotPrimaryWidth;
		} else {
			primaryWidth = (fraction.gradation?.width || 0) * 100;
		}

		return primaryWidth * distanceBasedOnZoom;
	};

	return (
		<li
			className={classNames(
				classes.layer,
				{ [classes.selected]: isSelected },
				{ [classes.hovered]: hovered.id === soilLayerID.toLocaleString() },
			)}
			style={{
				top: topInPx,
				height: depthInPixels,
				borderTop: '1px solid #000',
			}}
			onClick={(e: MouseEvent) => handleOnSelect(e)}
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
		>
			<Fragment>
				{isSelected && getPositionTooltip(top.toFixed(2), classes.positionTooltipTop)}
				{isSelected && <span className={classes.topHandle}/>}
				{hovered.id === soilLayerID.toLocaleString() && soilTooltip()}
				{isSelected && <span className={classes.bottomHandle}/>}
				{isSelected && getPositionTooltip((top - height).toFixed(2), classes.positionTooltipBottom)}
			</Fragment>
			{
				[...fractionsList].map((fraction, index) => {
					const { soil } = fraction;
					const { boreholeSoilPattern } = soil || {};

					return (<Box
						component="span"
						className={'borhole_layer'}
						key={`bh_fraction_${uuidv4()}`}
						style={{
							backgroundColor: `#${soil?.color}`,
							width: getFractionWidth(fraction),
							height: '100%',
							borderLeft: index !== 0 ? '2px solid #000' : '',
							backgroundSize: '10px',
							backgroundImage:
										boreholeSoilPattern !== undefined
											? `url(${imageContext(`./${Object.keys(BoreholeSoilPattern)[boreholeSoilPattern].toLowerCase()}.svg`)})`
											: '',
						}}>
					 </Box>
					 );
				})
			}
		</li>
	);
};

export default React.memo(BoreholeSoilLayer);

