import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { ChartVisibility } from '@/models/types';

const initialState: {visible: string[]; visibilityChangedManually: boolean} =
	{ visible: Object.values(ChartVisibility).filter((chartName) => chartName !== ChartVisibility.WATER_PRESSURE)
		.map((chartName) => chartName),
	visibilityChangedManually: false };

export default createReducer(initialState, {
	[actions.updateChartsVisibility.type]: (_, { payload }) => payload,
	[actions.showWaterPressure.type]: (draft, { payload }): void => {
		if (!draft.visibilityChangedManually){
			if (payload) {
				if (!draft.visible.includes(ChartVisibility.WATER_PRESSURE)){
					draft.visible.push(ChartVisibility.WATER_PRESSURE);
				}
			} else {
				//Unless chart visibility is changed manually, water pressure, if present, will always be at index 3.
				draft.visible.splice(3, 1);
			}
		}
	},
});
