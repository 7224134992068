import { User } from '@auth0/auth0-spa-js';
import { createAction } from '@reduxjs/toolkit';

/* Action Types */
enum ActionTypes {
	USER_LOGGED_IN = '@@mondriaan/USER/LOGGED_IN',
}

interface UserWithAcessCode {
	accessCode: string;
	user: User;
}

export const userLoggedIn = createAction<UserWithAcessCode>(ActionTypes.USER_LOGGED_IN);
