// source: cpt.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var location_pb = require('./location_pb.js');
goog.object.extend(proto, location_pb);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CPT', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CPTList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.CPT.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CPT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CPT.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CPT';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CPTList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CPTList';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.repeatedFields_ = [11,13,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    contractor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    project: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    position: (f = msg.getPosition()) && location_pb.LatLng.toObject(includeInstance, f),
    preDrill: jspb.Message.getFieldWithDefault(msg, 6, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    coneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    projectNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cptNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.toObject, includeInstance),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    warningsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    fileName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    fullCompanyId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    soilLayersList: jspb.Message.toObjectList(msg.getSoilLayersList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CPT;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = new location_pb.LatLng;
      reader.readMessage(value,location_pb.LatLng.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreDrill(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConeNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCptNumber(value);
      break;
    case 11:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addWarnings(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullCompanyId(value);
      break;
    case 16:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.deserializeBinaryFromReader);
      msg.addSoilLayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CPT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getContractor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      location_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getPreDrill();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConeNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProjectNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCptNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.serializeBinaryToWriter
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getWarningsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFullCompanyId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSoilLayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 project_id = 17;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string contractor = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getContractor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setContractor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional LatLng position = 5;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getPosition = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, location_pb.LatLng, 5));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.LatLng|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string pre_drill = 6;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getPreDrill = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setPreDrill = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.hasDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string cone_number = 8;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getConeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setConeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string project_number = 9;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getProjectNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setProjectNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cpt_number = 10;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getCptNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setCptNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated CPTRecord records = 11;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord, 11));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional float z = 12;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * repeated string warnings = 13;
 * @return {!Array<string>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getWarningsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setWarningsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.addWarnings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.clearWarningsList = function() {
  return this.setWarningsList([]);
};


/**
 * optional string file_name = 14;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string full_company_id = 15;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getFullCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setFullCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated SoilLayer soil_layers = 16;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.getSoilLayersList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, 16));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.setSoilLayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.addSoilLayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPT.prototype.clearSoilLayersList = function() {
  return this.setSoilLayersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    penetrationLength: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    coneResistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    frictionNumber: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    depthBasedOnZValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    localFriction: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    porePressure: (f = msg.getPorePressure()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPenetrationLength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConeResistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrictionNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepthBasedOnZValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLocalFriction(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setPorePressure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPenetrationLength();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getConeResistance();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getFrictionNumber();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDepthBasedOnZValue();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLocalFriction();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getPorePressure();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional float penetration_length = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.getPenetrationLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.setPenetrationLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float cone_resistance = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.getConeResistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.setConeResistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float friction_number = 3;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.getFrictionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.setFrictionNumber = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float depth_based_on_z_value = 4;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.getDepthBasedOnZValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.setDepthBasedOnZValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float local_friction = 5;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.getLocalFriction = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.setLocalFriction = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional google.protobuf.FloatValue pore_pressure = 6;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.getPorePressure = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 6));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.setPorePressure = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.clearPorePressure = function() {
  return this.setPorePressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTRecord.prototype.hasPorePressure = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: msg.getPayload_asB64(),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk;
  return proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes payload = 1;
 * @return {!(string|Uint8Array)}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes payload = 1;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.toObject = function(includeInstance, msg) {
  var f, obj = {
    cptId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    soilLayersList: jspb.Message.toObjectList(msg.getSoilLayersList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation;
  return proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCptId(value);
      break;
    case 2:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.deserializeBinaryFromReader);
      msg.addSoilLayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCptId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSoilLayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 cpt_id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.getCptId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.setCptId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SoilLayer soil_layers = 2;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.getSoilLayersList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, 2));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.setSoilLayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.addSoilLayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation.prototype.clearSoilLayersList = function() {
  return this.setSoilLayersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpt: (f = msg.getCpt()) && proto.com.royalhaskoningdhv.mondriaan.proto.CPT.toObject(includeInstance, f),
    soilLayersList: jspb.Message.toObjectList(msg.getSoilLayersList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.CPT;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.CPT.deserializeBinaryFromReader);
      msg.setCpt(value);
      break;
    case 2:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.deserializeBinaryFromReader);
      msg.addSoilLayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.CPT.serializeBinaryToWriter
    );
  }
  f = message.getSoilLayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional CPT cpt = 1;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.CPT}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.getCpt = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.CPT} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.CPT, 1));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.CPT|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.setCpt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.clearCpt = function() {
  return this.setCpt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.hasCpt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SoilLayer soil_layers = 2;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.getSoilLayersList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, 2));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.setSoilLayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.addSoilLayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTInterpretation.prototype.clearSoilLayersList = function() {
  return this.setSoilLayersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    depthTop: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    depthBase: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    soilId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepthTop(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepthBase(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSoilId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepthTop();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDepthBase();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSoilId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float depth_top = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.getDepthTop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.setDepthTop = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float depth_base = 3;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.getDepthBase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.setDepthBase = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 soil_id = 4;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.getSoilId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLayer.prototype.setSoilId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.toObject = function(includeInstance, msg) {
  var f, obj = {
    cptIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCptIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCptIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 cpt_ids = 1;
 * @return {!Array<number>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.prototype.getCptIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.prototype.setCptIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.prototype.addCptIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTIdList.prototype.clearCptIdsList = function() {
  return this.setCptIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.toObject = function(includeInstance, msg) {
  var f, obj = {
    cptsList: jspb.Message.toObjectList(msg.getCptsList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.CPT.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CPTList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.CPT;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.CPT.deserializeBinaryFromReader);
      msg.addCpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.CPT.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CPT cpts = 1;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.CPT>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.prototype.getCptsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.CPT>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.CPT, 1));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.CPT>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.prototype.setCptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPT}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.prototype.addCpts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.CPT, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CPTList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTList.prototype.clearCptsList = function() {
  return this.setCptsList([]);
};


goog.object.extend(exports, proto.com.royalhaskoningdhv.mondriaan.proto);
