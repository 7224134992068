import React, { useState, useRef, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { LngLatBounds, MapMouseEvent } from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import MapGL, { Marker, Viewport } from '@urbica/react-map-gl';
import Cluster, { ClusterComponentProps } from '@urbica/react-map-gl-cluster';
import { getProjects, getSelectedSoilInvestigationIds, getSelectedProject, getSelectedProjectId } from '@/store/selectors';
import { createProjectWithMapView } from '@/store/types';
import {
	setConfirmSoilInvestigationsDeletionDialogOpen,
	setSelectedProject,
	 updateMapBounds } from '@/store/actions';
import CustomCluster from './CustomCluster';
import CustomIcon, { MarkerStatus } from './CustomIcon';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MousePosition } from '@/models/types';
import FilesSelectionMenu from '../MapSideBar/FilesSelectionMenu';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { lngLatBoundsToMapViewCoords } from '@/helpers';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import {
	setSelectedSoilInvestigationIds,
	setSelectedSoilInvestigations,
} from '@/store/actions';

const currentMapLayerStyle = `mapbox://styles/mapbox/${!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'dark-v11' : 'light-v9'}`;
const DEFAULT_VIEWPORT: Viewport = {
	latitude: 52.21158,
	longitude: 5.600489,
	zoom: 7,
};


const getStatus = (
	projectid: number,
	selectedProjectId: number | null,
	soilInvestigationId: SoilInvestigationId.AsObject,
	selectedSoilInvestigationIds: SoilInvestigationId.AsObject[],
): MarkerStatus => {
	const found = selectedSoilInvestigationIds.find((siId) =>
		siId.soilInvestigationId === soilInvestigationId.soilInvestigationId && siId.type === soilInvestigationId.type);

	if (found) {
		return MarkerStatus.SELECTED;
	}

	if (projectid === selectedProjectId) {
		return MarkerStatus.ACTIVE;
	} else {
		return MarkerStatus.ARCHIVED;
	}
};

// example of a params bounds => ?west=4.446636370052632&south=52.16560917145359&east=4.4531685106296655&north=52.168085369644515
function generateBoundsFromParams(searchString: string): LngLatBounds | null {
	if (!searchString || searchString.length <= 1) return null;
	const params = new URLSearchParams(searchString);
	if (!params.has('west') || !params.has('south') || !params.has('east') || !params.has('north')) return null;

	return new LngLatBounds([Number(params.get('west')), Number(params.get('south')), Number(params.get('east')), Number(params.get('north'))]);
}

const initialMousePos: MousePosition = {
	mouseX: null,
	mouseY: null,
};

const Mapbox: FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const location = useLocation();
	const mapRef = useRef<MapGL>(null);
	const selectedProject = useSelector(getSelectedProject);
	const selectedProjectId = useSelector(getSelectedProjectId);
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const projects = useSelector(getProjects);
	const [viewport, setViewport] = useState<Viewport>(DEFAULT_VIEWPORT);
	const [mouseRightClickPos, setMouseRightClickPos] = useState<MousePosition>(initialMousePos);

	useEffect(() => {
		const boundsFromUrl = generateBoundsFromParams(location.search);

		const map = mapRef?.current?.getMap();

		if (map && boundsFromUrl ) {
			map.fitBounds(boundsFromUrl, { duration: 1500 });
		}
	}, [location.search]);

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.getMap().keyboard.disable();
		}
	}, []);

	const onClusterClick = (id: number): void => {
		dispatch(setSelectedSoilInvestigationIds([]));
		dispatch(setSelectedSoilInvestigations({}));
		dispatch(setSelectedProject({ id, zoomToProject: true, origin: 'Map' }));
	};

	const handleMapPositionChange = (): void => {
		if (mapRef.current != null) {
			const currentBounds = mapRef.current.getMap().getBounds();
			dispatch(updateMapBounds(createProjectWithMapView(selectedProjectId, lngLatBoundsToMapViewCoords(currentBounds))));
		}
	};

	const handleRightClick = (event: MapMouseEvent): void => {
		event.preventDefault();
		if (selectedSoilInvestigationIds.length) {
			setMouseRightClickPos({
				mouseX: event.point.x,
				mouseY: event.point.y,
			});
		}
	};

	const confirmDeleteSoilInvestigationsDialog = (): void => {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(true));
	};

	const handleFilesMenuClose = (): void => {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(false));
		setMouseRightClickPos(initialMousePos);
	};

	return (
		<MapGL
			{...viewport}
			ref={mapRef}
			mapStyle={currentMapLayerStyle}
			accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
			style={{ width: '100vw', height: '100vh' }}
			onViewportChange={(view: Viewport): void => {
				setViewport(view);
				handleMapPositionChange();
			}}
			dragRotate={false}
			onContextmenu={handleRightClick}
		>
			{projects && Object.values(projects).map(
				(project): JSX.Element => {
					const { cptsList, boreholesList } = project;
					return (
						<Cluster
							key={`${project.id}`}
							radius={10000}
							extent={1}
							nodeSize={1}
							maxZoom={project.id === selectedProject?.id ? 10 : 12}
							component={(props: ClusterComponentProps): JSX.Element =>
								<CustomCluster
									markerProps={props}
									project={project}
									selected={project.id === selectedProject?.id}
									onClick={(id): void => onClusterClick(id)}
								/>
							}
						>
							{
								[...cptsList, ...boreholesList].map((soilInvestigation): JSX.Element | null => {
									const { position } = soilInvestigation;
									if (!position) return null;
									const status = getStatus(project.id, selectedProject?.id || -9999, {
										soilInvestigationId: soilInvestigation.id,
										type: soilInvestigation.type,
									}, selectedSoilInvestigationIds);
									return (
										<Marker
											key={`${soilInvestigation.id}_${status}`}
											longitude={position.longitude}
											latitude={position.latitude}
											anchor="center">
											<CustomIcon simpleSoilInvestigation={soilInvestigation} status={status} />
										</Marker>
									);
								})
							}
						</Cluster>
					);
				},
			)}
			<FilesSelectionMenu
				handleClose={(): void => handleFilesMenuClose()}
				projectId={selectedProject?.id || -999}
				projectName={selectedProject?.name || ''}
				interpretationMethod={selectedProject?.interpretationMethod || InterpretationMethod.MANUAL}
				soilInvestigationIds={selectedSoilInvestigationIds}
				mousePosition={mouseRightClickPos}
				open={mouseRightClickPos?.mouseY !== null}
				handleDeleteSoilInvestigation={confirmDeleteSoilInvestigationsDialog}
			/>
		</MapGL>
	);
};

export default Mapbox;
