import { CPTRecord, SoilLayer } from '@/proto/build/cpt_pb';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';

export enum ESoilInvestigationType {
	CPT = 'CPT',
	BOREHOLE = 'Borehole',
}

export enum CPTStatus {
	EMPTY = 'EMPTY',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
}

export enum ExportStatus {
	EMPTY = 'EMPTY',
	ERROR = 'ERROR',
	TOO_LARGE = 'TOO_LARGE',
}

export type UUID = string;

export type DraggingState = 'top' | 'bottom' | undefined;

export interface SelectedSoilInvestigationLayer {
	soilInvestigationId: string;
	id: UUID;
	soilInvestigationType?: ESoilInvestigationType;
	isDragging?: DraggingState;
}

export type Position = {
	top: number;
	depth: number;
};

export type HoveredSoilInvestigationLayer = SelectedSoilInvestigationLayer;

export interface HoverProps {
	cx: number;
	cy: number;
	dataKey: string;
	fill: string;
	index: number;
	key: string;
	payload: CPTRecord.AsObject;
	r: number;
	stroke: string;
	strokeWidth: number;
	value: number;
}

export type CustomButtonType = 'purple' | 'gray' | 'orange' | 'turquoise';

export enum ProjectStatusType {
	UPDATING = 'Updating',
	CREATING = 'Creating',
	UNKNOWN = 'Unknown',
}

export interface ProjectProgress {
	show: boolean;
	progress: number;
	type: ProjectStatusType;
}

export interface SelectProjectPayload {
	id: number;
	zoomToProject: boolean;
	origin?: string;
}

export type SoilInvestigationIdWithLayerPayload = {
	soilInvestigationId: string;
	layer: SoilLayer.AsObject;
};

export enum UploadMethod {
	BUTTON = 'Button',
	DRAG_AND_DROP = 'Drag and Drop',
}

export enum SoilCategories {
	STANDARD_LIBRARY = 'Standard Library',
	ROBERTSON = 'Robertson',
	CUR_RULE = 'CUR Rule',
	IC_TYPE = 'IC Type',
	PROJECT_SPECIFIC = 'Project Specific'
}

export const getDescriptionOf = (interpretation?: InterpretationMethod): string => {
	switch (interpretation) {
		case InterpretationMethod.MANUAL:
			return 'Manual Interpretation';
		case InterpretationMethod.ROBERTSON:
			return 'Robertson, 2015';
		case InterpretationMethod.IC_TYPE:
			return 'IC Type, 2015';
		case InterpretationMethod.CUR:
			return 'CUR Rule';
		default:
			throw Error('Unknown interpretation method');
	}
};

export interface MousePosition {
	mouseX: null | number;
	mouseY: null | number;
}

export enum ChartVisibility {
	WATER_PRESSURE = 'Water Pressure',
	CONE_RESISTANCE = 'Cone Resistance',
	FRICTION_RATIO = 'Friction Ratio',
	SOIL_LAYERS = 'Soil Layers',
}

export enum SoilDialogState {
	HIDDEN = 0,
	NEW = 1,
	CUSTOM = 2,
	OTHERS = 3,
}
