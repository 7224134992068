import { createSelector } from '@reduxjs/toolkit';
import { AppState, SoilState } from '@/store/types';
import { getSelectedProject } from '@/store/selectors/index';

const appState = (s: AppState): AppState => s;
const soilState = createSelector(appState, (s: AppState): SoilState => s.soil);

export const getSoilLibraries = createSelector(appState, getSelectedProject, (a, project) => {
	const libraries = {
		...a.soil.libraries,
	};

	if (project && project.customSoilLibrary) {
		libraries[project.customSoilLibrary.id] = project.customSoilLibrary;
	}

	return libraries;
});
export const getSoilCategories = createSelector(soilState, (s: SoilState) => s.categories);
export const getSoils = createSelector(soilState, (s: SoilState) => s.soils);
export const getProjectSoils = createSelector(soilState, getSelectedProject, (s, project) =>
	project?.customSoilLibrary?.soilsList.map((soilId) => s.soils[soilId]) || []);
