import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default as cptMarkerActive } from '@/assets/images/markers/cpt_active.svg';
import { default as cptMarkerArchived } from '@/assets/images/markers/cpt_archived.svg';
import { default as cptMarkerHovered } from '@/assets/images/markers/cpt_hovered.svg';
import { default as cptMarkerSelected } from '@/assets/images/markers/cpt_selected.svg';
import { default as boreholeMarkerActive } from '@/assets/images/markers/borehole_active.svg';
import { default as boreholeMarkerArchived } from '@/assets/images/markers/borehole_archived.svg';
import { default as boreholeMarkerHovered } from '@/assets/images/markers/borehole_hovered.svg';
import { default as boreholeMarkerSelected } from '@/assets/images/markers/borehole_selected.svg';
import { getTimeStamp, dateTimestampToString } from '@/helpers';
import { setSelectedSoilInvestigationIds, viewSoilInvestigations } from '@/store/actions';
import { getSelectedSoilInvestigationIds, getSelectedProject } from '@/store/selectors';
import CustomPopup from './CustomPopup';
import classes from './index.module.css';
import { SimpleSoilInvestigation, SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';
import { ESoilInvestigationType } from '@/models/types';

export enum MarkerStatus {
	ACTIVE = 'active',
	ARCHIVED = 'archived',
	HOVERED = 'hovered',
	SELECTED = 'selected',
}

interface Props {
	simpleSoilInvestigation: SimpleSoilInvestigation.AsObject;
	status: MarkerStatus;
}

const getIcon = (type: string, status: MarkerStatus): string => {
	const isCpt = type === ESoilInvestigationType.CPT;
	switch (status) {
		case MarkerStatus.ACTIVE:
			return isCpt ? cptMarkerActive : boreholeMarkerActive;
		case MarkerStatus.ARCHIVED:
			return isCpt ? cptMarkerArchived : boreholeMarkerArchived;
		case MarkerStatus.HOVERED:
			return isCpt ? cptMarkerHovered : boreholeMarkerHovered;
		case MarkerStatus.SELECTED:
			return isCpt ? cptMarkerSelected : boreholeMarkerSelected;
		default:
			throw new Error('This icon does not exist');
	}
};

const CustomIcon: React.FC<Props> = ({ simpleSoilInvestigation, status }): JSX.Element => {
	const dispatch = useDispatch();
	const [iconStatus, setIconsStatus] = useState(status);
	const [showPopUp, setShowPopUp] = useState<boolean>(false);
	const selectedProject = useSelector(getSelectedProject);
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const [showPopUpTimeout, setShowPopUpTimeout] = useState<boolean>(false);
	const [date, setDate] = useState<string>('');
	const { id, type, testId, position, contractor, date: simpleSoilInvestigationDate } = simpleSoilInvestigation;

	useEffect(() => {
		if (simpleSoilInvestigationDate) {
			const ts = getTimeStamp(simpleSoilInvestigationDate);
			setDate(dateTimestampToString(ts));
		}
	}, [simpleSoilInvestigationDate]);

	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (!showPopUp) {
			return;
		}

		const timeout = setTimeout(() => setShowPopUpTimeout(true), 500);
		return (): void => {
			clearTimeout(timeout);
		};
	}, [showPopUp]);

	const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
		e.preventDefault();
		setIconsStatus(MarkerStatus.HOVERED);
		setShowPopUp(true);
	};

	const handleMouseLeave = (e: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
		e.preventDefault();
		setIconsStatus(status);
		setShowPopUp(false);
		setShowPopUpTimeout(false);
	};

	function onMarkerClick(e: React.MouseEvent<HTMLImageElement, MouseEvent>, soilInvestigationId:  SoilInvestigationId.AsObject): void {
		let currentSelectedSoilInvestigationIds = [...selectedSoilInvestigationIds];
		if (e.ctrlKey || e.metaKey) {
			const foundIndex = selectedSoilInvestigationIds.findIndex((siId) =>
				siId.soilInvestigationId === soilInvestigationId.soilInvestigationId && siId.type === soilInvestigationId.type  );
			if (foundIndex >= 0) {
				currentSelectedSoilInvestigationIds.splice(foundIndex, 1);
			} else {
				currentSelectedSoilInvestigationIds = [...currentSelectedSoilInvestigationIds, soilInvestigationId];
			}
		} else {
			currentSelectedSoilInvestigationIds = [soilInvestigationId];
		}

		dispatch(setSelectedSoilInvestigationIds(currentSelectedSoilInvestigationIds));
	}

	const handleMarkerDoubleCLick = (event: React.MouseEvent, soilInvestigationId: SoilInvestigationId.AsObject): void => {
		event.preventDefault();
		dispatch(viewSoilInvestigations({ projectId: selectedProject?.id || -9999, soilInvestigationsIds: [soilInvestigationId] }));
	};

	return (
		<Fragment>
			<img
				src={getIcon(type, iconStatus)}
				alt="status"
				onMouseOver={handleMouseEnter}
				onMouseOut={handleMouseLeave}
				className={classes.cptIcon}
				onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>): void => onMarkerClick(e, {
					soilInvestigationId: id,
					type: type,
				})}
				onDoubleClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>): void => handleMarkerDoubleCLick(e, {
					soilInvestigationId: id,
					type: type,
				})}
			/>
			{showPopUpTimeout && (
				// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
				<CustomPopup long={position?.longitude!} lat={position?.latitude!}>
					<span className={classes.name}>{testId}</span>
					<span className={classes.date}>{date}</span>
					<span className={classes.contractorHead}>Si contractor:</span>
					<span className={classes.contractor}>{contractor}</span>
				</CustomPopup>
			)}
		</Fragment>
	);
};

export default CustomIcon;
