import { withStyles } from '@material-ui/core/styles';
import { default as MUIIConButton } from '@material-ui/core/IconButton';

const CustomIconButton = withStyles({
	root: {
		padding: '4px',
		borderRadius: '4px',
		color: '#ffffff',
		backgroundColor: '#424242',
		'&:disabled': {
			backgroundColor: '#b1b3b5',
			boxShadow: 'none',
			color: '#333333',
			'&:focus, &:hover': {
				color: '#333333',
				backgroundColor: '#b1b3b5',
				boxShadow: 'none',
			},
			'& svg': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		},
		'&:focus, &:hover': {
			color: '#ffffff',
			backgroundColor: '#5f6060',
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: '#393e46',
			boxShadow: 'none',
		},
		'& svg': {
			width: 24,
			height: 24,
		},
	},
})(MUIIConButton);

export { CustomIconButton };
