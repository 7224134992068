import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { getSelectedLayerID, getSelectedSoilInvestigation } from '@/store/selectors';
import { BoreholeAsObject } from '@/store/types';
import classes from './index.module.css';
import { BoreholeSoilLayer } from '@/proto/build/borehole_pb';
import { dateTimestampToString, getTimeStamp } from '@/helpers';


const getTemplateFromDescription = (layer: BoreholeSoilLayer.AsObject) => {
	const { description } = layer || {} as BoreholeSoilLayer.AsObject;
	const { template, codesList } = description || {};

	let formattedTemplate = template;

	codesList?.forEach((code) => {
		const target = `{${code.index}}`;
		const newValue = code.description ? code.description : code.code;

		formattedTemplate = formattedTemplate?.replace(target, newValue);
	});

	return formattedTemplate;
};

const getRangeOfItems = (layers: Record<string, BoreholeSoilLayer.AsObject>, selectedLayerId: string) => {
	const range = 5;
	const layerArray =  Object.values(layers);
	const foundIndex = layerArray.findIndex((layer) => layer.id === +selectedLayerId);

	// You need constraints if index - 5 is < 0
	const startIndex = Math.max(0, foundIndex - range);
	const endIndex = foundIndex + range;

	return layerArray.slice(startIndex, endIndex);
};

const BoreholeSideBarHandler = (): JSX.Element => {
	const {
		groundWaterLevel,
		groundWaterLevelHighestAverage,
		groundWaterLevelLowestAverage,
		layersList,
		date,
	} = useSelector(getSelectedSoilInvestigation) as BoreholeAsObject || {};
	const { id: layerId } = useSelector(getSelectedLayerID);
	const formattedDate = dateTimestampToString(getTimeStamp(date)) || '-';

	const getGroundWaterLevelText = (level: number): string => {
		const text = 'Grondwaterstand (éénmalige opname';
		if (level === -999) {
			return `${text}): -`;
		} else {
			return `${text}, ${formattedDate}) : ${groundWaterLevel.toFixed(3)}`;
		}
	};

	const getAverageGroundWaterLevelText = (level: number, lowOrHigh: string): string => {
		const text = `Gemiddeld ${lowOrHigh} Grondwaterstand (indicatieve waarde o.b.v. glijkenmerken`;
		const levelForText = (lowOrHigh === 'hoge' ? groundWaterLevelHighestAverage : groundWaterLevelLowestAverage).toFixed(3);
		if (level === -999) {
			return `${text}): -`;
		} else {
			return `${text}, ${formattedDate}): ${levelForText}`;
		}
	};

	if (!layersList) return <span>Loading...</span>;
	return (
		<div className={classes.boreholeSidebar}>
			<div className={classes.groundWaterlevel}>
				<Typography variant="h5">Grondwaterstand</Typography>
				<Divider className={classes.divider}/>
				<Typography
					variant="body1"
					style={{ marginBottom: 10 }}>
					{getGroundWaterLevelText(groundWaterLevel)}
				</Typography>
				<Typography variant="body1" style={{ marginBottom: 10 }}>
					{getAverageGroundWaterLevelText(groundWaterLevelHighestAverage, 'hoge')}
				</Typography>
				<Typography variant="body1" style={{ marginBottom: 10 }}>
					{getAverageGroundWaterLevelText(groundWaterLevelLowestAverage, 'lage')}
				</Typography>
			</div>
			<Typography variant="h5">Bodembeschrijving</Typography>
			<Divider className={classes.divider}/>
			<List className={classes.soilList}>
				{getRangeOfItems(layersList, layerId).map((layer) => {
					const { depthFromTop, height, name, id } = layer;
					const template = getTemplateFromDescription(layer);
					const isSelected = id.toString() === layerId;
					return <ListItem
						key={`layer_${id}`}
						className={classes.soilItem}
						style={{ backgroundColor: isSelected ? '#2e2f30' : '#999' }}>
						{depthFromTop.toFixed(2)} tot {(depthFromTop - height).toFixed(2)}: {name}{template && `, ${template}`}
					</ListItem>;
				})}
			</List>
		</div>
	);
};

export default BoreholeSideBarHandler;
