/**
 * @fileoverview gRPC-Web generated client stub for com.royalhaskoningdhv.mondriaan.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.10
// source: project.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var cpt_pb = require('./cpt_pb.js')

var soilInvestigation_pb = require('./soilInvestigation_pb.js')

var interpretation_pb = require('./interpretation_pb.js')

var soil_pb = require('./soil_pb.js')
const proto = {};
proto.com = {};
proto.com.royalhaskoningdhv = {};
proto.com.royalhaskoningdhv.mondriaan = {};
proto.com.royalhaskoningdhv.mondriaan.proto = require('./project_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData,
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult>}
 */
const methodDescriptor_ProjectService_create = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/create',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData,
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult>}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.create =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/create',
      request,
      metadata || {},
      methodDescriptor_ProjectService_create);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult>}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/create',
      request,
      metadata || {},
      methodDescriptor_ProjectService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ProjectService_update = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/update',
  grpc.web.MethodType.UNARY,
  proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/update',
      request,
      metadata || {},
      methodDescriptor_ProjectService_update,
      callback);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/update',
      request,
      metadata || {},
      methodDescriptor_ProjectService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject,
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult>}
 */
const methodDescriptor_ProjectService_importSoilInvestigationToProject = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/importSoilInvestigationToProject',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject,
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult>}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.importSoilInvestigationToProject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/importSoilInvestigationToProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_importSoilInvestigationToProject);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult>}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.importSoilInvestigationToProject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/importSoilInvestigationToProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_importSoilInvestigationToProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_ProjectService_checkUniqueProjectName = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/checkUniqueProjectName',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.checkUniqueProjectName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/checkUniqueProjectName',
      request,
      metadata || {},
      methodDescriptor_ProjectService_checkUniqueProjectName,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.checkUniqueProjectName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/checkUniqueProjectName',
      request,
      metadata || {},
      methodDescriptor_ProjectService_checkUniqueProjectName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects>}
 */
const methodDescriptor_ProjectService_getAllProjects = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/getAllProjects',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.getAllProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/getAllProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectService_getAllProjects,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.getAllProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/getAllProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectService_getAllProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport,
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV>}
 */
const methodDescriptor_ProjectService_exportToOpenGroundCSV = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/exportToOpenGroundCSV',
  grpc.web.MethodType.UNARY,
  proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport,
  proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.exportToOpenGroundCSV =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/exportToOpenGroundCSV',
      request,
      metadata || {},
      methodDescriptor_ProjectService_exportToOpenGroundCSV,
      callback);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.exportToOpenGroundCSV =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/exportToOpenGroundCSV',
      request,
      metadata || {},
      methodDescriptor_ProjectService_exportToOpenGroundCSV);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int64Value,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ProjectService_delete = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/delete',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.Int64Value,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Int64Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int64Value} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/delete',
      request,
      metadata || {},
      methodDescriptor_ProjectService_delete,
      callback);
};


/**
 * @param {!proto.google.protobuf.Int64Value} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.ProjectService/delete',
      request,
      metadata || {},
      methodDescriptor_ProjectService_delete);
};


module.exports = proto.com.royalhaskoningdhv.mondriaan.proto;

