import React, { FC, forwardRef, ReactElement, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, Label } from 'recharts';
import CustomTooltip from '@/components/Interpretation/CustomTooltip';
import { HoverProps } from '@/models/types';
import { replaceHyphensWithMinus } from '@/helpers';
import { GraphProps } from '@/models/types/graphs';
import CustomDot from '../../CustomDot';
import GraphSkeleton from '../GraphSkeleton';
import classes from './index.module.css';

const WaterPressureGraph: FC<GraphProps> = forwardRef<HTMLElement, GraphProps>((props, ref) => {
	const [isActive, setIsActive] = useState(false);
	const { cpt, computedMeasurements, xAxisHeight, rightMargin, visibility, children } = props;
	const {
		graphWidth,
		graphHeight,
		gridVerticalPoints,
		gridHorizontalPoints,
		lastMeasurementRounded,
		finalXTick,
		yAxisWidth,
	} = computedMeasurements;

	const recordList = Object.values(cpt.recordsList).map((item) => {
		const { porePressure, ...rest } = item;
		return {
			...rest,
			depthBasedOnZValue: item.depthBasedOnZValue,
			porePressure: porePressure?.value,
		};
	});

	return (
		<div className={classes.container}>
			<span className={classes.purpleBar} ref={ref} />
			<div className={classes.headerContainer}>
				<span className={classes.header}>
					u<sub>2</sub> [MPa]
				</span>
			</div>
			{visibility ? (
				<LineChart
					layout="vertical"
					width={graphWidth}
					height={graphHeight}
					data={recordList}
					syncId={cpt.id}
					margin={{
						top: 0,
						right: rightMargin,
						left: yAxisWidth,
						bottom: 0,
					}}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onMouseMove={(_: any): void => {
						if (!isActive) {
							setIsActive(true);
						}
					}}
					onMouseLeave={(): void => {
						if (isActive) {
							setIsActive(false);
						}
					}}
				>
					<CartesianGrid
						verticalPoints={gridVerticalPoints}
						horizontalPoints={gridHorizontalPoints}
						y={xAxisHeight}
						stroke="#dddddd"
					/>
					<XAxis
						type="number"
						domain={[-0.2, (finalXTick - 2) / 10]}
						orientation="top"
						allowDecimals={true}
						allowDataOverflow
						interval="preserveStartEnd"
						axisLine={false}
						tickLine={false}
						tickCount={finalXTick / 2 + 1}
					/>
					<YAxis
						type="number"
						width={yAxisWidth}
						domain={[lastMeasurementRounded, Math.ceil(cpt.z)]}
						dataKey="depthBasedOnZValue"
						reversed
						hide
					/>
					<Tooltip
						wrapperStyle={{ zIndex: 999 }}
						isAnimationActive={false}
						content={<CustomTooltip shouldDisplay={isActive} />}
					/>
					<Line
						type="monotoneY"
						dataKey="porePressure"
						stroke="#5651f7"
						dot={false}
						activeDot={(props: HoverProps): ReactElement<SVGElement> =>
							((props.value ?
								<CustomDot cx={props.cx} cy={props.cy} r={props.r} /> : null) as unknown) as ReactElement<SVGElement, string>
						}
					/>
					<ReferenceArea x1={-0.2} x2={0.3} y1={cpt.z} y2={cpt.z - 0.00001} stroke="#555555">
						<Label position="left">
							{replaceHyphensWithMinus(cpt.z.toLocaleString(undefined, { maximumFractionDigits: 2 }))}
						</Label>
					</ReferenceArea>
					{children}
				</LineChart>
			) : (
				<GraphSkeleton width={graphWidth} height={graphHeight} />
			)}
		</div>
	);
});

export default React.memo(WaterPressureGraph);
