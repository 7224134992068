import React, { KeyboardEvent, ChangeEvent, FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { getSelectedSoilInvestigation, getSelectedSoilInvestigationSoilLayer } from '@/store/selectors';
import { updateSoilLayer } from '@/store/actions';
import classes from './index.module.css';
import { SoilLayer } from '@/proto/build/cpt_pb';
import { CPTAsObject } from '@/store/types';

const round = (number: number): string => (!isNaN(number) ? (Math.round((number + Number.EPSILON) * 100) / 100).toString() : '');

const LayerInputFields: FC = (): JSX.Element | null => {
	const dispatch = useDispatch();
	const soilInvestigation = useSelector(getSelectedSoilInvestigation) as CPTAsObject;
	const selectedLayer = useSelector(getSelectedSoilInvestigationSoilLayer) as SoilLayer.AsObject;
	const [position, setPosition] = useState<{ top?: string; bottom: string }>({
		top: selectedLayer?.depthTop?.toFixed(2) || '',
		bottom: selectedLayer?.depthTop && selectedLayer?.depthBase ? (selectedLayer?.depthTop - selectedLayer?.depthBase).toFixed(2) : '',
	});

	useEffect(() => {
		if (selectedLayer) {
			setPosition({ top: round(selectedLayer.depthTop), bottom: round(selectedLayer.depthTop - selectedLayer.depthBase) });
		}
	}, [setPosition, selectedLayer]);

	const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			(event.target as HTMLInputElement).blur();
		}
	};

	const onTopChange = (event: ChangeEvent<HTMLInputElement>): void => setPosition({ top: event.target.value, bottom: position.bottom });
	const onBottomChange = (event: ChangeEvent<HTMLInputElement>): void => setPosition({ top: position.top, bottom: event.target.value });

	const changeLayer = (): void => {
		const top = Number(position.top);
		const bottom = Number(position.bottom);

		if (selectedLayer && !isNaN(top) && !isNaN(bottom)) {
			const payload = {
				soilInvestigationId: soilInvestigation.id.toString(),
				layer: {
					...selectedLayer,
					depthTop: top < bottom ? bottom : top,
					depthBase: Math.abs(top - bottom),
				},
			};
			dispatch(updateSoilLayer(payload));
		}
	};

	return (
		<div className={classes.topBottom}>
			<h2 className={classes.header}>DEPTH COMPARED TO NAP</h2>
			<div className={classes.fields}>
				<OutlinedTextField
					value={position.top}
					className={classes.textField}
					label="Top"
					onChange={onTopChange}
					onBlur={changeLayer}
					onKeyPress={onKeyPress}
					type="number"
					inputProps={{ step: 0.1, lang: navigator.language }}
					disabled={round(soilInvestigation?.z) === position.top || !selectedLayer}
				/>
				<OutlinedTextField
					value={position.bottom}
					className={classes.textField}
					label="Bottom"
					onChange={onBottomChange}
					onBlur={changeLayer}
					onKeyPress={onKeyPress}
					type="number"
					inputProps={{ step: 0.1, lang: navigator.language }}
					disabled={!selectedLayer}
				/>
			</div>
		</div>
	);
};

export default LayerInputFields;
