/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { select } from 'redux-saga/effects';
import { LngLatBounds } from 'mapbox-gl';
import * as actions from '@/store/actions';
import { getProjects, getSelectedSoilInvestigation, getSelectedProject } from '@/store/selectors';
import { Interpretation } from '@/proto/build/cpt_pb';
import { CPTAsObject, MapViewCoords, Project, ProjectInformation } from '@/store/types';
import { getUrlPathName } from '@/store/selectors/router';
import { projectSelected } from '@/routes';
import { updateInterpretationCmd } from '@/net';
import mondriaanApi from '@/net/facade/mondriaanApi';
import { AllProjects } from '@/proto/build/project_pb';
import { GrpcError } from '@/models/classes';
import { lngLatBoundsToMapViewCoords } from '@/helpers';
import { SimpleSoilInvestigation } from '@/proto/build/soilInvestigation_pb';

function buildSearchStringFromMapViewCoords(coords: MapViewCoords): string {
	if (!coords) return '';
	return `west=${coords.west}&south=${coords.south}&east=${coords.east}&north=${coords.north}`;
}

// This task is run once when the app is loaded and can restore the current project id
// and map boundary
export function* restoreProjectLocation() {
	try {

		// Get last project from local storage in case there's no project in the URL
		const path: string = yield select(getUrlPathName);
		const projectRouteMatch = projectSelected.matchPath(path);
		const projectId = projectRouteMatch?.params.id || null;
		if (projectId === null) {
			const projectInformation = getLocalStorageInfo();

			const projectPath = projectInformation.lastSeenProjectId ? `/projects/${projectInformation.lastSeenProjectId}` : '';
			const mapLocation = projectInformation.lastSeenBoundary ?
				`?${buildSearchStringFromMapViewCoords(projectInformation.lastSeenBoundary)}` :
				'';

			// Setting selected project is handled by the router
			yield put(push(`${projectPath}${mapLocation}`));
		}
	} catch (err) {
		console.error('Failure restoring project location', err);
	}
}

export function* storeMapBounds(action: ReturnType<typeof actions.updateMapBounds>) {
	try {
		if (action.payload === null) return;

		const projectPath = action.payload.projectId ? `/projects/${action.payload.projectId}` : '';
		const mapLocation = action.payload.view ? `?${buildSearchStringFromMapViewCoords(action.payload.view)}` : '';

		yield put(push(`${projectPath}${mapLocation}`));

		setMapBoundsOnLocalStorage(action.payload.view);
	} catch (e) {
		console.error(e);
	}
}

export function* fetchAllProjects() {
	try {
		const result: AllProjects.AsObject = yield mondriaanApi.getAllProjects();
		yield put(actions.addMultipleProjects(result.projectsList));
		yield put(actions.setSoils(result.projectsList.flatMap((project) => project.customSoilLibrary?.soilsList || [])));
	} catch (e) {
		yield put(actions.updateRetrievingProjectsFailed(true));
		throw new Error(`Cannot fetch projects: ${e}.`);
	}
}

export function* updateInterpretation() {
	try {
		const selectedSoilInvestigation: CPTAsObject = yield select(getSelectedSoilInvestigation);
		const selectedProject: Project = yield select(getSelectedProject);

		const payload: Interpretation.AsObject = {
			cptId: selectedSoilInvestigation.id,
			soilLayersList: Object.values(selectedSoilInvestigation.soilLayersList),
		};

		yield updateInterpretationCmd(payload);

		yield put(actions.updateSoilInterpretationSuccess({ project: selectedProject, soilInvestigationId: selectedSoilInvestigation.id }));

	} catch (e) {
		yield put(actions.updateSoilInterpretationFailed(e as GrpcError));
	}
}

/**
 * Every time a project is selected we calculate its bounds
 */
export function* setProjectActive({ payload }: ReturnType<typeof actions.setSelectedProject>) {
	try {

		if (!payload) return;

		const id = typeof payload === 'number' ? payload : payload.id;


		setProjectIdOnLocalStorage(id);

		const bounds = new LngLatBounds();
		const projectList: Record<string, Project> = yield select(getProjects);
		const project = payload ? projectList[id] : undefined;

		if (!project) {
			return;
		}

		const { cptsList, boreholesList } = project;

		// Are bounds set for current project?
		[...cptsList, ...boreholesList].forEach((soilInvestigation: SimpleSoilInvestigation.AsObject) => {
			const { position } = soilInvestigation;
			if (position && (typeof payload !== 'number' && payload.zoomToProject)) {
				bounds.extend([position.longitude, position.latitude]);
			}
		});

		if (!bounds.isEmpty()) {
			const convertedBounds = lngLatBoundsToMapViewCoords(bounds);
			yield put(push(`/projects/${id}?${buildSearchStringFromMapViewCoords(convertedBounds)}`));
		} else {
			yield put(push(`/projects/${id}`));
		}

	} catch (e) {
		console.error(e);
	}
}

// Inside the local storage key "mondriaan" we find a dictionary where the key is the "Project id" and the value is a Project Location
// It can be extended later on to hold other values
const localStorageKey = 'mondriaan';
function getLocalStorageInfo(): ProjectInformation {
	const inStore = localStorage.getItem(localStorageKey);
	return inStore == null ? {} : JSON.parse(inStore);
}

function setProjectIdOnLocalStorage(projectId: number): void {
	const current = getLocalStorageInfo();
	current.lastSeenProjectId = projectId;
	localStorage.setItem(localStorageKey, JSON.stringify(current));
}

function setMapBoundsOnLocalStorage(mapBounds: MapViewCoords): void {
	const current = getLocalStorageInfo();
	current.lastSeenBoundary = mapBounds;
	localStorage.setItem(localStorageKey, JSON.stringify(current));
}
