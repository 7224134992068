import React, { Fragment, useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import ReactDOM from 'react-dom';
import { Int64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { deleteProjectFailed, deleteProjectSuccess, setSearchTerm, setSelectedProject } from '@/store/actions';
import {
	getProjects,
	getSelectedProject,
	getSelectedSoilInvestigationIds,
	getSearchTerm,
} from '@/store/selectors';
import ProjectFiles from '@/components/MapSideBar/ProjectFiles';
import ProjectOptions from '@/components/MapSideBar/ProjectOptions';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog';
import { CustomIconButton } from '@/components/custom/iconButtons';
import { deleteProjectCmd } from '@/net';
import ProjectInformation from './ProjectInformation';
import { ProjectEdit } from './ProjectEdit';
import SearchBar from './SearchBar';
import ProjectList from './ProjectList';
import classes from './index.module.css';
import { useFilesUtils } from '@/hooks/useFilesUtils';
import UnactiveSelectedFiles from './UnactiveSelectedFiles';

interface Props {
	yellowBannerIsActive: boolean;
}

const MapSideBar: FC<Props> = ({ yellowBannerIsActive }): JSX.Element => {
	const dispatch = useDispatch();
	const projects = useSelector(getProjects);
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const selectedProject = useSelector(getSelectedProject);
	const searchTerm = useSelector(getSearchTerm);
	const [showProjectOptions, setShowProjectOptions] = useState<boolean>(false);
	const [showEditSideBar, setShowEditSideBar] = React.useState<boolean>(false);
	const [showDeleteProjectConfirmation, setShowDeleteProjectConfirmation] =  useState<boolean>(false);
	const {
		handleOnClick,
		handleRightClick,
		handleDoubleCLick,
	 } = useFilesUtils();

	useEffect(() => {
		if (!selectedProject) {
			setShowEditSideBar(false);
		}
	}, [selectedProject]);

	const sortedProjects = Object.values(projects)
		.sort((a, b) => (b.createdAt?.seconds ?? 0) - (a.createdAt?.seconds ?? 0))
		.filter((project) =>
			(project.name && project.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
				(project.abwReference && project.abwReference.toLowerCase().includes(searchTerm.toLowerCase())),
		);

	const rootDocument = document.getElementById('root') || new Element();

	const handleSearchFieldChange = (text: string): void => {
		dispatch(setSearchTerm(text));
	};

	const closeProjectOptions = (): void => {
		setShowProjectOptions(false);
	};

	const handleProjectOptions = (): void => {
		setShowProjectOptions(!showProjectOptions);
	};

	const handleProjectEdit = (): void => {
		setShowEditSideBar(!showEditSideBar);
		closeProjectOptions();
	};

	const confirmDeleteProjectDialog = (): void => {
		setShowDeleteProjectConfirmation(true);
	};

	const handleConfirmDeleteProjectClick = (): void => {
		const projectId = selectedProject?.id as number;

		deleteProjectCmd(new Int64Value().setValue(projectId))
			.then(() => {
				dispatch(deleteProjectSuccess(projectId));
				dispatch(setSelectedProject(undefined));
			})
			.catch((e) => dispatch(deleteProjectFailed({ projectId, msg: e })));

		closeOptionsAndConfirmation();
	};

	const closeOptionsAndConfirmation = (): void => {
		setShowProjectOptions(false);
		setShowDeleteProjectConfirmation(false);
	};

	return (
		<aside className={classNames(
			classes.sideBar,
			{ [classes.sideBarPushed]: yellowBannerIsActive },
		)}>
			{showEditSideBar && selectedProject ? (
				<ProjectEdit project={selectedProject} closeEdit={handleProjectEdit} />
			) : (
				<Fragment>
					<div className={classes.searchBarTreeDotsWrapper}>
						<SearchBar setSearchTerm={handleSearchFieldChange} fullWidth={!selectedProject} />
						{selectedProject &&
							<CustomIconButton onClick={handleProjectOptions} classes={{ root: classes.projectOptionsButton }}>
								<MoreHorizRoundedIcon />
							</CustomIconButton>
						}
						{selectedProject && showProjectOptions && (
							<ProjectOptions
								handleClose={closeProjectOptions}
								projectId={selectedProject.id}
								projectName={selectedProject.name}
								interpretationMethod={selectedProject.interpretationMethod}
								selectedSoilInvestigationIds={selectedSoilInvestigationIds}
								handleEdit={handleProjectEdit}
								handleDeleteProject={confirmDeleteProjectDialog}
							/>
						)}
					</div>
					{
						!selectedProject ? (
							<Fragment>
								<ProjectList sortedProjects={sortedProjects}/>
								<UnactiveSelectedFiles
									onClick={(event, id): void => handleOnClick(event, id)}
									onDoubleClick={(event, id): void => handleDoubleCLick(event, id)}
									onContextMenu={(event, status): void => handleRightClick(event, status)}
								/>
							</Fragment>
						) : (
							<Fragment>
								<ProjectInformation project={selectedProject} />
								<Divider variant="fullWidth" className={classes.divider} />
								<ProjectFiles project={selectedProject}/>
							</Fragment>
						)
					}
				</Fragment>
			)}
			{
				ReactDOM.createPortal(
					<ConfirmationDialog
						onCancel={(): void => {
							closeOptionsAndConfirmation();
						}}
						onConfirm={handleConfirmDeleteProjectClick}
						visible={showDeleteProjectConfirmation}
						title="Confirmation request"
						main="You are going to permanently delete the current project."
						cancelButtonLabel="Cancel"
						confirmButtonLabel="Delete Project"
					>
						<p className={classes.warningMsg}>This action cannot be reversed.</p>
					</ConfirmationDialog>,
					rootDocument)
			}
		</aside>
	);
};

export default MapSideBar;
