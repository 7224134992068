import React, { FC, ReactNode } from 'react';
import { TextButtonNeutral } from '@/components/custom/buttons';
import classes from './index.module.css';

interface Props {
	title: string;
	main: string;
	visible: boolean;
	onHide: () => void;
	children?: ReactNode;
}

const SimpleErrorDialog: FC<Props> = ({ title, main, visible, onHide, children }) => {
	if (!visible)
		return null;

	return (
		<div className={classes.box}>
			<p className={classes.title}>{title}</p>
			<div id="content" className={classes.content}>
				<p id="main" className={classes.main}>{main}</p>
				{children}
			</div>
			<div id="footer" className={classes.footer}>
				<TextButtonNeutral className={classes.removeBtn} onClick={onHide}>
					CLOSE
				</TextButtonNeutral>
			</div>
		</div>
	);
};

export default SimpleErrorDialog;
