import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';

const initialState: SoilInvestigationId.AsObject[] = [];

export default createReducer(initialState, {

	[actions.setSelectedSoilInvestigationIds.type]: (draft, { payload }): SoilInvestigationId.AsObject[] => payload,

	[actions.deleteSoilInvestigationsSuccess.type]: (
		draft, action: ReturnType<typeof actions.deleteSoilInvestigationsSuccess>): SoilInvestigationId.AsObject[] => {
		// Remove selected Soil Investigation that were deleted
		return draft.filter((soilInvestigationId) => !action.payload.soilInvestigationIds.some(
			(s) => s.soilInvestigationId === soilInvestigationId.soilInvestigationId && s.type === soilInvestigationId.type));
	},
});
