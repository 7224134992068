// source: project.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var cpt_pb = require('./cpt_pb.js');
goog.object.extend(proto, cpt_pb);
var soilInvestigation_pb = require('./soilInvestigation_pb.js');
goog.object.extend(proto, soilInvestigation_pb);
var interpretation_pb = require('./interpretation_pb.js');
goog.object.extend(proto, interpretation_pb);
var soil_pb = require('./soil_pb.js');
goog.object.extend(proto, soil_pb);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.ProjectOrProgressCase', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.ProjectOrProgressCase', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.Project', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.Project.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.Project.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.Project';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.oneofGroups_);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abwReference: jspb.Message.getFieldWithDefault(msg, 3, ""),
    interpretationMethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minimumLayerThickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    waterLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    soilInvestigationsList: jspb.Message.toObjectList(msg.getSoilInvestigationsList(),
    cpt_pb.UploadFileChunk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbwReference(value);
      break;
    case 4:
      var value = /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} */ (reader.readEnum());
      msg.setInterpretationMethod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinimumLayerThickness(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaterLevel(value);
      break;
    case 6:
      var value = new cpt_pb.UploadFileChunk;
      reader.readMessage(value,cpt_pb.UploadFileChunk.deserializeBinaryFromReader);
      msg.addSoilInvestigations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbwReference();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInterpretationMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMinimumLayerThickness();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getWaterLevel();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getSoilInvestigationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      cpt_pb.UploadFileChunk.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abw_reference = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getAbwReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setAbwReference = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InterpretationMethod interpretation_method = 4;
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getInterpretationMethod = function() {
  return /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setInterpretationMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional float minimum_layer_thickness = 5;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getMinimumLayerThickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setMinimumLayerThickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float water_level = 7;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getWaterLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setWaterLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated UploadFileChunk soil_investigations = 6;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.getSoilInvestigationsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk>} */ (
    jspb.Message.getRepeatedWrapperField(this, cpt_pb.UploadFileChunk, 6));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.setSoilInvestigationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.addSoilInvestigations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectData.prototype.clearSoilInvestigationsList = function() {
  return this.setSoilInvestigationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    abwReference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interpretationMethod: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minimumLayerThickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    waterLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData;
  return proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbwReference(value);
      break;
    case 5:
      var value = /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} */ (reader.readEnum());
      msg.setInterpretationMethod(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinimumLayerThickness(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaterLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAbwReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterpretationMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMinimumLayerThickness();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getWaterLevel();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string abw_reference = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getAbwReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setAbwReference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional InterpretationMethod interpretation_method = 5;
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getInterpretationMethod = function() {
  return /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setInterpretationMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float minimum_layer_thickness = 6;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getMinimumLayerThickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setMinimumLayerThickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float water_level = 7;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.getWaterLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateProjectData.prototype.setWaterLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.ProjectOrProgressCase = {
  PROJECT_OR_PROGRESS_NOT_SET: 0,
  PROJECT_WITH_ERRORS: 1,
  PROGRESS: 2,
  CREATE_PROJECT_FAILED: 3
};

/**
 * @return {proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.ProjectOrProgressCase}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.getProjectOrProgressCase = function() {
  return /** @type {proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.ProjectOrProgressCase} */(jspb.Message.computeOneofCase(this, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectWithErrors: (f = msg.getProjectWithErrors()) && proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.toObject(includeInstance, f),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    createProjectFailed: (f = msg.getCreateProjectFailed()) && proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.deserializeBinaryFromReader);
      msg.setProjectWithErrors(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    case 3:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.deserializeBinaryFromReader);
      msg.setCreateProjectFailed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectWithErrors();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getCreateProjectFailed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectWithErrors project_with_errors = 1;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.getProjectWithErrors = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors, 1));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.setProjectWithErrors = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.clearProjectWithErrors = function() {
  return this.setProjectWithErrors(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.hasProjectWithErrors = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float progress = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.setProgress = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.clearProgress = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CreateProjectFailed create_project_failed = 3;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.getCreateProjectFailed = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed, 3));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.setCreateProjectFailed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.clearCreateProjectFailed = function() {
  return this.setCreateProjectFailed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectResult.prototype.hasCreateProjectFailed = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed;
  return proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CreateProjectFailed.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && proto.com.royalhaskoningdhv.mondriaan.proto.Project.toObject(includeInstance, f),
    errorsMap: (f = msg.getErrorsMap()) ? f.toObject(includeInstance, proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.toObject) : [],
    mainImportErrorReason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors;
  return proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.Project;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = msg.getErrorsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.deserializeBinaryFromReader, "", new proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainImportErrorReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.Project.serializeBinaryToWriter
    );
  }
  f = message.getErrorsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.serializeBinaryToWriter);
  }
  f = message.getMainImportErrorReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.Project}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.getProject = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.Project} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.Project, 1));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.Project|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, ErrorList> errors = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.getErrorsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.clearErrorsMap = function() {
  this.getErrorsMap().clear();
  return this;
};


/**
 * optional string main_import_error_reason = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.getMainImportErrorReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.prototype.setMainImportErrorReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string errors = 1;
 * @return {!Array<string>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.prototype.getErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.prototype.setErrorsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.prototype.addErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ErrorList.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.repeatedFields_ = [8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    abwReference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interpretationMethod: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minimumLayerThickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    waterLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    cptsList: jspb.Message.toObjectList(msg.getCptsList(),
    soilInvestigation_pb.SimpleSoilInvestigation.toObject, includeInstance),
    boreholesList: jspb.Message.toObjectList(msg.getBoreholesList(),
    soilInvestigation_pb.SimpleSoilInvestigation.toObject, includeInstance),
    customSoilLibrary: (f = msg.getCustomSoilLibrary()) && soil_pb.SoilLibrary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.Project;
  return proto.com.royalhaskoningdhv.mondriaan.proto.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbwReference(value);
      break;
    case 5:
      var value = /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} */ (reader.readEnum());
      msg.setInterpretationMethod(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinimumLayerThickness(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaterLevel(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new soilInvestigation_pb.SimpleSoilInvestigation;
      reader.readMessage(value,soilInvestigation_pb.SimpleSoilInvestigation.deserializeBinaryFromReader);
      msg.addCpts(value);
      break;
    case 11:
      var value = new soilInvestigation_pb.SimpleSoilInvestigation;
      reader.readMessage(value,soilInvestigation_pb.SimpleSoilInvestigation.deserializeBinaryFromReader);
      msg.addBoreholes(value);
      break;
    case 9:
      var value = new soil_pb.SoilLibrary;
      reader.readMessage(value,soil_pb.SoilLibrary.deserializeBinaryFromReader);
      msg.setCustomSoilLibrary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAbwReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterpretationMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMinimumLayerThickness();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getWaterLevel();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      soilInvestigation_pb.SimpleSoilInvestigation.serializeBinaryToWriter
    );
  }
  f = message.getBoreholesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      soilInvestigation_pb.SimpleSoilInvestigation.serializeBinaryToWriter
    );
  }
  f = message.getCustomSoilLibrary();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      soil_pb.SoilLibrary.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string abw_reference = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getAbwReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setAbwReference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional InterpretationMethod interpretation_method = 5;
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getInterpretationMethod = function() {
  return /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationMethod} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setInterpretationMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float minimum_layer_thickness = 6;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getMinimumLayerThickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setMinimumLayerThickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float water_level = 10;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getWaterLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setWaterLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated SimpleSoilInvestigation cpts = 8;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getCptsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, soilInvestigation_pb.SimpleSoilInvestigation, 8));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setCptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.addCpts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.clearCptsList = function() {
  return this.setCptsList([]);
};


/**
 * repeated SimpleSoilInvestigation boreholes = 11;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getBoreholesList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, soilInvestigation_pb.SimpleSoilInvestigation, 11));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setBoreholesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.addBoreholes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SimpleSoilInvestigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.clearBoreholesList = function() {
  return this.setBoreholesList([]);
};


/**
 * optional SoilLibrary custom_soil_library = 9;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.getCustomSoilLibrary = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} */ (
    jspb.Message.getWrapperField(this, soil_pb.SoilLibrary, 9));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.setCustomSoilLibrary = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.clearCustomSoilLibrary = function() {
  return this.setCustomSoilLibrary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Project.prototype.hasCustomSoilLibrary = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectsList: jspb.Message.toObjectList(msg.getProjectsList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.Project.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects;
  return proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.Project;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.Project.deserializeBinaryFromReader);
      msg.addProjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.Project.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Project projects = 1;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Project>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.prototype.getProjectsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Project>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.Project, 1));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Project>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.prototype.setProjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Project=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Project}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.prototype.addProjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.Project, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.AllProjects.prototype.clearProjectsList = function() {
  return this.setProjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    soilinvestigationsList: jspb.Message.toObjectList(msg.getSoilinvestigationsList(),
    cpt_pb.UploadFileChunk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject;
  return proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new cpt_pb.UploadFileChunk;
      reader.readMessage(value,cpt_pb.UploadFileChunk.deserializeBinaryFromReader);
      msg.addSoilinvestigations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSoilinvestigationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      cpt_pb.UploadFileChunk.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UploadFileChunk soilInvestigations = 2;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.getSoilinvestigationsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk>} */ (
    jspb.Message.getRepeatedWrapperField(this, cpt_pb.UploadFileChunk, 2));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.setSoilinvestigationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.addSoilinvestigations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.UploadFileChunk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProject.prototype.clearSoilinvestigationsList = function() {
  return this.setSoilinvestigationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.ProjectOrProgressCase = {
  PROJECT_OR_PROGRESS_NOT_SET: 0,
  PROJECT_WITH_ERRORS: 1,
  PROGRESS: 2
};

/**
 * @return {proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.ProjectOrProgressCase}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.getProjectOrProgressCase = function() {
  return /** @type {proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.ProjectOrProgressCase} */(jspb.Message.computeOneofCase(this, proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectWithErrors: (f = msg.getProjectWithErrors()) && proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.toObject(includeInstance, f),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult;
  return proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.deserializeBinaryFromReader);
      msg.setProjectWithErrors(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectWithErrors();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional ProjectWithErrors project_with_errors = 1;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.getProjectWithErrors = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors, 1));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.ProjectWithErrors|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.setProjectWithErrors = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.clearProjectWithErrors = function() {
  return this.setProjectWithErrors(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.hasProjectWithErrors = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float progress = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.setProgress = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.clearProgress = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.ImportSoilInvestigationToProjectResult.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: msg.getContent_asB64(),
    zipFileName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV;
  return proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getZipFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes content = 1;
 * @return {!(string|Uint8Array)}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes content = 1;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string zip_file_name = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.getZipFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.OpenGroundZippedCSV.prototype.setZipFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cptidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    boreholeidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport;
  return proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCptids(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBoreholeids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCptidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getBoreholeidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 projectId = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.getProjectid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 cptIds = 2;
 * @return {!Array<number>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.getCptidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.setCptidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.addCptids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.clearCptidsList = function() {
  return this.setCptidsList([]);
};


/**
 * repeated int64 boreholeIds = 3;
 * @return {!Array<number>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.getBoreholeidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.setBoreholeidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.addBoreholeids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.InterpretationsToExport.prototype.clearBoreholeidsList = function() {
  return this.setBoreholeidsList([]);
};


goog.object.extend(exports, proto.com.royalhaskoningdhv.mondriaan.proto);
