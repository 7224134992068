export default ((): void => {
	if (!('path' in Event.prototype)) {
		Object.defineProperty(Event.prototype, 'path', {
			get: function () {
				const path = [];
				let currentElem: HTMLElement | null = this.target as HTMLElement;
				while (currentElem) {
					path.push(currentElem);
					currentElem = currentElem.parentElement;
				}
				return path;
			},
		});
	}
})();
