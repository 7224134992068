// source: soil.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.Soil', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilPattern', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue', null, global);
goog.exportSymbol('proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.repeatedFields_, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.Soil, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.Soil.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.Soil';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.displayName = 'proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    librariesList: jspb.Message.toObjectList(msg.getLibrariesList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.toObject, includeInstance),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.deserializeBinaryFromReader);
      msg.addLibraries(value);
      break;
    case 2:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLibrariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SoilLibrary libraries = 1;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.getLibrariesList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary, 1));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.setLibrariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.addLibraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.clearLibrariesList = function() {
  return this.setLibrariesList([]);
};


/**
 * repeated SoilCategory categories = 2;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory, 2));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibraryAndCategoryList.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pattern: (f = msg.getPattern()) && proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.toObject(includeInstance, f),
    soilsList: jspb.Message.toObjectList(msg.getSoilsList(),
    proto.com.royalhaskoningdhv.mondriaan.proto.Soil.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.deserializeBinaryFromReader);
      msg.setPattern(value);
      break;
    case 4:
      var value = new proto.com.royalhaskoningdhv.mondriaan.proto.Soil;
      reader.readMessage(value,proto.com.royalhaskoningdhv.mondriaan.proto.Soil.deserializeBinaryFromReader);
      msg.addSoils(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPattern();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.serializeBinaryToWriter
    );
  }
  f = message.getSoilsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.royalhaskoningdhv.mondriaan.proto.Soil.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SoilPatternValue pattern = 3;
 * @return {?proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.getPattern = function() {
  return /** @type{?proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue} */ (
    jspb.Message.getWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue, 3));
};


/**
 * @param {?proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.setPattern = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.clearPattern = function() {
  return this.setPattern(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.hasPattern = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Soil soils = 4;
 * @return {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Soil>}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.getSoilsList = function() {
  return /** @type{!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Soil>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.royalhaskoningdhv.mondriaan.proto.Soil, 4));
};


/**
 * @param {!Array<!proto.com.royalhaskoningdhv.mondriaan.proto.Soil>} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.setSoilsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.addSoils = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.royalhaskoningdhv.mondriaan.proto.Soil, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilLibrary.prototype.clearSoilsList = function() {
  return this.setSoilsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilCategory.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.Soil.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: (f = msg.getProjectId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    soilLibraryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cohesion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    frictionAngle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.Soil;
  return proto.com.royalhaskoningdhv.mondriaan.proto.Soil.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSoilLibraryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohesion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrictionAngle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.Soil.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getCategoryId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSoilLibraryId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCohesion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrictionAngle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int64Value project_id = 8;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getProjectId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
*/
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setProjectId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.clearProjectId = function() {
  return this.setProjectId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.hasProjectId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 category_id = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 soil_library_id = 3;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getSoilLibraryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setSoilLibraryId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cohesion = 5;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getCohesion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setCohesion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string friction_angle = 6;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getFrictionAngle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setFrictionAngle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.Soil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.Soil.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cohesion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    frictionAngle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil;
  return proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohesion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrictionAngle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCohesion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrictionAngle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 project_id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 category_id = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cohesion = 5;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.getCohesion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.setCohesion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string friction_angle = 6;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.getFrictionAngle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.setFrictionAngle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.NewCustomSoil.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cohesion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    frictionAngle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData;
  return proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohesion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrictionAngle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCohesion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrictionAngle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 category_id = 2;
 * @return {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cohesion = 5;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.getCohesion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.setCohesion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string friction_angle = 6;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.getFrictionAngle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.setFrictionAngle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.UpdateCustomSoilData.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    pattern: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue;
  return proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPattern} */ (reader.readEnum());
      msg.setPattern(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPattern();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional SoilPattern pattern = 3;
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPattern}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.prototype.getPattern = function() {
  return /** @type {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPattern} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPattern} value
 * @return {!proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue} returns this
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPatternValue.prototype.setPattern = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.com.royalhaskoningdhv.mondriaan.proto.SoilPattern = {
  SQUARE: 0,
  PLUS: 1,
  DOT: 2,
  WAVE: 3
};

goog.object.extend(exports, proto.com.royalhaskoningdhv.mondriaan.proto);
