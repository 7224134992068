import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { dateTimestampToString, getTimeStamp, soilInvestigationIdWithTypeToList } from '@/helpers';
import { Project } from '@/store/types';
import {
	getConfirmDeletionSoilInvestigationDialogOpen,
	getSelectedSoilInvestigationIds,
} from '@/store/selectors';
import { setConfirmSoilInvestigationsDeletionDialogOpen } from '@/store/actions';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog';
import { deleteSoilInvestigationsCmd } from '@/net';
import { deleteSoilInvestigationsSuccess, deleteSoilInvestigationsFailed } from '@/store/actions/soilInvestigations';
import FilesSelectionMenu from '../FilesSelectionMenu';
import UnactiveSelectedFiles from '../UnactiveSelectedFiles';
import classes from './index.module.css';
import { useFilesUtils } from '@/hooks/useFilesUtils';
import { setSearchTerm } from '@/store/actions';

interface Props {
	project: Project;
}

export const ProjectFiles: FC<Props> = ({ project }) => {
	const dispatch = useDispatch();
	const selectedSoilInvestigationIds = useSelector(getSelectedSoilInvestigationIds);
	const {
		simpleSoilInvestigationList,
		mouseClickPos,
		handleOnClick,
		handleRightClick,
		handleDoubleCLick,
		handleMouseClickPosition,
	 } = useFilesUtils();

	useEffect(() => {
		dispatch(setSearchTerm(''));
	}, [dispatch]);

	const getDeleteSoilInvestigationConfirmDialogOpen = useSelector(getConfirmDeletionSoilInvestigationDialogOpen);

	const rootDocument = document.getElementById('root') || new Element();

	const confirmDeleteSoilInvestigationsDialog = (): void => {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(true));
	};

	const handleConfirmClick = (): void => {
		if (selectedSoilInvestigationIds === null) {
			console.error('Object with soil investigations to be deleted is empty!');
			return;
		}

		deleteSoilInvestigationsCmd(soilInvestigationIdWithTypeToList(selectedSoilInvestigationIds))
			.then(() => dispatch(deleteSoilInvestigationsSuccess({ projectId: project.id, soilInvestigationIds: selectedSoilInvestigationIds })))
			.catch((e) => dispatch(deleteSoilInvestigationsFailed(e)));

		resetDeleteSoilInvestigationDialog();
	};

	const handleCancelClick = (): void => {
		resetDeleteSoilInvestigationDialog();
	};

	function resetDeleteSoilInvestigationDialog(): void {
		dispatch(setConfirmSoilInvestigationsDeletionDialogOpen(false));
	}

	return (
		<div className={classes.projectFiles}>
			{!simpleSoilInvestigationList.length ? (
				<div className={classes.emptyText}>No matching results were found</div>
			) : (
				<span className={classes.header}>PROJECT FILES</span>
			)}
			{!!Object.values(simpleSoilInvestigationList).length && (
				<ul className={classes.cptList}>
					{Object.values(simpleSoilInvestigationList).map((soilInvestigation) => {
						const date = dateTimestampToString(getTimeStamp(soilInvestigation.date));
						const soilInvestigationIdAsObject = {
							soilInvestigationId: soilInvestigation.id,
							type: soilInvestigation.type,
						};
						const isSelected = selectedSoilInvestigationIds.some(
							(soilInvestigationId) =>
								soilInvestigationId.soilInvestigationId === soilInvestigation.id
								&& soilInvestigationId.type === soilInvestigation.type);
						return (
							<li
								key={`${soilInvestigation.type}_${soilInvestigation.id}`}
								className={classnames(classes.cptItem, { [classes.cptItemSelected]: isSelected })}
								onClick={(event): void => handleOnClick(event, soilInvestigationIdAsObject)}
								onDoubleClick={(event): void => handleDoubleCLick(event, soilInvestigationIdAsObject)}
								onContextMenu={(e): void => handleRightClick(e, isSelected)}
							>
								<span className={classes.cptNumber}>{soilInvestigation.testId}</span>
								<span className={classes.cptDate}>{date}</span>
							</li>
						);
					})}
				</ul>
			)}
			<UnactiveSelectedFiles
				onClick={(event, id): void => handleOnClick(event, id)}
				onDoubleClick={(event, id): void => handleDoubleCLick(event, id)}
				onContextMenu={(event, status): void => handleRightClick(event, status)}
			/>
			<FilesSelectionMenu
				handleClose={(): void => handleMouseClickPosition()}
				projectId={project.id}
				projectName={project.name}
				interpretationMethod={project.interpretationMethod}
				soilInvestigationIds={selectedSoilInvestigationIds}
				mousePosition={mouseClickPos}
				open={mouseClickPos?.mouseY !== null}
				handleDeleteSoilInvestigation={confirmDeleteSoilInvestigationsDialog}
			/>
			{ReactDOM.createPortal(
				<ConfirmationDialog
					onCancel={handleCancelClick}
					onConfirm={handleConfirmClick}
					visible={getDeleteSoilInvestigationConfirmDialogOpen}
					title="Confirmation request"
					main="You are going to permanently delete selected files."
					cancelButtonLabel="Cancel"
					confirmButtonLabel="Delete Files"
				>
					<p className={classes.warningMsg}>That action cannot be reversed.</p>
				</ConfirmationDialog>,
				rootDocument,
			)}
		</div>
	);
};

export default ProjectFiles;
