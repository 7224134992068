/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put } from 'redux-saga/effects';
import { SoilLibraryAndCategoryList } from '@/proto/build/soil_pb';
import { setSoilCategories, setSoilLibraries } from '@/store/actions';
import mondriaanApi from '@/net/facade/mondriaanApi';

export function* retrieveSoilLibrariesAndCategories() {
	const { librariesList, categoriesList }: SoilLibraryAndCategoryList.AsObject = yield call(mondriaanApi.getGlobalSoilLibraries);

	yield put(setSoilLibraries(librariesList));
	yield put(setSoilCategories(categoriesList));
}
