import {
	Middleware,
	MiddlewareAPI,
	Dispatch,
	Action,
} from 'redux';
import { generateMetricsBasedOnAction } from './metrics';

export function createMixPanelMiddleware(): Middleware {
	const metricsObserver: Middleware = ({ getState }: MiddlewareAPI) => (next: Dispatch) => (action: Action): unknown => {

		// Call the next dispatch method in the middleware chain.
		const result = next(action);

		generateMetricsBasedOnAction(action);

		return result;
	};

	return metricsObserver;
}
