import { withStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';

/* example
	<Tabs
		value={activeTab}
		onChange={handleTabChange}
		TabIndicatorProps={{
			style: {
				backgroundColor: '#01cebd',
			},
		}}
	>
		{[0, 1, 2].map((item) => {
			return <StyledTab className={classes.tab} key={`Tab_${item}`} label={`Tab ${item}`} value={item} />;
		})}
	</Tabs>
*/

const generalTabStyles: Partial<CSSProperties> = {
	fontSize: '14px',
	fontWeight: 600,
	lineHeight: '24.5px',
	letterSpacing: '0.5px',
	textAlign: 'center',
	textTransform: 'uppercase',
	backgroundColor: 'transparent',
};

const StyledTab = withStyles({
	root: {
		...generalTabStyles,
		color: '#ffffff',
		textTransform: 'uppercase',
		letterSpacing: '0.4px',
		borderBottom: '1px solid #393e46',
		opacity: 1,
		'&:hover, &:focus, &:active': {
			backgroundColor: '#393e46',
		},
		'&:active': {
			backgroundColor: '#515a66',
		},
		'&$selected': {
			color: '#01cebd',
		},
	},
	selected: {},
})(Tab);

export default StyledTab;
