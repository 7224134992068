import React, { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { InputAdornment } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Search from '@material-ui/icons/Search';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import { getSoilLibraries, getSoils } from '@/store/selectors/soil';
import { Soil, SoilPattern } from '@/proto/build/soil_pb';
import { CustomIconButton } from '@/components/custom/iconButtons';
import { CUSTOM_SOIL_LIBRARY_ID } from '@/models/constants';
import { SoilDialogState, SoilCategories } from '@/models/types';
import classes from './index.module.css';

interface Props {
	handleSoilDialog: (dialogStatus: SoilDialogState, soil?: Soil.AsObject) => void;
}

/* Dynamically include all soil layer images */
const imageContext = require.context('@/assets/images/patterns', false, /\.(svg)$/);

const SoilTypesTab: FC<Props> = ({ handleSoilDialog }): JSX.Element => {
	const [search, setSearch] = useState('');
	const soilMaterials = useSelector(getSoils);
	const soilLibraries = useSelector(getSoilLibraries);

	const getPatternUrl = (soilLibraryId: number): string | undefined => {
		const pattern = soilLibraries[soilLibraryId].pattern;
		if (pattern) {
			return imageContext(`./${Object.keys(SoilPattern)[pattern.pattern].toLowerCase()}.svg`);
		}
	};

	const isCustomSoil = (soilId: number): boolean => {
		return soilMaterials[soilId].soilLibraryId === CUSTOM_SOIL_LIBRARY_ID;
	};

	return (
		<div className={classes.root}>
			<div className={classes.searchContainer}>
				<OutlinedTextField
					value={search}
					className={classes.textField}
					onChange={(event): void => setSearch(event.target.value)}
					InputProps={{
						classes: {
							root: classes.searchRoot,
							input: classes.searchInput,
						},
						startAdornment: (
							<InputAdornment position="start" classes={{ root: classes.searchAdornment }}>
								<Search />
							</InputAdornment>
						),
					}}
				/>
				<CustomIconButton onClick={(): void => handleSoilDialog(SoilDialogState.NEW) }>
					<AddRoundedIcon />
				</CustomIconButton>
			</div>
			<div className={classes.scrollable}>
				{Object.values(soilLibraries).filter((library) => library.soilsList.length ).map((library) => {
					const soils = library.soilsList.filter((soilId) => soilMaterials[soilId]?.name?.toLowerCase().includes(search.toLowerCase()));
					const libraryName = library.name === SoilCategories.PROJECT_SPECIFIC ? `${library.name} soil types` : library.name;

					if (!soils.length) return null;

					return (
						<Fragment key={library.id}>
							<h4 className={classes.groupLabel}>{libraryName}</h4>
							<ul className={classes.library}>
								{soils.map((soilId) =>
									<li
										key={soilId}
										className={classNames(classes.soilMaterial)}
										onClick={(): void => {
											const state = isCustomSoil(soilId) ? SoilDialogState.CUSTOM : SoilDialogState.OTHERS;
											handleSoilDialog(state, soilMaterials[soilId]);
										}}
									>
										<span className={classes.colorBox} style={{ backgroundColor: soilMaterials[soilId].color }}>
											{library.pattern && <img
												src={getPatternUrl(library.id)}
												alt="Category pattern"
												className={classes.optionImage}
											/>}
										</span>
										{soilMaterials[soilId].name}
									</li>,
								)}
							</ul>
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default SoilTypesTab;
