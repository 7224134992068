import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const generalButtonStyles: Partial<CSSProperties> = {
	boxShadow: 'none',
	textTransform: 'uppercase',
	fontSize: '13px',
	border: 'none',
	borderRadius: '4px',
	lineHeight: '22px',
	fontWeight: 600,
	letterSpacing: '0.5px',
	height: '32px',
};

const TextButtonBase = withStyles({
	root: {
		backgroundColor: 'rgba(98, 2, 238, 0)',
		...generalButtonStyles,
		'&:hover': {
			color: 'rgba(0, 0, 0, 0.87)',
		},
		'&:disabled': {
			color: '#bdbdbd',
		},
	},
})(Button);

const TextButtonNeutral = withStyles({
	root: {
		color: '#ffffff',
		'&:hover': {
			backgroundColor: '#424852',
			color: '#ffffff',
		},
		'&:focus': {
			backgroundColor: '#424852',
			color: '#ffffff',
		},
		'&:active': {
			backgroundColor: '#393e46',
			color: '#ffffff',
		},
	},
})(TextButtonBase);

const TextButtonOrange = withStyles({
	root: {
		color: '#ce7600',
		padding: '3px 10px 5px 11px',
		'&:hover': {
			backgroundColor: '#f6ba35',
		},
		'&:focus': {
			backgroundColor: '#fcb61b',
			color: 'rgba(0, 0, 0, 0.87)',
		},
		'&:active': {
			backgroundColor: '#ce7600',
			color: '#ffffff',
		},
	},
})(TextButtonBase);

const TextButtonTurquiose = withStyles({
	root: {
		color: '#01cebd',
		'&:hover': {
			backgroundColor: '#75dee1',
		},
		'&:focus': {
			backgroundColor: '#5adacc',
			color: '#2e2f30',
		},
		'&:active': {
			backgroundColor: '#127681',
			color: '#ffffff',
		},
	},
})(TextButtonBase);

const TextIconFlatButton = withStyles({
	root: {
		color: '#ffffff',
		fontSize: '12px',
		fontWeight: 'normal',
		backgroundColor: '#424242',
		padding: '4px 8px 4px 4px',
		textTransform: 'capitalize',
		'&:disabled': {
			backgroundColor: '#b1b3b5',
			boxShadow: 'none',
			color: '#333333',
			'&:focus, &:hover': {
				color: '#333333',
				backgroundColor: '#b1b3b5',
				boxShadow: 'none',
			},
			'& svg': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		},
		'&:focus, &:hover': {
			color: '#ffffff',
			backgroundColor: '#5f6060',
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: '#393e46',
			boxShadow: 'none',
		},
		'& svg': {
			marginRight: 4,
			width: 20,
			height: 20,
		},
	},
})(TextButtonBase);

const TextRaisedButton = withStyles({
	root: {
		backgroundColor: '#644bff',
		padding: '4px 14px 6px 15px',
		fontWeight: 600,
		fontSize: '13px',
		'&:hover, &:focus': {
			backgroundColor: '#8570ff',
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: '#4b34d9',
			boxShadow: 'none',
		},
		'&:disabled': {
			backgroundColor: '#7d848f',
			boxShadow: 'none',
		},
	},
})(TextIconFlatButton);

const HideSideBarButton = withStyles({
	root: {
		width: 16,
		height: 20,
		borderRadius: '1px',
		padding: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.23)',
		color: 'rgba(238, 238, 238, 0.54)',
		'&:hover': {
			backgroundColor: '#644bff',
			color: 'rgba(238, 238, 238, 0.54)',
			boxShadow: 'none',
		},
		'&:focus': {
			backgroundColor: '#3d2bb0',
			color: 'rgba(238, 238, 238, 0.54)',
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: '#3d2bb0',
			color: '#644bff',
		},
		'&:disabled': {
			backgroundColor: 'rgba(0, 0, 0, 0.09)',
			color: 'rgba(0, 0, 0, 0.26)',
			boxShadow: 'none',
		},
	},
})(IconButton);

const AccordionButton = withStyles({
	root: {
		height: 20,
		width: 24,
	},
})(HideSideBarButton);

export {
	TextButtonOrange,
	TextButtonNeutral,
	TextButtonTurquiose,
	TextIconFlatButton,
	TextRaisedButton,
	HideSideBarButton,
	AccordionButton,
};
