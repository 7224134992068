import React, { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getSelectedSoilInvestigationSoilLayer } from '@/store/selectors';
import { getSoilLibraries, getSoils } from '@/store/selectors/soil';
import { resetHoveredSoilLayer, resetSelectedSoilLayer } from '@/store/actions/soillayer';
import { CPTAsObject } from '@/store/types';
import { GraphRefs } from '@/models/types/graphs';
import CPTSoilLayer from './CPTSoilLayer';
import classes from './index.module.css';
import GraphSkeleton from '@/components/Interpretation/SoilInvestigationGraphs/GraphSkeleton';

interface Props {
	cpt: CPTAsObject;
	distanceBasedOnZoom: number;
	height: number;
	width: number;
	xAxisHeight: number;
	graphRefs: GraphRefs;
	visibility: boolean;
}

type WithPathMouseEvent = MouseEvent & {
	path: Element[];
};

const CPTSoilLayerBar: FC<Props> = ({
	cpt,
	distanceBasedOnZoom,
	height,
	width,
	xAxisHeight,
	graphRefs,
	visibility,
}): JSX.Element => {
	const dispatch = useDispatch();
	const soils = useSelector(getSoils);
	const soilLibraries = useSelector(getSoilLibraries);
	const selectedSoilInvestigationSoilLayer = useSelector(getSelectedSoilInvestigationSoilLayer);
	const barStart = Math.ceil(cpt.z);

	const handleClickAway = (event: React.MouseEvent<Document, MouseEvent>): void => {
		removePurpleBar(event);

		if (!selectedSoilInvestigationSoilLayer) return;
		// The path property of MouseEvent objects is non-standard
		// To not use any we extends the MouseEvent with the path properties
		const excludedElementFound = findExcludedElements(['topbar', 'sidebar', 'popper', 'layer'], event);

		if (excludedElementFound) return;

		dispatch(resetSelectedSoilLayer());
		dispatch(resetHoveredSoilLayer());
	};

	const findExcludedElements = (
		words: string[],
		event: React.MouseEvent<Document, MouseEvent>,
	): Element | undefined => {
		const { path } = (event as unknown) as WithPathMouseEvent;
		return path.find((p: Element): boolean => {
			const className = p.classList?.value?.replace('-', '').toLocaleLowerCase();

			return !!words.find((word): boolean => className?.includes(word));
		});
	};

	const removePurpleBar = (event: React.MouseEvent<Document, MouseEvent>): void => {
		const excludedElementFound = findExcludedElements(['topbar', 'sidebar', 'popper'], event);

		if (excludedElementFound) return;

		Object.values(graphRefs).forEach((graphRef): void => {
			if (typeof graphRef.ref === 'function' || !graphRef.ref?.current) return;
			graphRef.ref.current.style.display = 'none';
		});
	};

	return (
		<div className={classes.container}>
			<div className={classes.headerContainer}>
				<span className={classes.header}>Soil layers</span>
			</div>
			{visibility ? (
				<ClickAwayListener disableReactTree onClickAway={handleClickAway}>
					<ul className={classes.bar} style={{ height, width, marginTop: xAxisHeight }}>
						<li className={classes.cptZ} style={{ top: (barStart - cpt.z) * distanceBasedOnZoom - 1 }} />
						{Object.values(cpt.soilLayersList).map(
							(layer, index): ReactNode => (
								<CPTSoilLayer
									key={`CPT_layer_${layer.id || index}`}
									cpt={cpt}
									id={`${layer.id}`}
									barStart={barStart}
									distanceBasedOnZoom={distanceBasedOnZoom}
									top={layer.depthTop}
									depth={layer.depthBase}
									isSelected={selectedSoilInvestigationSoilLayer?.id === layer.id}
									soilPattern={soilLibraries[soils[layer.soilId]?.soilLibraryId]?.pattern?.pattern}
									soilMaterial={soils[layer.soilId]}
									graphRefs={graphRefs}
								/>
							),
						)}
					</ul>
				</ClickAwayListener>
			) : (
				<GraphSkeleton width={width} height={height} />
			)}
		</div>
	);
};

export default React.memo(CPTSoilLayerBar);
