import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const colors: { [key: string]: string } = {
	light: '#a99dff',
	default: '#8570ff',
	background: '#424852',
	active: '#644bff',
	disabled: '#9e9e9e',
};

const StyledCheckbox = withStyles({
	root: {
		padding: '5px 7px',
		borderRadius: 2,
		color: colors.light,
		width: 18,
		height: 18,
		'&:hover, &:focus, &:active, &$checked:hover, &$checked:focus, &$checked:active': {
			backgroundColor: colors.background,
			color: colors.default,
		},
		'&:active, &$checked:active': {
			color: colors.active,
		},
		'&$disabled': {
			color: colors.disabled,
		},
		'&$checked': {
			color: colors.default,
		},
	},
	checked: {},
	disabled: {},
})(Checkbox);

export default StyledCheckbox;
