/**
 * @fileoverview gRPC-Web generated client stub for com.royalhaskoningdhv.mondriaan.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.10
// source: cpt.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var location_pb = require('./location_pb.js')
const proto = {};
proto.com = {};
proto.com.royalhaskoningdhv = {};
proto.com.royalhaskoningdhv.mondriaan = {};
proto.com.royalhaskoningdhv.mondriaan.proto = require('./cpt_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CPTService_updateInterpretation = new grpc.web.MethodDescriptor(
  '/com.royalhaskoningdhv.mondriaan.proto.CPTService/updateInterpretation',
  grpc.web.MethodType.UNARY,
  proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTServiceClient.prototype.updateInterpretation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.CPTService/updateInterpretation',
      request,
      metadata || {},
      methodDescriptor_CPTService_updateInterpretation,
      callback);
};


/**
 * @param {!proto.com.royalhaskoningdhv.mondriaan.proto.Interpretation} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.com.royalhaskoningdhv.mondriaan.proto.CPTServicePromiseClient.prototype.updateInterpretation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.royalhaskoningdhv.mondriaan.proto.CPTService/updateInterpretation',
      request,
      metadata || {},
      methodDescriptor_CPTService_updateInterpretation);
};


module.exports = proto.com.royalhaskoningdhv.mondriaan.proto;

