import { createAction } from '@reduxjs/toolkit';

enum ActionTypes {
	UPDATE_CHARTS_VISIBILITY = '@@mondriaan/CHARTS_VISIBILITY/UPDATE',
	SHOW_WATER_PRESSURE = '@@mondriaan/CHARTS_VISIBILITY/SHOW_WATER_PRESSURE',
}

export const updateChartsVisibility =
	createAction<{visible: string[]; visibilityChangedManually: boolean}>(ActionTypes.UPDATE_CHARTS_VISIBILITY);
export const showWaterPressure = createAction<boolean>(ActionTypes.SHOW_WATER_PRESSURE);
