/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { generatePath, matchPath } from 'react-router-dom';
import { getUrlPathName, getUrlSearchParams } from '@/store/selectors/router';
import { interpretationGeneral, interpretationProjectSelected, projectsmap } from '@/routes';
import { getSelectedProjectId } from '@/store/selectors';

export function* openCptOnInterpretationPage() {
	const projectId: number = yield select(getSelectedProjectId);
	const location: string = yield select(getUrlPathName);
	const params: URLSearchParams = yield select(getUrlSearchParams);

	const isProjectsMapRoute = matchPath(projectsmap.templatePath, {
		path: `${location}`,
		exact: true,
		strict: false,
	});

	const interpretationUrl = isProjectsMapRoute
		? interpretationGeneral.templatePath
		: `${generatePath(interpretationProjectSelected.templatePath, { id: projectId })}/${params}`;

	yield put(push(interpretationUrl));
}
