import React, { FC } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { MousePosition } from '@/models/types';
import { exportInterpretations } from '@/helpers';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { viewSoilInvestigations } from '@/store/actions';
import classes from './index.module.css';
import { SoilInvestigationId } from '@/proto/build/soilInvestigation_pb';

interface Props {
	handleClose: () => void;
	projectId: number;
	projectName: string;
	interpretationMethod: InterpretationMethod;
	soilInvestigationIds: SoilInvestigationId.AsObject[];
	handleDeleteSoilInvestigation: () => void;
	open: boolean;
	mousePosition?: MousePosition;
	anchorEl?: HTMLElement
}

export const FilesSelectionMenu: FC<Props> = ({ handleClose, projectId, projectName, interpretationMethod,
	soilInvestigationIds, handleDeleteSoilInvestigation, open, mousePosition, anchorEl }): JSX.Element => {
	const anchorPosition = mousePosition?.mouseY !== null && mousePosition?.mouseX !== null
		? {
			top: mousePosition?.mouseY || 0,
			left: mousePosition?.mouseX || 0,
		}
		: undefined;
	const MAX_CPTS_TO_VIEW = 10;
	const dispatch = useDispatch();

	const handleView = (): void => {
		dispatch(viewSoilInvestigations({ projectId, soilInvestigationsIds: soilInvestigationIds }));
	};

	const handleExport = (): void => {
		handleClose();
		exportInterpretations(soilInvestigationIds, projectId, projectName, interpretationMethod, dispatch);
	};

	return (
		<Menu
			classes={{ paper: classes.menu, list: classes.list }}
			keepMounted
			open={open}
			anchorEl={anchorEl}
			onClose={(): void => handleClose()}
			anchorReference={mousePosition ? 'anchorPosition' : 'anchorEl'}
			anchorPosition={anchorPosition}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			MenuListProps={{ onMouseLeave: () => handleClose()	}}
		>
			{
				 (soilInvestigationIds.length <= MAX_CPTS_TO_VIEW) && (
					<MenuItem classes={{ root: classes.listItem }} onClick={handleView}>
						View Selection
					</MenuItem>
				)
			}
			<MenuItem disabled={projectId <= 0} classes={{ root: classes.listItem }} onClick={handleExport}>
				Export Selection
			</MenuItem>
			<MenuItem disabled={projectId <= 0} classes={{ root: classes.listItem }} onClick={handleDeleteSoilInvestigation}>
				Delete Selection
			</MenuItem>
		</Menu>
	);
};

export default FilesSelectionMenu;
