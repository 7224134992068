import { createAction } from '@reduxjs/toolkit';
import { Soil, SoilCategory, SoilLibrary, UpdateCustomSoilData } from '@/proto/build/soil_pb';

/* Action Types */
enum ActionTypes {
	SET_SOIL = '@@mondriaan/SOIL/SET',
	UPDATE_SOIL = '@@mondriaan/SOIL/UPDATE',
	DELETE_SOIL = '@@mondriaan/SOIL/DELETE',
	SET_SOILS = '@@mondriaan/SOIL/SET_SOILS',
	SET_SOIL_LIBRARIES = '@@mondriaan/SOIL/SET_LIBRARIES',
	SET_SOIL_CATEGORIES = '@@mondriaan/SOIL/SET_CATEGORIES',
}

export const setSoil = createAction<Soil.AsObject>(ActionTypes.SET_SOIL);
export const updateSoil = createAction<UpdateCustomSoilData.AsObject>(ActionTypes.UPDATE_SOIL);
export const deleteSoil = createAction<Soil.AsObject>(ActionTypes.DELETE_SOIL);
export const setSoilLibraries = createAction<SoilLibrary.AsObject[]>(ActionTypes.SET_SOIL_LIBRARIES);
export const setSoils = createAction<Soil.AsObject[]>(ActionTypes.SET_SOILS);
export const setSoilCategories = createAction<SoilCategory.AsObject[]>(ActionTypes.SET_SOIL_CATEGORIES);
