import React, { FC } from 'react';
import classes from './index.module.css';

interface Props {
	width: number;
	height: number;
}

const GraphSkeleton: FC<Props> = ({ width, height }): JSX.Element => (
	<div className={classes.container} style={{ width, height }}></div>
);

export default GraphSkeleton;
