import React, { FC } from 'react';
import { FormControl, FormHelperText, InputLabel, Tooltip } from '@material-ui/core';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { OutlinedInput } from '@/components/custom/OutlinedTextField';
import classes from './index.module.css';

type Props = OutlinedInputProps & {
	id: string;
	helperText?: string;
	tooltipText: string;
}

const OutlinedTextFieldWithTooltip: FC<Props> = ({ id, error, helperText, tooltipText, label, className, ...props }): JSX.Element =>
	<Tooltip
		className={className}
		classes={{ tooltip: classes.tooltip }}
		title={tooltipText}
		placement="top"
		enterDelay={400}
		enterNextDelay={400}
	>
		<FormControl variant="outlined" className={classes.outlinedInput}>
			<InputLabel
				htmlFor={id}
				className={classes.label}
				error={error}
				classes={{ focused: classes.labelFocused, error: classes.error }}
			>
				{label}
				<InfoRounded className={classes.infoIcon} />
			</InputLabel>
			<OutlinedInput
				{...props}
				id={id}
				error={error}
				label={`${label}....`}
			/>
			<FormHelperText error={error} classes={{ error: classes.error }}>
				{helperText}
			</FormHelperText>
		</FormControl>
	</Tooltip>;

export default OutlinedTextFieldWithTooltip;
