import React, { FC, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import { getDescriptionOf } from '@/models/types';
import { getTimeStamp, dateTimestampToString } from '@/helpers';
import { AccordionButton } from '@/components/custom/buttons';
import { Project } from '@/store/types';
import classes from './index.module.css';

interface Props {
	project: Project;
}

const ProjectInformation: FC<Props> = ({ project }) => {
	const [createdAt, setCreatedAt] = useState<string>('');
	const [lastModified, setLastModified] = useState<string>('');

	useEffect(() => {
		if (project.createdAt) {
			const createdAtTimestamp = getTimeStamp(project.createdAt);

			// TODO: change to lastModified when the property is available
			const lastModifiedTimestamp = createdAtTimestamp;

			setCreatedAt(dateTimestampToString(createdAtTimestamp));
			setLastModified(dateTimestampToString(lastModifiedTimestamp));
		}
	}, [project]);

	return (
		<Accordion classes={{ root: classes.accordion }}>
			<AccordionSummary
				classes={{ root: classes.accordionSummary, content: classes.accordionContent }}
				expandIcon={
					<AccordionButton>
						<ExpandMoreIcon />
					</AccordionButton>
				}
			>
				<span className={classes.projectName}>{project.name}</span>
			</AccordionSummary>
			<AccordionDetails classes={{ root: classes.accordionDetails }}>
				<span className={classes.abw}>{project.abwReference}</span>
				<span className={classes.description}>{project.description}</span>
				<div className={classes.projectInfo}>
					<span className={classes.fields}>Interpretation method: {getDescriptionOf(project.interpretationMethod)}</span>
					<span className={classes.fields}>Min layer: {project.minimumLayerThickness.toFixed(1)} m</span>
					<span className={classes.fields}>Ground water: {project.waterLevel.toFixed(1)} m</span>
					<span className={classes.fields}>Created: {createdAt}</span>
					<span className={classes.fields}>Modified: {lastModified}</span>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default ProjectInformation;
