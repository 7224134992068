import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { UpdateProjectData } from '@/proto/build/project_pb';
import { OutlinedTextField } from '@/components/custom/OutlinedTextField';
import OutlinedTextFieldWithTooltip from '@/components/custom/OutlinedTextFieldWithTooltip';
import { TextButtonTurquiose, TextIconFlatButton } from '@/components/custom/buttons';
import { getNumberWith1DecimalPlace, onKeyPress } from '@/helpers';
import { checkUniqueProjectName, updateProjectCmd } from '@/net';
import { InterpretationMethod } from '@/proto/build/interpretation_pb';
import { getDescriptionOf } from '@/models/types';
import { Project } from '@/store/types';
import classes from './index.module.css';

interface Props {
	project: Project;
	closeEdit: () => void;
}

const defaultState: UpdateProjectData.AsObject = {
	id: 0,
	name: '',
	description: '',
	abwReference: '',
	interpretationMethod: InterpretationMethod.MANUAL,
	waterLevel: 0,
	minimumLayerThickness: 0.1,
};

export const ProjectEdit: FC<Props> = ({ project, closeEdit }) => {
	const dispatch = useDispatch();
	const [tempProject, setTempProject] = useState<UpdateProjectData.AsObject>(defaultState);
	const [reasonNameIsInvalid, setReasonNameIsInvalid] = useState<string>('');
	const [reasonWaterLevelIsInvalid, setReasonWaterLevelIsInvalid] = useState<string>('');

	useEffect(() => {
		setTempProject(
			(old): UpdateProjectData.AsObject => {
				return {
					...old,
					id: project.id,
					name: project.name,
					description: project.description,
					abwReference: project.abwReference,
					interpretationMethod: project.interpretationMethod,
					minimumLayerThickness: project.minimumLayerThickness,
					waterLevel: project.waterLevel,
				};
			},
		);
	}, [project]);

	const updateProject = (): void => {
		const nameAsStringValue = new StringValue();
		nameAsStringValue.setValue(tempProject.name);

		if (!reasonNameIsInvalid && !reasonWaterLevelIsInvalid) {
			checkUniqueProjectName(nameAsStringValue)
				.then((nameIsUnique) => {
					if (nameIsUnique.getValue() || tempProject.name.toLocaleLowerCase() === project.name.toLocaleLowerCase()) {
						if (
							project.name.toLocaleLowerCase() !== tempProject.name.toLocaleLowerCase() ||
							project.description.toLocaleLowerCase() !== tempProject.description.toLocaleLowerCase() ||
							project.abwReference.toLocaleLowerCase() !== tempProject.abwReference.toLocaleLowerCase() ||
							project.interpretationMethod !== tempProject.interpretationMethod ||
							project.minimumLayerThickness.toFixed(1) !== tempProject.minimumLayerThickness.toFixed(1) ||
							project.waterLevel !== tempProject.waterLevel
						) {
							updateProjectCmd(tempProject, project, dispatch);
						}
						closeEdit();
					} else {
						setReasonNameIsInvalid('This name is already in use');
					}
				})
				.catch((e) => {
					console.error(e);
				});
		}
	};

	const handleTextFieldOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, input: string): void => {
		let value = input === 'minimumLayerThickness' ? getNumberWith1DecimalPlace(event.target.value) : event.target.value;

		if (input === 'name') {
			if (value === '') {
				setReasonNameIsInvalid('This field is mandatory');
			} else {
				setReasonNameIsInvalid('');
			}
		}

		else if (input === 'waterLevel') {
			if (value === '') {
				setReasonWaterLevelIsInvalid('This field is mandatory');
			} else {
				setReasonWaterLevelIsInvalid('');
			}
		}

		else if (input === 'minimumLayerThickness') {
			if (value !== undefined && value > 1) {
				value = 1;
			}
		}

		setTempProject((old) => {
			return {
				...old,
				[`${input}`]: value,
			};
		});
	};

	const handleTextFieldOnBlur = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, input: string): void => {
		let value =  getNumberWith1DecimalPlace(event.target.value);

		if (value !== undefined) {
			if (value > 1) {
				value = 1;
			} else if (value < 0.1) {
				value = 0.1;
			}
		}

		setTempProject((old) => {
			return {
				...old,
				[`${input}`]: value,
			};
		});
	};

	const rootClassForProjectName = (): string => {
		return reasonNameIsInvalid ? classes.error : '';
	};

	if (!tempProject) return null;

	return (
		<Fragment>
			<div className={classes.buttons}>
				<TextButtonTurquiose onClick={(): void => updateProject()}>DONE</TextButtonTurquiose>
				<TextIconFlatButton variant="contained" className={classes.deleteProject} onClick={(e): void => console.log('delete project')}>
					<DeleteIcon />
					<span className={classes.buttonText}>Delete project</span>
				</TextIconFlatButton>
			</div>
			<div className={classes.textfields}>
				<OutlinedTextField
					fullWidth
					value={tempProject.name}
					className={classes.textField}
					label="Name"
					classes={{ root: rootClassForProjectName() }}
					onChange={(e): void => handleTextFieldOnChange(e, 'name')}
					onKeyPress={onKeyPress}
					inputProps={{
						maxLength: 100,
						autoComplete: 'off',
					}}
					type="text"
					helperText={reasonNameIsInvalid}
				/>
				<OutlinedTextField
					fullWidth
					value={tempProject.abwReference}
					className={classes.textField}
					label="ABW Reference"
					onChange={(e): void => handleTextFieldOnChange(e, 'abwReference')}
					onKeyPress={onKeyPress}
					inputProps={{
						maxLength: 15,
						form: {
							autocomplete: 'off',
						},
					}}
					type="text"
				/>
				<OutlinedTextField
					fullWidth
					value={tempProject.description}
					className={classNames(classes.textField, classes.textArea)}
					multiline
					minRows={5}
					label="Description"
					onChange={(e): void => handleTextFieldOnChange(e, 'description')}
					onKeyPress={onKeyPress}
					type="text"
				/>
				<OutlinedTextField
					fullWidth
					select
					value={tempProject.interpretationMethod}
					className={classes.textField}
					label="Interpretation Method"
					disabled={Object.keys(InterpretationMethod).length <= 1}
					onChange={(e): void => handleTextFieldOnChange(e, 'interpretationMethod')}
					SelectProps={{
						IconComponent: KeyboardArrowDownRounded,
						classes: { icon: classes.interpretationIcon },
					}}
					type="text"
				>
					{Object.entries(InterpretationMethod).map(([key, value], index) => {
						return (
							<MenuItem key={key} value={index}>
								{getDescriptionOf(value as InterpretationMethod)}
							</MenuItem>
						);
					})}
				</OutlinedTextField>
				<div className={classes.fieldContainer}>
					<OutlinedTextField
						fullWidth
						value={Math.round(tempProject.minimumLayerThickness * 10) / 10}
						className={classes.textField}
						label="Min Layer"
						onChange={(e): void => handleTextFieldOnChange(e, 'minimumLayerThickness')}
						onBlur={(e): void => handleTextFieldOnBlur(e, 'minimumLayerThickness')}
						onKeyPress={onKeyPress}
						inputProps={{ step: 0.1, lang: navigator.language, min: 0.1, max: 1 }}
						InputProps={{
							endAdornment: <span className={classes.endAdornment}>m</span>,
						}}
						type="number"
					/>
					<OutlinedTextFieldWithTooltip
						fullWidth
						id="water-level"
						label="Ground Water"
						value={tempProject.waterLevel}
						className={classes.outlinedInput}
						onChange={(e): void => handleTextFieldOnChange(e, 'waterLevel')}
						onKeyPress={onKeyPress}
						inputProps={{ step: 0.1, lang: navigator.language }}
						endAdornment={<span className={classes.endAdornment}>m</span>}
						error={!!reasonWaterLevelIsInvalid}
						type="number"
						helperText={reasonWaterLevelIsInvalid}
						tooltipText="Please, fill in the ground water level (depth compared to NAP) for your project.
										It will be used when defining soil layers with automatic Interpretation methods."
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default ProjectEdit;
