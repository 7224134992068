import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { Dispatch } from '@reduxjs/toolkit';
import { Int64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { SoilLibraryServicePromiseClient } from '@/proto/build/soil_grpc_web_pb';
import { Soil, SoilLibraryAndCategoryList, UpdateCustomSoilData, NewCustomSoil } from '@/proto/build/soil_pb';
import { GrpcError } from '@/models/classes';
import { setSoil, updateSoil, deleteSoil } from '@/store/actions/soil';
import { addSoilToProject, deleteSoilFromProject } from '@/store/actions';
import mondriaanApi from './facade/mondriaanApi';

const soilLibraryServiceClient = new SoilLibraryServicePromiseClient(process.env.REACT_APP_BACKEND_URL || '', {});

export async function getGlobalSoilLibraries(): Promise<SoilLibraryAndCategoryList.AsObject> {
	return (await soilLibraryServiceClient.allGlobalLibraries(new Empty())).toObject();
}

export function addCustomSoilCmd(customSoil: NewCustomSoil.AsObject, projectName: string, categoryName: string, dispatch: Dispatch): void {
	performance.mark('addNewCustomSoil:start');
	const soil = new NewCustomSoil();
	soil.setProjectId(customSoil.projectId);
	soil.setCategoryId(customSoil.categoryId);
	soil.setName(customSoil.name);
	soil.setCohesion(customSoil.cohesion);
	soil.setFrictionAngle(customSoil.frictionAngle);
	soil.setColor(customSoil.color);

	mondriaanApi.createSoilType(soil)
		.then((response) => {
			performance.mark('addNewCustomSoil:end');
			dispatch(setSoil(response));
			dispatch(addSoilToProject({ projectId: customSoil.projectId, projectName, categoryName, soilId: response.id }));
			performance.measure('addNewCustomSoil', 'addNewCustomSoil:start', 'addNewCustomSoil:end');
		})
		.catch((e: GrpcError) => {
			throw new Error(`Could not add the new custom soil with name ${customSoil.name}, ${e.message}`);
		});
}

export function updateCustomSoilCmd(customSoil: UpdateCustomSoilData.AsObject, dispatch: Dispatch): void {
	performance.mark('updateCustomSoilCmd:start');
	const soil = new UpdateCustomSoilData();
	soil.setId(customSoil.id);
	soil.setCategoryId(customSoil.categoryId);
	soil.setName(customSoil.name);
	soil.setCohesion(customSoil.cohesion);
	soil.setFrictionAngle(customSoil.frictionAngle);
	soil.setColor(customSoil.color);

	mondriaanApi
		.updateSoilType(soil)
		.then(() => {
			performance.mark('updateCustomSoilCmd:end');
			dispatch(updateSoil(customSoil));
			performance.measure('updateCustomSoilCmd', 'updateCustomSoilCmd:start', 'updateCustomSoilCmd:end');
		})
		.catch((e: GrpcError) => {
			throw new Error(`Could not update the custom soil with name ${customSoil.name}, ${e.message}`);
		});
}


export function deleteCustomSoilCmd(soil: Soil.AsObject, dispatch: Dispatch): void {
	performance.mark('deleteCustomSoilCmd:start');
	const soilId = new Int64Value();
	soilId.setValue(soil.id);

	mondriaanApi
		.deleteSoilType(soilId)
		.then(() => {
			performance.mark('deleteCustomSoilCmd:end');
			dispatch(deleteSoil(soil));
			if (soil.projectId?.value) {
				dispatch(deleteSoilFromProject({ projectId: soil.projectId.value, soilId: soil.id }));
			}
			performance.measure('deleteCustomSoilCmd', 'deleteCustomSoilCmd:start', 'deleteCustomSoilCmd:end');
		})
		.catch((e: GrpcError) => {
			throw new Error(`Could not delete the custom soil with id ${soil.id}, ${e.message}`);
		});
}
