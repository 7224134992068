import React, { FC, Fragment, MouseEvent as ReactMouseEvent, useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Soil, SoilPattern } from '@/proto/build/soil_pb';
import { distanceBetweenSteps } from '@/components/Layout/DepthRuler';
import { ESoilInvestigationType, Position } from '@/models/types';
import { PROPOSED_LAYER_SOIL_ID } from '@/models/constants';
import {
	setSelectedLayer,
	updateSoilLayer,
	setHoveredSoilLayer,
	resetHoveredSoilLayer, setDraggingState,
} from '@/store/actions';
import { getHoveredLayerID, getSelectedLayerID } from '@/store/selectors';
import { MIN_LAYER_THICKNESS } from '@/models/constants';
import { CPTAsObject } from '@/store/types';
import { GraphRefs } from '@/models/types/graphs';
import classes from './index.module.css';

/* Dynamically include all soil layer images */
const imageContext = require.context('@/assets/images/patterns', false, /\.(svg)$/);

interface Props {
	className?: string;
	id: string;
	cpt: CPTAsObject;
	barStart: number;
	distanceBasedOnZoom: number;
	soilMaterial?: Soil.AsObject;
	soilPattern?: SoilPattern;
	top: number;
	depth: number;
	isDeletable?: boolean;
	isHovering?: boolean;
	isSelected: boolean;
	graphRefs: GraphRefs;
}

const BORDER_SIZE = 5;

const getTooltipPosition = (distanceBasedOnZoom: number): number => {
	let soilLayerWidth = 54;
	const defaultMarginLeft = 2;

	// This is an efficient way to get the zoomLevel without the need to pass it through all the component that use distanceBasedOnZoom
	// we have it already in the distanceBasedOnZoom so we need to divide the distanceBasedOnZoom with distanceBetweenSteps
	// Otherwise we would need to refactor a lot of component that are using this distanceBasedOnZoom prop and pass both
	// (distanceBetweenSteps and zoomLevel separated) or pass the zoomLevel the distanceBasedOnZoom separated
	// distanceBasedOnZoom = distanceBetweenSteps * zoomLevel;
	let zoomLevel = distanceBasedOnZoom / distanceBetweenSteps;

	// The default SoilBar width becomes 40px (MIN_WIDTH) when the zoom level is below 75%
	if (zoomLevel < 0.75) {
		soilLayerWidth = 40;
		zoomLevel = 1;
	}
	// The max SoilBar width is 162px (3 * 54)
	if (zoomLevel > 3) {
		zoomLevel = 3;
	}

	return soilLayerWidth * zoomLevel + defaultMarginLeft * zoomLevel;
};

const CPTSoilLayer: FC<Props> = ({
	className,
	id,
	cpt,
	barStart,
	distanceBasedOnZoom,
	soilMaterial,
	soilPattern,
	top,
	depth: depthInMeters,
	isSelected,
	graphRefs,
}): JSX.Element | null => {
	const dispatch = useDispatch();
	const hovered = useSelector(getHoveredLayerID);
	const anySelected = !!useSelector(getSelectedLayerID).id;
	const [position, setPosition] = useState({ top, depth: depthInMeters });
	const [topTooltipOpen, setTopTooltipOpen] = useState(false);
	const [bottomTooltipOpen, setBottomTooltipOpen] = useState(false);
	const depthInPixels = position.depth * distanceBasedOnZoom;
	const isTopLayer = isSelected && cpt?.z?.toFixed(2) === top?.toFixed(2);

	/* Calculate the Tooltip when hover left position and memoize it */
	const computedTooltipPosition = useMemo(() => getTooltipPosition(distanceBasedOnZoom), [distanceBasedOnZoom]);

	/* Update the local position with the position in the store */
	useEffect(() => {
		setPosition({ top, depth: depthInMeters });
	}, [top, depthInMeters]);

	const handleOnSelect = (): void => {
		if (isSelected) return;
		drawPurpleBar(position.depth, position.top);
		dispatch(
			setSelectedLayer({
				soilInvestigationId: cpt.id.toString(),
				soilInvestigationType: ESoilInvestigationType.CPT,
				id,
			}),
		);
	};

	const getTopInPx = useCallback((top: number): number => {
		return (barStart - top) * distanceBasedOnZoom - 1;
	}, [barStart, distanceBasedOnZoom]);

	const drawPurpleBar = useCallback((height: number, top: number): void => {
		const heightInPx = height * distanceBasedOnZoom;
		const topInPx = getTopInPx(top) + 49;

		Object.values(graphRefs).forEach((graphRef): void => {
			if (typeof graphRef.ref === 'function' || !graphRef.ref?.current) return;
			graphRef.ref.current.style.display = 'block';
			graphRef.ref.current.style.height = `${heightInPx}px`;
			graphRef.ref.current.style.top = `${topInPx}px`;
			graphRef.ref.current.style.width = `${graphRef.width}px`;
			graphRef.ref.current.style.left = `${graphRef.marginLeft}px`;
		});
	}, [distanceBasedOnZoom, getTopInPx, graphRefs]);

	useEffect(() => {
		if (!isSelected) return;
		drawPurpleBar(position.depth, position.top);
	}, [drawPurpleBar, isSelected, position.depth, position.top]);

	const handleOnMouseDownTopHandle = (event: ReactMouseEvent<HTMLLIElement>): void => {
		/* Calculate distance between the top of the screen and the bottom of the layer */
		const marginToBottom = event.clientY - (event.nativeEvent.offsetY - BORDER_SIZE) + depthInPixels;

		const calculatePosition = (clientY: number): Position => {
			const newDepth = (marginToBottom - clientY) / distanceBasedOnZoom;
			const newTop = position.top - (position.depth - newDepth);
			const bottom = position.top - position.depth;

			/* Make sure layer doesnt get smaller then minimum layer thickness */
			if (MIN_LAYER_THICKNESS > newDepth) {
				return { top: bottom + MIN_LAYER_THICKNESS, depth: MIN_LAYER_THICKNESS };
			}

			/* Make sure layer doesnt go higher then the CPT's Z value */
			if (newTop > cpt.z) {
				return { top: cpt.z, depth: cpt.z - bottom };
			}

			return { top: newTop, depth: newDepth };
		};

		setTopTooltipOpen(true);
		dispatch(setDraggingState('top'));
		handleOnMouseDown(event, calculatePosition);
	};

	const handleOnMouseDownBottomHandle = (event: ReactMouseEvent<HTMLLIElement>): void => {
		/* Calculate distance between the top of the screen and the start of the layer */
		const marginToTop =
			event.clientY - (event.nativeEvent.offsetY - BORDER_SIZE) - position.depth * distanceBasedOnZoom;
		const maxHeight = Math.abs(cpt.recordsList[cpt.recordsList.length - 1].depthBasedOnZValue - position.top);

		const calculatePosition = (clientY: number): Position => {
			let newHeight = (clientY - marginToTop) / distanceBasedOnZoom;

			/* Make sure layer doesnt get smaller then minimum layer thickness */
			if (newHeight < MIN_LAYER_THICKNESS) {
				newHeight = MIN_LAYER_THICKNESS;
			} else if (newHeight > maxHeight) {
				/* Make sure layer doesnt go lower then the the lowest record inside the CPT */
				newHeight = maxHeight;
			}

			return { top: position.top, depth: newHeight };
		};

		setBottomTooltipOpen(true);
		dispatch(setDraggingState('bottom'));
		handleOnMouseDown(event, calculatePosition);
	};

	const handleOnMouseDown = (
		event: ReactMouseEvent<HTMLLIElement>,
		calculatePosition: (clientY: number) => Position,
	): void => {
		event.stopPropagation();

		const resize = (event: MouseEvent): void => {
			event.stopPropagation();
			if (!calculatePosition) return;

			const pos = calculatePosition(event.clientY);
			setPosition(pos);

			drawPurpleBar(pos.depth, pos.top);
		};

		const mouseUp = (event: MouseEvent): void => {
			setTopTooltipOpen(false);
			setBottomTooltipOpen(false);
			dispatch(setDraggingState(undefined));
			if (!calculatePosition) return;

			const pos = calculatePosition(event.clientY);

			const payload = {
				soilInvestigationId: cpt.id.toString(),
				layer: {
					id,
					depthTop: pos.top,
					depthBase: pos.depth,
					soilId: soilMaterial?.id || PROPOSED_LAYER_SOIL_ID,
				},
			};
			dispatch(updateSoilLayer(payload));

			/* The mouse up handler only has to be run once, so we can let it de-register itself */
			document.removeEventListener('mouseup', mouseUp);
			document.removeEventListener('mousemove', resize);
		};

		/* This is put on the document so you can still move your mouse horizontally when dragging */
		document.addEventListener('mouseup', mouseUp);
		document.addEventListener('mousemove', resize);
	};

	const handleOnMouseEnter = (): void => {
		if (!soilMaterial || anySelected) return;
		dispatch(setHoveredSoilLayer({ soilInvestigationId: cpt.id.toString(), id }));
	};

	const handleOnMouseLeave = (): void => {
		if (!soilMaterial || anySelected) return;
		dispatch(resetHoveredSoilLayer());
	};

	const getPositionTooltip = (value: string, className: string): JSX.Element => {
		return (
			<div className={classNames(classes.positionTooltip, className)} style={{ left: computedTooltipPosition + 3 }}>
				{value}
			</div>
		);
	};

	const soilTooltip = (): JSX.Element => {
		return (
			<div className={classes.tooltip} style={{ left: computedTooltipPosition }}>
				<span className={classes.tooltipLabel}>{soilMaterial?.name}</span>
			</div>
		);
	};

	const topInPx = getTopInPx(position.top);
	const flooredTop = Math.floor(topInPx);

	return (
		<li
			className={classNames(
				classes.layer,
				{ [classes.proposedLayer]: !soilMaterial },
				{ [classes.selected]: isSelected },
				{ [classes.hovered]: hovered.id === id && soilMaterial && !anySelected },
				className,
			)}
			style={{
				top: !isTopLayer ? topInPx : flooredTop,
				height: !isTopLayer ? Math.ceil(depthInPixels + (topInPx - flooredTop)) : depthInPixels,
				backgroundColor: soilMaterial?.color || '#000',
				backgroundImage:
					soilPattern !== undefined
						? `url(${imageContext(`./${Object.keys(SoilPattern)[soilPattern].toLowerCase()}.svg`)})`
						: '',
			}}
			onClick={handleOnSelect}
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
		>
			<Fragment>
				{isSelected && topTooltipOpen && getPositionTooltip(position.top.toFixed(2), classes.positionTooltipTop)}
				{isSelected && !isTopLayer && <span className={classes.topHandle} onMouseDown={handleOnMouseDownTopHandle} />}
				{hovered.id === id && soilMaterial && !anySelected && soilTooltip()}
				{isSelected && <span className={classes.bottomHandle} onMouseDown={handleOnMouseDownBottomHandle} />}
				{isSelected &&
					bottomTooltipOpen &&
					getPositionTooltip((position.top - position.depth).toFixed(2), classes.positionTooltipBottom)}
			</Fragment>
		</li>
	);
};

export default React.memo(CPTSoilLayer);
