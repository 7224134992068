import React, { FC, Fragment, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Tabs } from '@material-ui/core';
import SoilTypeDialog from '@/components/Dialogs/SoilTypeDialog';
import SoilTypeReadOnlyDialog from '@/components/Dialogs/SoilTypeReadOnlyDialog';
import SoilTypesTab from '@/components/Layout/SideBar/CPTSidebarHandler/SoilTypesTab';
import TabPanel from '@/components/custom/TabPanel';
import StyledTab from '@/components/custom/tabs';
import { Soil } from '@/proto/build/soil_pb';
import { SoilDialogState } from '@/models/types';
import classes from './index.module.css';
import LayerInputFields from './LayerInputFields';
import SoilMaterialHandler from './SoilMaterialHandler';


interface Props {
	openSideBar: boolean;
}

const CPTSidebarHandler: FC<Props> = ({ openSideBar }) : JSX.Element => {
	const [tabValue, setTabValue] = useState(0);
	const [selectedSoil, setSelectedSoil] = useState<Soil.AsObject | null>(null);
	const [dialogState, setDialogState] = useState<SoilDialogState>(SoilDialogState.HIDDEN);

	const handleSoilDialog = (status: SoilDialogState, soil?: Soil.AsObject): void => {
		const s = soil || null;

		setSelectedSoil(s);
		setDialogState(status);
	};

	return (
		<Fragment>
			<Tabs
				value={tabValue}
				classes={{
					root: classes.tabRoot,
					indicator: classes.tabIndicator,
				}}
				onChange={(_event, newValue): void => { setTabValue(newValue); }}
			>
				<StyledTab label="Layers" />
				<StyledTab label="Soil Libraries" />
			</Tabs>
			{openSideBar && (
				<Fragment>
					<SwipeableViews
						index={tabValue}
						onChangeIndex={(index): void => setTabValue(index)}
					>
						<TabPanel value={tabValue} index={0}>
							<LayerInputFields />
							<SoilMaterialHandler />
						</TabPanel>
						<TabPanel value={tabValue} index={1} className={classes.tabPanel}>
							<SoilTypesTab handleSoilDialog={(status, soil): void => handleSoilDialog(status, soil) } />
						</TabPanel>
					</SwipeableViews>
				</Fragment>
			)}
			{
				(dialogState === SoilDialogState.NEW || dialogState === SoilDialogState.CUSTOM) &&
					<SoilTypeDialog
						soilDialogIsOpen={dialogState === SoilDialogState.NEW || dialogState === SoilDialogState.CUSTOM}
						soil={selectedSoil}
						handleSoilDialog={(status: SoilDialogState): void => handleSoilDialog(status)}
					/>
			}
			{
				(dialogState === SoilDialogState.OTHERS) &&
					<SoilTypeReadOnlyDialog
						soilDialogIsOpen={dialogState === SoilDialogState.OTHERS}
						soil={selectedSoil}
						handleSoilDialog={(status: SoilDialogState): void => handleSoilDialog(status)}
					/>
			}
		</Fragment>
	);
};

export default CPTSidebarHandler;
