import React, { createRef, FC, forwardRef, ReactNode, Ref, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import WaterPressureGraph from '@/components/Interpretation/SoilInvestigationGraphs/WaterPressureGraph';
import ConeResistanceGraph from '@/components/Interpretation/SoilInvestigationGraphs/ConeResistanceGraph';
import FrictionRatioGraph from '@/components/Interpretation/SoilInvestigationGraphs/FrictionRatioGraph';
import { distanceBetweenSteps } from '@/components/Layout/DepthRuler';
import WarningChip from '@/components/Interpretation/WarningChip';
import { getChartsVisibility, getSelectedProject } from '@/store/selectors';
import { CPTAsObject } from '@/store/types';
import { ChartVisibility } from '@/models/types';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import MetaDataContent from '@/components/Dialogs/MetaDataContent';
import { TextButtonTurquiose } from '@/components/custom/buttons';
import { GraphMeasurements } from '@/models/types/graphs';
import classes from './index.module.css';
import CPTSoilLayerBar from './CPTSoilLayerBar';

interface Props {
	cpt: CPTAsObject;
	zoomLevel: number;
	maxZIndex: number;
	visibility: boolean;
	ref: Ref<HTMLDivElement>;
}

const xAxisHeight = 30;
const rightMargin = 10;
const coneResistanceFinalXTick = 30;
const frictionRatioFinalXTick = 10;
const waterPressureFinalXTick = 10;
const defaultSoilLayerBarWidth = 54;
const minSoilBarWidth = 40;
const maxSoilBarWidth = 162; // defaultWidth * zoomlevel(300%)

const computeGraphMeasurements = (
	cpt: CPTAsObject,
	zoomLevel: number,
	finalXTick: number,
	yAxisWidth: number,
): GraphMeasurements | null => {
	if (!cpt.recordsList.length) return null;

	const xTickCount = finalXTick / 2;
	const deepestValue = cpt.recordsList[cpt.recordsList.length - 1].depthBasedOnZValue;
	const measurementLength = Math.ceil(cpt.z) - Math.floor(deepestValue);
	const distanceBasedOnZoom = distanceBetweenSteps * zoomLevel;

	return {
		finalXTick,
		yAxisWidth,
		graphWidth: distanceBasedOnZoom * xTickCount + yAxisWidth + rightMargin,
		graphHeight: distanceBasedOnZoom * measurementLength + xAxisHeight,
		graphHeightWithoutXAxis: distanceBasedOnZoom * measurementLength,
		gridHorizontalPoints: [...Array(measurementLength + 1)?.keys()].map(
			(i): number => distanceBasedOnZoom * i + xAxisHeight,
		),
		gridVerticalPoints: [...Array(xTickCount + 1)?.keys()].map((i): number => distanceBasedOnZoom * i + yAxisWidth),
		lastMeasurementRounded: Math.floor(deepestValue),
	};
};

const CPT: FC<Props> = forwardRef<HTMLDivElement, Props>(({ cpt, zoomLevel, maxZIndex, visibility }, ref) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const selectedProject =  useSelector(getSelectedProject);
	const visibleCharts = useSelector(getChartsVisibility);
	const frictionRatioRef = useRef<HTMLSpanElement>(null);
	const coneResistanceRef = useRef<HTMLSpanElement>(null);
	const waterPressureRef = useRef<HTMLSpanElement>(null);
	const cptHeaderRef = createRef<HTMLDivElement>();
	const topPosBaseOnFirstZIndex = (maxZIndex - Math.ceil(cpt.z)) * (distanceBetweenSteps * zoomLevel);
	const [cptIsInActiveProject, setCptIsInActiveProject] = useState(false);

	useEffect(() => {
		if (selectedProject) {
			const found = Object.values(selectedProject.cptsList).find((projectCpt) => cpt.id === projectCpt.id);
			setCptIsInActiveProject(!!found);
		}
	}, [cpt.id, selectedProject]);

	/* Calculate all the required values for both graphs and memoize them */
	const measurementsWaterPressure = useMemo(
		() => computeGraphMeasurements(cpt, zoomLevel, waterPressureFinalXTick, 40),
		[cpt, zoomLevel],
	);
	const measurementsConeResistance = useMemo(
		() => computeGraphMeasurements(cpt, zoomLevel, coneResistanceFinalXTick, 45),
		[cpt, zoomLevel],
	);
	const measurementsFrictionRatio = useMemo(
		() => computeGraphMeasurements(cpt, zoomLevel, frictionRatioFinalXTick, 10),
		[cpt, zoomLevel],
	);
	const measurementsSoilBarWidth = useMemo((): number => {
		const zoomedWidth = defaultSoilLayerBarWidth * zoomLevel;
		return zoomedWidth <= minSoilBarWidth
			? minSoilBarWidth
			: zoomedWidth >= maxSoilBarWidth
				? maxSoilBarWidth
				: zoomedWidth;
	}, [zoomLevel]);

	if (!measurementsConeResistance || !measurementsFrictionRatio || !measurementsWaterPressure) return null;

	const graphRefs = {
		frictionRatio: {
			ref: frictionRatioRef,
			marginLeft: measurementsFrictionRatio.yAxisWidth,
			width: measurementsFrictionRatio.graphWidth - measurementsFrictionRatio.yAxisWidth - rightMargin,
		},
		coneResistance: {
			ref: coneResistanceRef,
			marginLeft: measurementsConeResistance.yAxisWidth,
			width: measurementsConeResistance.graphWidth - measurementsConeResistance.yAxisWidth - rightMargin,
		},
		waterPressure: {
			ref: waterPressureRef,
			marginLeft: measurementsWaterPressure.yAxisWidth,
			width: measurementsWaterPressure.graphWidth - measurementsWaterPressure.yAxisWidth - rightMargin,
		},
	};

	const DialogActions = <TextButtonTurquiose onClick={(): void => setDialogOpen(false)}>CLOSE</TextButtonTurquiose>;

	const hideWarning = (): boolean => {
		if (visibleCharts.length === 1 && visibleCharts.includes(ChartVisibility.SOIL_LAYERS)) {
			return true;
		}

		if (zoomLevel < 1.5) {
			if (!visibleCharts.includes(ChartVisibility.CONE_RESISTANCE) && visibleCharts.length === 1) {
				return true;
			}
		}

		if (zoomLevel < 0.5) {
			if (visibleCharts.length <= 2 && !visibleCharts.includes(ChartVisibility.CONE_RESISTANCE)) {
				return true;
			}
		}

		if (zoomLevel < 0.65) {
			if (visibleCharts.includes(ChartVisibility.CONE_RESISTANCE)) {
				return false;
			}

			if (visibleCharts.length === 2 && visibleCharts.includes(ChartVisibility.SOIL_LAYERS)) {
				return true;
			}
		}

		return false;
	};

	return (
		<div ref={ref} className={classes.root} style={{ transform: `translateY(${topPosBaseOnFirstZIndex}px)` }}>
			<div className={classes.header} ref={cptHeaderRef} style={{ color: cptIsInActiveProject ? '#01cebd' : '#999' }}>
				{cpt.cptNumber}
				<InfoIcon
					className={classes.icon}
					onClick={(): void => {
						setDialogOpen(true);
					}}
				/>
				{cpt.warningsList.map(
					(warning): ReactNode => {
						return hideWarning() ? null : <WarningChip key={warning} message={warning} />;
					},
				)}
			</div>
			<div className={classes.soilInvestigation}>
				{measurementsWaterPressure && visibleCharts.includes(ChartVisibility.WATER_PRESSURE) && (
					<WaterPressureGraph
						ref={graphRefs.waterPressure.ref}
						cpt={cpt}
						zoomLevel={zoomLevel}
						computedMeasurements={measurementsWaterPressure}
						xAxisHeight={xAxisHeight}
						rightMargin={rightMargin}
						visibility={visibility}
					/>
				)}
				{visibleCharts.includes(ChartVisibility.CONE_RESISTANCE) && (
					<ConeResistanceGraph
						ref={graphRefs.coneResistance.ref}
						cpt={cpt}
						computedMeasurements={measurementsConeResistance}
						xAxisHeight={xAxisHeight}
						rightMargin={rightMargin}
						visibility={visibility}
					/>
				)}
				{visibleCharts.includes(ChartVisibility.FRICTION_RATIO) && (
					<FrictionRatioGraph
						ref={graphRefs.frictionRatio.ref}
						cpt={cpt}
						zoomLevel={zoomLevel}
						computedMeasurements={measurementsFrictionRatio}
						xAxisHeight={xAxisHeight}
						rightMargin={rightMargin}
						visibility={visibility}
					/>
				)}
				<div className={classes.soilLayers}>
					{visibleCharts.includes(ChartVisibility.SOIL_LAYERS) && (
						<CPTSoilLayerBar
							cpt={cpt}
							distanceBasedOnZoom={distanceBetweenSteps * zoomLevel}
							height={measurementsFrictionRatio.graphHeightWithoutXAxis}
							width={measurementsSoilBarWidth}
							xAxisHeight={xAxisHeight}
							graphRefs={graphRefs}
							visibility={visibility}
						/>
					)}
				</div>
			</div>
			<BaseDialog
				title={`${cpt.cptNumber} Metadata`}
				isOpen={dialogOpen}
				closeDialog={(): void => setDialogOpen(false)}
				actionButtons={DialogActions}
			>
				<MetaDataContent data={cpt} />
			</BaseDialog>
		</div>
	);
});

export default React.memo(CPT);
