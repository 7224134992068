import React, { FC, Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '@material-ui/icons/Person';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { ReferenceObject } from 'popper.js';
import {
	getProjectProgress,
	getExportStatusSelector,
	getProjectErrors,
	getWarningMessage,
	getMainImportError,
	getSelectedProject,
} from '@/store/selectors';
import { resetProjectErrors, resetState, setSelectedSoilInvestigationIds, userLoggedIn } from '@/store/actions';
import AppBar from '@/components/Layout/AppBar';
import Mapbox from '@/components/Mapbox';
import FileUploadButton from '@/components/custom/FileUploadButton';
import ProgressBarDialog from '@/components/Dialogs/ProgressBarDialog';
import ProjectDialog from '@/components/Dialogs/ProjectDialog';
import { readMultipleFileContent, validatedFilesExtension } from '@/helpers';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { TextButtonNeutral } from '@/components/custom/buttons';
import AlertBanner from '@/components/custom/AlertBanner';
import SimpleErrorDialog from '@/components/Dialogs/SimpleErrorDialog';
import { CustomIconButton } from '@/components/custom/iconButtons';
import MenuList from '@/components/custom/IconPoperListMenu';
import MapSideBar from '@/components/MapSideBar';
import { ESoilInvestigationType, ExportStatus, UploadMethod } from '@/models/types';
import { default as logoutIcon } from '@/assets/images/menu/logout-24px.svg';
import { mixPanelReset } from '@/tracking/mixPanel';
import { UploadFileChunk } from '@/proto/build/cpt_pb';
import classes from './index.module.css';

const MAX_UPLOAD_SIZE = 1024 * 1024 * 50;
const VALIDATION_PAGE_URL =
	'https://geodigitalwiki.corporateroot.net/doku.php?id=software:openground_software:mondriaan:validation_metadata_cpt';

const ProjectsMap: FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const progressStatus = useSelector(getProjectProgress);
	const projectErrors = useSelector(getProjectErrors);
	const warningMsg = useSelector(getWarningMessage);
	const mainImportError = useSelector(getMainImportError);
	const selectedProject = useSelector(getSelectedProject);
	const exportStatus = useSelector(getExportStatusSelector);
	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [uploadMethod, setUploadMethod] = useState<UploadMethod | undefined>(undefined);
	const [errorDialogContent, setErrorDialogContent] = useState<{ title: string; content: string }>();
	const [files, setFiles] = useState<UploadFileChunk.AsObject[]>([]);
	const [showImportErrorAlert, setShowImportErrorAlert] = React.useState(false);
	const [showImportErrorDialog, setShowImportErrorDialog] = React.useState(false);
	const [showCreateProjectFailedDialog, setShowCreateProjectFailedDialog] = React.useState(false);
	const [keysPressed, setKeysPressed] = useState<Record<string, boolean>>({});

	const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
	const [anchorEl, setAnchorEl] = React.useState<null | ReferenceObject | (() => ReferenceObject)>(null);
	const [menuOpen, setMenuOpen] = React.useState(false);

	useEffect((): void => {
		if (isAuthenticated) {
			getAccessTokenSilently()
				.then((accessTokenResult) => {
					if (user)
						dispatch(userLoggedIn({ accessCode: accessTokenResult, user }));
				})
				.catch((e) => console.error(`Failure getting Mondriaan API''s access token: ${e}`));
		}
	}, [dispatch, getAccessTokenSilently, isAuthenticated, user]);

	useEffect((): void => {
		const cptsAffected = Object.keys(projectErrors.list);
		// If there's any type of error...
		setShowImportErrorAlert(cptsAffected.length > 0 && !projectErrors.allFilesFailed);
		setShowImportErrorDialog(projectErrors.allFilesFailed);
		setShowCreateProjectFailedDialog(projectErrors.createProjectFailed);
	}, [projectErrors]);

	// Handle the locally uploading files (when you select the files or drag and drop)
	const handleOnFilesSelect = (files: File[], uploadMethod: UploadMethod): void => {
		setErrorDialogContent(undefined);
		const uploadSize = files.map((f: File) => f.size).reduce((total, num) => total + num);

		const validatedFiles = validatedFilesExtension(files);

		if (!validatedFiles) {
			setErrorDialogContent({
				title: 'Atleast one file has an invalid extension.',
				content: 'Please, only select files with valid extensions(.gef, .xml).',
			});
		} else if (files.length > 200) {
			setErrorDialogContent({
				title: 'One time upload cannot be larger than 200 files.',
				content: 'Please, select less files.',
			});
		} else if (uploadSize > MAX_UPLOAD_SIZE) {
			setErrorDialogContent({
				title: 'One time upload cannot be larger than 50MB.',
				content: 'Please, select a smaller batch of files.',
			});
		} else {
			setShowDialog(true);
			setFiles(readMultipleFileContent(files));
			setUploadMethod(uploadMethod);
		}
	};

	const handleDrag = (event: React.DragEvent<HTMLDivElement>): void => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
		event.preventDefault();
		event.stopPropagation();
		handleOnFilesSelect(Array.from(event.dataTransfer.files), UploadMethod.DRAG_AND_DROP);
	};

	const clearFiles = (): void => {
		setFiles([]);
	};

	const handleCloseErrorDialog = (): void => {
		setErrorDialogContent(undefined);
	};

	const handleHideClick = (): void => {
		dispatch(resetState());
		dispatch(resetProjectErrors());
	};

	const moreInformationFragment = (
		<Fragment>
			You can read more about files validation in &nbsp;
			<a href={VALIDATION_PAGE_URL} target="_blank" rel="noopener noreferrer">
				this article
			</a>
			.
		</Fragment>
	);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		event.stopPropagation();
		event.persist();

		if (keysPressed[`${event.key}`]) return;

		const keys = {
			...keysPressed,
			[`${event.key}`]: true,
		};

		if (keysPressed.Control && event.key.toLowerCase() === 'a' && selectedProject) {
			event.preventDefault();
			const cpts = Object.values(selectedProject?.cptsList || []);
			const boreholes = Object.values(selectedProject?.boreholesList || []);

			const cptsIds = cpts.map((cpt) => {
				return {
					soilInvestigationId: cpt.id,
					type: ESoilInvestigationType.CPT,
				};
			});

			const borholesIds = boreholes.map((bh) => {
				return {
					soilInvestigationId: bh.id,
					type: ESoilInvestigationType.BOREHOLE,
				};
			});

			dispatch(setSelectedSoilInvestigationIds([...cptsIds, ...borholesIds]));
		}

		setKeysPressed(keys);
	};

	const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		event.stopPropagation();
		event.persist();

		setKeysPressed((keys) => {
			delete keys[event.key];
			return keys;
		});
	};

	const signUserOut = (): void => {
		mixPanelReset();
		logout();
	};

	return (
		<div onDrop={handleDrop} onDragOver={handleDrag} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} tabIndex={0}>
			<AppBar>
				<FileUploadButton
					accept=".gef, .xml"
					buttonText="UPLOAD"
					multiple={true}
					buttonType="purple"
					className={classes.upload}
					onFilesSelect={(files): void => handleOnFilesSelect(files, UploadMethod.BUTTON)}
				/>
				<Tooltip title="Instructions">
					<CustomIconButton
						classes={{ root: classes.infoButton }}
						onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
							e.stopPropagation();
							// eslint-disable-next-line max-len
							window.open('https://geodigitalwiki.corporateroot.net/doku.php?id=software:openground_software:mondriaan:instruction', '_blank');
						}}
						style={{ backgroundColor: '#D3D3D3' }}
					>
						<InfoIcon classes={{ root: classes.icon }} style={{ color: '#5f6060' }} />
					</CustomIconButton>
				</Tooltip>
				<CustomIconButton
					classes={{ root: classes.profileButton }}
					onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
						e.stopPropagation();
						setAnchorEl(e.currentTarget);
						setMenuOpen(!menuOpen);
					}}
				>
					<PersonIcon classes={{ root: classes.icon }} />
					<MenuList
						header={isAuthenticated ? user?.name as string : 'Guest'}
						open={menuOpen}
						setOpen={setMenuOpen}
						anchorEl={anchorEl}
					>
						<MenuItem className={classes.menuItem} onClick={signUserOut}>
							<img src={logoutIcon} alt="Sign out" className={classes.profileIcons} />
							<span className={classes.itemText}>Sign out</span>
						</MenuItem>
					</MenuList>
				</CustomIconButton>
			</AppBar>
			<MapSideBar yellowBannerIsActive={showImportErrorAlert}/>
			<Mapbox />
			{errorDialogContent && (
				<BaseDialog
					title="An error occurred"
					isOpen={!!errorDialogContent}
					closeDialog={handleCloseErrorDialog}
					actionButtons={
						<Fragment>
							<TextButtonNeutral onClick={handleCloseErrorDialog}>CLOSE</TextButtonNeutral>
							<FileUploadButton
								accept=".gef"
								buttonText="RESELECT"
								multiple={true}
								buttonType="turquoise"
								onFilesSelect={(files): void => handleOnFilesSelect(files, UploadMethod.BUTTON)}
							/>
						</Fragment>
					}
				>
					<p className={classNames(classes.text, classes.bold)}>{errorDialogContent.title}</p>
					<span className={classes.text}>{errorDialogContent.content}</span>
				</BaseDialog>
			)}
			<ProjectDialog
				showDialog={showDialog}
				setShowDialog={setShowDialog}
				files={files}
				clearFiles={clearFiles}
				uploadMethod={uploadMethod}
			/>
			{progressStatus.show && <ProgressBarDialog progress={progressStatus.progress} type={progressStatus.type}/>}
			<AlertBanner visible={showImportErrorAlert} onHide={handleHideClick}>
				<span className={classes.blackLink}>
					{warningMsg}. {moreInformationFragment}
				</span>
			</AlertBanner>
			<SimpleErrorDialog
				visible={showImportErrorDialog}
				title="An error occurred"
				onHide={handleHideClick}
				main={`None of the selected files could be read because of ${mainImportError}.`}
			>
				<span className={classes.whiteLink}>{moreInformationFragment}</span>
			</SimpleErrorDialog>
			<SimpleErrorDialog
				visible={showCreateProjectFailedDialog}
				title="An error occurred"
				onHide={handleHideClick}
				main="Failure storing the project please try again in a few minutes."
			/>
			<SimpleErrorDialog
				visible={exportStatus === ExportStatus.TOO_LARGE}
				title="One time export cannot be larger than 100 files."
				onHide={handleHideClick}
				main="Please, select a smaller batch of files."
			/>
			<SimpleErrorDialog
				visible={exportStatus === ExportStatus.ERROR}
				title="An error occurred"
				onHide={handleHideClick}
				main="An error occurred during the export of the selected files."
			/>
		</div>
	);
};

export default ProjectsMap;
