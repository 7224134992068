import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import { ESoilInvestigationType, HoveredSoilInvestigationLayer } from '@/models/types';

const initialState: HoveredSoilInvestigationLayer = {
	soilInvestigationId: '',
	soilInvestigationType: ESoilInvestigationType.CPT,
	id: '',
};

export default createReducer(initialState, {
	[actions.setHoveredSoilLayer.type]: (draft, { payload }): HoveredSoilInvestigationLayer => payload,
	[actions.resetHoveredSoilLayer.type]: (): HoveredSoilInvestigationLayer => initialState,
	[actions.resetState.type]: (): HoveredSoilInvestigationLayer => initialState,
});
